import request from '../../utils/axios'

const attribute = {
  getAttribute: function (parames) { // 查询接口
    return request.get('edu/attributeName/findList', { params: parames })
  },
  getAttributeValue: function (parames) { // 查询接口
    return request.get('edu/attributeValue/findByAttributeId', { params: parames })
  },
  editAttributeName: function (parames) { // 编辑属性名接口
    return request.get('edu/attributeName/update', { params: parames })
  },
  addAttribute: function (parames) { // 新增属性名接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/attributeName/add',
      method: 'get',
      params: parames
    })
  },
  addAttValue: function (parames) { // 新增属性值接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/attributeValue/add',
      method: 'get',
      params: parames
    })
  },
  deleteAttName: function (params) { // 删除属性名接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/attributeName/delete', { params })
  },
  deleteAttValue: function (params) { // 删除属性值接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/attributeValue/delete', { params })
  }
}

export default attribute
