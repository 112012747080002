import request from '../../utils/axios'

const message = {
  getMessageList: function (parames) { // 查询接口
    return request.get('edu/messageProduct/findList', { params: parames })
  },
  editMessage: function (parames) { // 编辑接口
    return request.get('edu/messageProduct/update', { params: parames })
  },
  goodMessage: function (parames) { // 点赞接口
    return request.get('edu/messageProduct/fabulous', { params: parames })
  },
  addMessage: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/messageProduct/add',
      method: 'get',
      params: parames
    })
  },
  deleteMessage: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/messageProduct/delete', { params })
  }
}

export default message
