import request from '../../utils/axios'

const law = {
  getLawList: function (parames) { // 查询接口
    return request.get('edu/law/findList', { params: parames })
  },
  editLaw: function (parames) { // 编辑接口
    return request.get('edu/law/update', { params: parames })
  },
  addLaw: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/law/add',
      method: 'get',
      params: parames
    })
  },
  deleteLaw: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/law/delete', { params })
  },
  auditLaw: function (params) { // 过审接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/law/audit', { params })
  }
}

export default law
