import request from '../../utils/axios'

const companyUser = {
  getCompanyUserList: function (parames) { // 查询接口
    return request.get('edu/companyUser/findList', { params: parames })
  },
  getCompanyListById: function (parames) { // 查询接口
    return request.get('edu/companyUser/findListByUserId', { params: parames })
  },
  editCompanyUser: function (parames) { // 编辑接口
    return request.get('edu/companyUser/update', { params: parames })
  },
  addCompanyUser: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/companyUser/add',
      method: 'get',
      params: parames
    })
  },
  deleteCompanyUser: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/companyUser/delete', { params })
  }
}

export default companyUser
