<!-- 上传文件的工具 -->
<template>
 <div class="upload">
  <el-row>
    <el-col :span="24">
      <input type="file" ref="fileInput" @change="handleFileChange">
      <div slot="tip" class="el-upload__tip">只能上传PDF/Word/Excel文件，且不超过3MB</div>
    </el-col>
    <el-col :span="24">
      <div class="demo-image__preview" v-show="this.$store.state.show">
      <span v-if="this.$store.state.upLoadFileUrl == ''">
        <i class="el-icon-loading" size="20px"></i>
      </span>
      <span v-else>
        上传成功
      </span>
    </div>
    </el-col>
  </el-row>
 </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'upload',
  components: {},
  data () {
    return {
      selectedFile: []
    }
  },
  mounted () {
    this.srcList.push(this.$store.state.upLoadFileUrl)
  },
  methods: {
    handleFileChange (event) {
      console.log('文件的全部信息')
      this.selectedFile = event.target.files[0] // 获取所选文件对象
      const _this = this
      _this.$store.commit('getFileName', event.target.files[0].name)
      this.uploadFile()
    },
    uploadFile () {
      const _this = this
      _this.$store.commit('getShow', true)
      _this.$store.commit('getUpLoadFileUrl', '')
      const formData = new FormData() // 创建FormData对象
      formData.append('file', this.selectedFile)
      axios.post('http://api.fengyutangfly.cn/edu/file/uploadImg', formData).then((response) => {
        console.log('文件已成功上传' + response.data.data)
        const _this = this
        _this.$store.commit('getUpLoadFileUrl', response.data.data)
      }).catch((error) => {
        console.error('文件上传失败', error)
      })
    }

  }
}
</script>
<style lang="scss" scoped>

</style>
