<!-- 法律大全 -->
<template>
 <div class="law">
    <el-row class="input">
      <el-col :span="2">全局搜索</el-col>
        <el-col :span="5">
          <el-input
            size="small"
            style="width: 250px;"
            placeholder="请输入法律名称或法条内容"
            @change="selectLaw"
            v-model="input">
          </el-input>
        </el-col>
    </el-row>
    <!-- 新增法律按钮 -->
    <el-row class="add">
      <el-col :span="2">
        <el-button @click="newLaw" type="primary" size="small" v-show="[1, 2, 3].includes(3)">新增法条</el-button>
      </el-col>
    </el-row>
    <!-- 法律表格 -->
    <el-row class="table">
      <el-col :span="24">
        <el-table
          :data="tableData"
          stripe
          :height=windowHeight-250
          style="width: 100%">
          <el-table-column
            prop="lawName"
            label="法律名称"
            width="180">
          </el-table-column>
          <el-table-column
            prop="assertTime"
            label="颁布日期"
            width="110">
          </el-table-column>
          <el-table-column
            prop="lawItem"
            label="款项"
            width="120">
          </el-table-column>
          <el-table-column
            prop="status"
            label="产品状态"
            width="100"
            :filters="[{ text: '上架', value: 1 }, { text: '审核中', value: 0 }, { text: '下架', value: 2 }]"
            :filter-method="filterTag"
            filter-placement="bottom-end">
            <template slot-scope="scope">
              <el-tag
              :type="scope.row.status === 1 ? 'primary' : scope.row.status === 0 ? 'success' : 'info'"
                disable-transitions>
                <span v-if="scope.row.status === 1">上架</span>
                <span v-if="scope.row.status === 0">审核中</span>
                <span v-if="scope.row.status === 2">下架</span>
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="lawContent"
            show-overflow-tooltip
            label="法条内容"
            width="700">
            <template slot-scope="scope">
              <span type='text'>{{scope.row.lawContent}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="rink"
            label="链接"
            show-overflow-tooltip
            width="180">
            <template slot-scope="scope">
              <span type='text'>{{scope.row.rink}}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100">
            <template slot-scope="scope">
              <el-button @click="editLaw(scope.row)" type="text" size="small">编辑</el-button>
              <el-button @click="downLaw(scope.row)" type="text" size="small" v-if="scope.row.status === 1">下架</el-button>
              <el-button @click="upLaw(scope.row)" type="text" size="small" v-if="scope.row.status === 2 || scope.row.status === 4">上架</el-button>
              <el-button @click="upLaw(scope.row)" type="text" size="small" v-if="scope.row.status === 0">通过</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div class="block">
      <el-row>
        <!-- 分页功能 -->
        <el-col :span="12" :offset="10">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total">
          </el-pagination>
        </el-col>
      </el-row>
    </div>
    <!-- // 新增和编辑的模板 -->
    <el-dialog
      :title="title"
      :visible.sync="newLawdialogVisible"
      width="48%"
      :before-close="handleClose">
      <span>
        <el-form ref="law" :model="law" label-width="80px">
          <el-form-item label="法律名称">
            <el-col :span="18">
              <el-input v-model="law.law_name"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="法律款项">
            <el-col :span="18">
              <el-input v-model="law.law_item"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="生效时间">
            <el-col :span="11">
              <el-date-picker type="date" placeholder="请选择日期" value-format="yyyy-MM-dd" v-model="law.law_time" style="width: 100%;"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="法律链接">
            <el-col :span="18">
              <el-input v-model="law.law_rink"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="法条内容">
            <el-input type="textarea" v-model="law.law_content" ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="finishAdd" v-if="this.title === '新增法条'">立即创建</el-button>
            <el-button type="success" @click="finishEdit" v-if="this.title === '编辑法条'">编辑完成</el-button>
            <el-button @click="newLawdialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </span>
    </el-dialog>
 </div>
</template>

<script>
import getheight from '../../../utils/getheight'
import law from '../../../api/product/law'
export default {
  name: 'law',
  mixins: [getheight],
  components: {},
  data () {
    return {
      input: '',
      title: '新增法条',
      windowHeight: 0,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: null,
      newLawdialogVisible: false,
      law: {
        law_id: '',
        law_name: '',
        law_item: '',
        law_time: '',
        law_rink: '',
        law_content: ''
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () { // 获得规定的列表
      const data = {
        current: this.currentPage,
        size: this.pageSize
      }
      law.getLawList(data).then(res => {
        console.log(res)
        this.total = res.data.total
        this.tableData = res.data.records
      })
    },
    newLaw () { // 打开新增弹窗
      this.newLawdialogVisible = true
      this.title = '新增法条'
      this.law = {}
    },
    finishAdd () { // 新增部门
      console.log('-------')
      const data = {
        position: 1,
        lawName: this.law.law_name,
        lawItem: this.law.law_item,
        assertTime: this.law.law_time,
        lawContent: this.law.law_content,
        rink: this.law.law_rink
      }
      console.log('----+++++---')
      law.addLaw(data).then(res => {
        if (res.code === 0) {
          console.log('成功了！')
          this.newLawdialogVisible = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        }
      })
      console.log('----+++====++---')
    },
    editLaw (row) { // 打开编辑
      this.newLawdialogVisible = true
      this.title = '编辑法条'
      this.law.law_id = row.id
      this.law.law_name = row.lawName
      this.law.law_item = row.lawItem
      this.law.law_time = row.assertTime
      this.law.law_content = row.lawContent
      this.law.law_rink = row.rink
    },
    finishEdit () { // 完成编辑的方法
      const data = {
        position: 1,
        id: this.law.law_id,
        lawName: this.law.law_name,
        lawItem: this.law.law_item,
        assertTime: this.law.law_time,
        lawContent: this.law.law_content,
        rink: this.law.law_rink
      }
      law.editLaw(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          console.log('成功了！')
          this.newLawdialogVisible = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    handleClose () {
      this.newLawdialogVisible = false
    },
    downLaw (row) { // 删除部门
      console.log(row)
      this.$confirm('确认下架？')
        .then(_ => {
          const params = { id: row.id }
          law.deleteLaw(params).then(res => {
            this.$message({
              message: '下架成功',
              type: 'warning'
            })
            this.getList()
          })
        })
        .catch(_ => {})
    },
    upLaw (row) { // 同意部门
      console.log(row)
      this.$confirm('确认通过？')
        .then(_ => {
          const params = { id: row.id }
          law.auditLaw(params).then(res => {
            this.$message({
              message: '上架成功',
              type: 'success'
            })
            this.getList()
          })
        })
        .catch(_ => {})
    },
    // 翻页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getList()
    },
    selectLaw () { // 查询部门
      const params = { lawName: this.input }
      if (this.input === '') {
        this.getList()
      } else {
        law.getLawList(params).then(res => {
          console.log(res)
          this.total = res.data.total
          this.tableData = res.data.records
        })
      }
    },
    // 状态的筛选
    filterTag (value, row) {
      return row.status === value
    }
  }
}
</script>
<style lang="scss" scoped>
.input{
  height: 50px;
  background: #FFFFFF;
  font-size: 14px;
  border: 0px red solid;
  display: flex;
  align-items: center;
}
.table{
  background: #FFFFFF;
  font-size: 14px;
  padding: 10px;
}
.add{
  height: 50px;
  margin-top: 10px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
}
.block{
  height: 40px;
  background: #FFFFFF;
}
</style>
