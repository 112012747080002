<!--  -->
<template>
 <div class="show">
    <header>
      <div class="container">
        <div class="logo">
          <el-image
            style="width: 50px; height: 50px"
            src="https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg"
            fit="fit"></el-image>
            <p style="margin-left: 20px;">丰羽堂技术服务平台</p>
        </div>
        <nav>
          <a href="#a">三大特色</a>
          <a href="#b">九大中心</a>
          <a href="#c">核心价值</a>
          <a href="#d">企业案例</a>
          <a href="#f">联系我们</a>
        </nav>
        <div class="test" style="font-size: 15px;">
            <router-link style="text-decoration: none;color: #000;font-weight: bold;" to="/home">免费体验</router-link>
        </div>
      </div>
    </header>
    <div class="pages">
      <div class="fristpage">
        <el-row>
          <el-col :span="11">
            <div style="margin-left: 18%;margin-top: 35%;text-align: left;">
              <div style="font-size: 30px;color: #1790ff;font-weight: bold;margin-left: 3px;letter-spacing: 5px">企业服务行业</div>
              <div style="font-size: 43px;margin-top: 15px;margin-bottom: 25px;font-weight: bold;">营销增长的一站式解决方案</div>
              <div style="font-size: 20px;margin-left: 3px;letter-spacing: 5px">全流程赋能企服公司10000+</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="secondpage" id="a">
        <h1>我们的特色</h1>
        <div style="margin-top: 45px;color: #A9A9A9;">我们致力于企业服务行业的效率提升和质量提高，用更多技术工具帮助企业服务行业来实现目标！</div>
        <div style="margin-top: 45px;width: 94%;margin-left: 5%;">
          <el-row style="text-align: left;">
            <el-col :span="6" :offset="1" class="card">
              <el-card class="card">
                <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/_x30_29.png" alt="流程">
                <div class="specialTittle">工作内容精简化、自动化</div>
                <ul style="margin-left: -15px;">
                  <li>日报、周报、月报自动生成</li>
                  <li>合同在线生成、编辑、审核与确认</li>
                  <li>服务方案自动生成与备份</li>
                </ul>
              </el-card>
            </el-col>
            <el-col :span="6" :offset="2">
              <el-card class="card">
                <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/_x30_26.png" alt="流程">
                <div class="specialTittle">企业运作标准化、流程化</div>
                <ul style="margin-left: -15px;">
                  <li>人才培养一步到位</li>
                  <li>营销动作人人参与</li>
                  <li>交付流程实时同步</li>
                  <li>商机分配最优配置</li>
                </ul>
              </el-card>
            </el-col>
            <el-col :span="6" :offset="1" class="card">
              <el-card class="card">
                <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/_x30_32.png"  alt="流程">
                <div class="specialTittle">数据呈现可视化、智能化</div>
                <ul style="margin-left: -15px;">
                  <li>企业、部门目标完成情况动态呈现</li>
                  <li>商机评分待成交客户排名</li>
                  <li>综合数据分析，业绩预估</li>
                </ul>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="threepage" id="b">
        <div class="middle">
           <div style="margin-top: -60px;font-size: 50px;color: white;letter-spacing: 7px;margin-left: 105px;">我们如何实现这些功能？</div>
        </div>
        <div>
          <h1 style="letter-spacing: 3px;margin-left: 15px;">九大中心功能模板</h1>
        </div>
        <div>
          <el-row style="width: 95%;margin-left: 3%;">
            <el-col :span="6" :offset="2" class="out">
              <el-row>
                <el-col :span="6">
                  <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/g.png" alt="流程">
                </el-col>
                <el-col :span="18">
                  <div class="title">学习中心</div>
                  <div class="text">企业服务知识、素材、工具合集，快速培养人才</div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6" :offset="1" class="out">
              <el-row>
                <el-col :span="6">
                  <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/f.png" alt="流程">
                </el-col>
                <el-col :span="18">
                  <div class="title">产品中心</div>
                  <div class="text">管理企业产品市场价、渠道价，衔接企业外部资源</div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6" :offset="1" class="out">
              <el-row>
                <el-col :span="6">
                  <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/h.png" alt="流程">
                </el-col>
                <el-col :span="18">
                  <div class="title">考试中心</div>
                  <div class="text">人才培养的检测工具，让商机做最优配置</div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="width: 95%;margin-left: 3%;margin-top: 20px;">
            <el-col :span="6" :offset="2" class="out">
              <el-row>
                <el-col :span="6">
                  <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/i.png" alt="流程">
                </el-col>
                <el-col :span="18">
                  <div class="title">企业中心</div>
                  <div class="text">企业服务知识、素材、工具合集，快速培养人才</div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6" :offset="1" class="out">
              <el-row>
                <el-col :span="6">
                  <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/b.png" alt="流程">
                </el-col>
                <el-col :span="18">
                  <div class="title">商机中心</div>
                  <div class="text">多渠道获得商机，商机评分排序，为业绩蓄能</div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6" :offset="1" class="out">
              <el-row>
                <el-col :span="6">
                  <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/a.png" alt="流程">
                </el-col>
                <el-col :span="18">
                  <div class="title">目标中心</div>
                  <div class="text">设置多重目标，多维度呈现目标完成情况</div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row style="width: 95%;margin-left: 3%;margin-top: 20px;">
            <el-col :span="6" :offset="2" class="out">
              <el-row>
                <el-col :span="6">
                  <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/d.png" alt="流程">
                </el-col>
                <el-col :span="18">
                  <div class="title">客户中心</div>
                  <div class="text">企业服务知识、素材、工具合集，快速培养人才</div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6" :offset="1" class="out">
              <el-row>
                <el-col :span="6">
                  <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/c.png" alt="流程">
                </el-col>
                <el-col :span="18">
                  <div class="title">订单中心</div>
                  <div class="text">企业服务知识、素材、工具合集，快速培养人才</div>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="6" :offset="1" class="out">
              <el-row>
                <el-col :span="6">
                  <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/e.png" alt="流程">
                </el-col>
                <el-col :span="18">
                  <div class="title">交付中心</div>
                  <div class="text">企业服务知识、素材、工具合集，快速培养人才</div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="fourpage" id="c">
        <div style="color: #FFFFFF;letter-spacing: 5px;font-size: 45px;margin-top: 10px;">我们的核心价值</div>
        <div style="margin-top: 15px;color: #DCDCDC;">多渠道、高质量、低成本获取商机；多维度商机评分系统，发现最有价值的商机；商机终结分析，寻找薄弱环节</div>
        <div style="padding: 50px;">
          <el-row style="margin-top: -10px;height: 430px;background: #FFFFFF;border-radius: 20px;padding-top: 50px;padding-right: 30px;">
            <el-col :span="7" :offset="1">
              <img  src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/source.png" alt="">
              <div style="font-size: 22px;margin-top: 35px;font-weight: bold;">多渠道商机获取</div>
            </el-col>
            <el-col :span="7" :offset="1">
              <img  src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/ranking.png" alt="">
              <div style="font-size: 22px;margin-top: 35px;font-weight: bold;">多维度商机排名</div>
            </el-col>
            <el-col :span="7" :offset="1">
              <img  src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/reason.png" alt="">
              <div style="font-size: 22px;margin-top: 35px;font-weight: bold;">商机终止原因分析</div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="fivepage" id="d">
        <h1 style="letter-spacing: 3px;margin-left: 15px;margin-top: 30px;">客户案例</h1>
        <el-row style="text-align: left;width: 95%;margin-left: 3%;margin-top: 50px;">
          <el-col :span="5" :offset="1">
            <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/PMT04.png" alt="流程">
            <div style="padding-right: 5px;">
              <div class="boss">北京市朝阳区A公司苏总</div>
              <div style="margin-top: 20px;line-height: 35px;">本公司一站式服务企业服务人才已经达到<span class="big">85.4%</span>，实现了低成本培养人才的目标！</div>
            </div>
          </el-col>
          <el-col :span="5" :offset="1">
            <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/PMT01.png" alt="流程">
            <div>
              <div class="boss">上海市静安区B公司王总</div>
              <div style="margin-top: 20px;line-height: 35px;">本集团公司商机增长<span class="big">30%</span>以上，人人参与营销，内部、外部高质量商机不断增加！</div>
            </div>
          </el-col>
          <el-col :span="5" :offset="1">
            <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/PMT02.png" alt="流程">
            <div>
              <div class="boss">广州市天河区C公司宋总</div>
              <div style="margin-top: 20px;line-height: 35px;">企业内部运行效率提升了<span class="big">45%</span>，人员工作内容、时间得到了最合理安排，实现了降本增效！</div>
            </div>
          </el-col>
          <el-col :span="5" :offset="1">
            <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/PMT03.jpg" alt="流程">
            <div style="padding-right: 15px;">
              <div class="boss">深圳市南山区D公司蒋总</div>
              <div style="margin-top: 20px;line-height: 35px;">一套工具解决了以往需要多套工具解决的问题，每年降低<span class="big">12%</span>的企业运营成本！</div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="endpage" id="f" style="color: #FFFFFF;padding-left: 30px;padding-top: 5px;text-align: left;">
        <h3>友情链接:</h3>
      </div>
    </div>
 </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.endpage{
  background: #2B343C;
  height: 300px;
}
.fivepage{
  height: 650px;
  background: #FFFFFF;
  .boss{
    font-weight: bold;
    letter-spacing: 2px;
    margin-bottom: 20px;
  }
  .big{
    color: #1790ff;
    font-size: 30px;
    font-weight: bold;
  }
  img{
    height: 220px;
    width: 250px;
    margin-bottom: 10px;
  }
}
.fourpage{
  height: 670px;
  padding: 15px;
  background-image: url('https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/blue.png');
  img{
    height: 270px;
    width: 370px;
  }
}
.threepage{
  height: 950px;
  .title{
    font-weight: bold;
    font-size: 17px;
  }
  .text{
    margin-top: 5px;
  }
  .out{
   height: 130px;
   border: 1px #CDCDCD solid;
   border-radius: 5px;
   text-align: left;
   padding-left: 10px;
   padding-right: 10px;
   padding-top: 20px;
   img{
    width: 60px;
    height: 60px;
   }
  }
  .middle{
    height: 380px;
    background-image: url('https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/middle.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.secondpage{
  height: 650px;
  background: #FFFFFF;
  .card{
    height: 400px;
    border: 0px black solid;
    padding-top: 1px;
    padding-left: 10px;
    img{
      width: 90px;
      height: 90px;
    }
    .specialTittle{
      font-size: 20px;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    ul>li{
      margin-top: 15px;
      // list-style: none;
    }
    // li:before {
    //   content: "";
    //   width: 8px;
    //   height: 8px;
    //   display: inline-block;
    //   border-radius: 50%;
    //   background: #4F8EFF;
    //   vertical-align: middle;
    //   margin-right: 14px;
    // }
  }
}
.fristpage{
  height: 680px;
  background-image: url('https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/frist-1.jpg');
  background-repeat: no-repeat;
  margin-top: -40px;
  padding: 30px;
  background-size: cover;
}
header{
  height: 80px;
  width: 100%;
  .container{
  position: fixed;
  // border: 1px black solid;
  background: white;
  z-index: 99;
  width: 100%;
  display: flex;
    nav{
    flex: 1;
    // border: 1px blue solid;
    height: 80px;
    margin-left: 30px;
    font-size: 16px;
    display: flex;
    align-items: center;
      a{
        margin-left: 30px;
        text-decoration: none;
        color: black;
      }
    }
    .test{
      width: 350px;
      height: 80px;
      // border: 1px red solid;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logo{
      width: 380px;
      font-size: 25px;
      height: 80px;
      border: 0px red solid;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
