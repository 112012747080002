<!-- 产品办理难度 -->
<template>
 <div class="difficulty">
   <div class="echart" id="mychartRadar" :style="myChartStyleRadar" style="margin-top: -10px;"></div>
 </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: '',
  components: {},
  data () {
    return {
      // 产品评估雷达图
      myChartStyleRadar: { float: 'left', width: '100%', height: '300px' },
      RadarOption: {
        title: {
          text: ''
        },
        radar: {
          indicator: [
            { name: '沟通成本', max: 5 },
            { name: '办理难度', max: 5 },
            { name: '办理速度', max: 5 },
            { name: '售后难度', max: 5 },
            { name: '材料难度', max: 5 }
          ]
        },
        series: [
          {
            name: 'Budget vs spending',
            type: 'radar',
            data: [
              {
                value: []
              }
            ]
          }
        ]
      }
    }
  },
  mounted () {
    console.log('-----')
    console.log(this.$store.state.difficultyRadar)
    console.log(this.RadarOption.series[0].data[0].value)
    this.RadarOption.series[0].data[0].value = this.$store.state.difficultyRadar
    this.$nextTick(
      () => {
        this.initEcharts()
      }
    )
  },
  methods: {
    initEcharts () {
      var mychartRadar = echarts.init(document.getElementById('mychartRadar'))
      mychartRadar.setOption(this.RadarOption)
      window.addEventListener('resize', () => {
        this.mychartRadar.resize()
      })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
