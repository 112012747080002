<!-- 交付物大全 -->
<template>
  <div class="process">
     <el-row class="input">
       <el-col :span="2">全局搜索</el-col>
         <el-col :span="5">
           <el-input
             size="small"
             style="width: 250px;"
             placeholder="请输入流程名称"
             prefix-icon="el-icon-search"
             @change="selectProcess"
             v-model="input">
           </el-input>
         </el-col>
     </el-row>
     <!-- 新增流程按钮 -->
     <el-row class="add">
       <el-col :span="2">
         <el-button @click="newMaterial" type="primary" size="small" v-show="[1, 2, 3].includes(3)">新增流程</el-button>
       </el-col>
     </el-row>
     <!-- 流程表格 -->
     <el-row class="table">
       <el-col :span="24">
         <el-table
           :data="tableData"
           stripe
           :height=windowHeight-250
           style="width: 100%">
           <el-table-column
             prop="title"
             label="流程名称"
             width="220">
           </el-table-column>
           <el-table-column
             prop="worker"
             label="操作人"
             width="150">
             <template slot-scope="scope">
              <el-tag
                :type="scope.row.worker === 0 ? 'primary' : 'success'"
                disable-transitions>{{scope.row.worker  === 0 ? '企业提供' : '非企业提供'}}</el-tag>
            </template>
           </el-table-column>
           <el-table-column
             prop="content"
             label="流程备注"
             width="550">
           </el-table-column>
           <el-table-column
             prop="filename"
             label="关联文件名"
             width="100">
           </el-table-column>
           <el-table-column
             prop="userId"
             label="创建人"
             width="100">
           </el-table-column>
           <el-table-column
             prop="upTime"
             label="创建时间"
             width="150">
           </el-table-column>
           <el-table-column
             fixed="right"
             label="操作"
             width="120">
             <template slot-scope="scope">
               <el-button @click="editProcess(scope.row)" type="text" size="small">编辑</el-button>
               <el-button @click="deleteProcess(scope.row)" type="text" size="small">删除</el-button>
             </template>
           </el-table-column>
         </el-table>
       </el-col>
     </el-row>
     <div class="block">
       <el-row>
         <!-- 分页功能 -->
         <el-col :span="12" :offset="10">
           <el-pagination
             @size-change="handleSizeChange"
             @current-change="handleCurrentChange"
             :current-page="currentPage"
             :page-sizes="[10, 20, 30, 40]"
             :page-size="10"
             layout="total, sizes, prev, pager, next, jumper"
             :total="this.total">
           </el-pagination>
         </el-col>
       </el-row>
     </div>
     <!-- 新增和编辑的模板 -->
     <el-dialog
       :title="title"
       :visible.sync="newLawdialogVisible"
       width="48%"
       :before-close="handleClose">
       <span>
        图片{{ this.$store.state.upLoadUrl }}
        文件{{ this.$store.state.upLoadFileUrl }}
         <el-form ref="law" :model="process" label-width="80px">
           <el-form-item label="流程标题">
             <el-col :span="18">
               <el-input v-model="process.process_title" placeholder="请输入流程标题"></el-input>
             </el-col>
           </el-form-item>
           <el-form-item label="执行对象" prop="select">
            <el-col :span="9">
              <template style="float: left;">
                <el-radio v-model="worker" label="1">客户执行</el-radio>
                <el-radio v-model="worker" label="2">非客户执行</el-radio>
              </template>
             </el-col>
           </el-form-item>
           <el-form-item label="流程内容">
             <el-input type="textarea" maxlength="200" :rows="3"  show-word-limit v-model="process.process_content" placeholder="请输入流程备注"></el-input>
           </el-form-item>
           <el-form-item label="上传文件">
              <div style="float: left;margin-top: 0px;width: 100%;">
                <el-row>
                  <el-col :span="8"><UpFile></UpFile></el-col>
                  <el-col :span="10" :offset="2"><el-input v-model="this.$store.state.fileName" placeholder="请输入文件名" size="small"></el-input></el-col>
                  <el-col :span="4"><span style="color: red;size: 9px;font-family: Arial, Helvetica, sans-serif;">通用名称</span></el-col>
                </el-row>
              </div>
           </el-form-item>
           <el-form-item label="上传图片">
              <div style="float: left;margin-top: 0px;">
                <UpLoad></UpLoad>
              </div>
           </el-form-item>
           <el-form-item>
             <el-button type="primary" @click="finishAdd" v-if="this.title === '新增流程'">立即创建</el-button>
             <el-button type="success" @click="finishEdit" v-if="this.title === '编辑流程'">编辑完成</el-button>
             <el-button @click="newLawdialogVisible = false">取消</el-button>
           </el-form-item>
         </el-form>
       </span>
     </el-dialog>
  </div>
 </template>

<script>
import getheight from '../../../utils/getheight'
import process from '../../../api/product/process'
import UpFile from '../../../components/utils/UpFile.vue'
import UpLoad from '../../../components/utils/UpLoad.vue'
export default {
  name: 'process',
  mixins: [getheight],
  components: {
    UpFile,
    UpLoad
  },
  data () {
    return {
      input: '',
      userId: null,
      word: '你\n好',
      title: '新增流程',
      windowHeight: 0,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: null,
      worker: '1',
      fileName: this.$store.state.fileName,
      newLawdialogVisible: false,
      process: {
        process_id: '',
        process_title: '',
        process_about_file: '',
        process_about_picture: '',
        process_content: ''
      }
    }
  },
  mounted () {
    this.getList()
    this.userId = JSON.parse(localStorage.getItem('user')).id
  },
  methods: {
    // 翻页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getList()
    },
    getList () { // 获得规定的列表
      const data = {
        current: this.currentPage,
        size: this.pageSize
      }
      process.getProcessList(data).then(res => {
        console.log(res)
        this.total = res.data.total
        this.tableData = res.data.records
      })
    },
    finishAdd () { // 新增
      const data = {
        userId: JSON.parse(localStorage.getItem('user')).id,
        title: this.process.process_title,
        worker: this.worker,
        content: this.process.process_content,
        aboutPicture: this.$store.state.upLoadUrl,
        fileName: this.$store.state.fileName,
        aboutFile: this.$store.state.upLoadFileUrl
      }
      process.addProcess(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          console.log('成功了！')
          this.newLawdialogVisible = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    newMaterial () { // 打开新增弹窗
      this.process = {}
      this.newLawdialogVisible = true
      this.title = '新增流程'
      this.fileName = ''
      const _this = this
      _this.$store.commit('getUpLoadFileUrl', '')
      _this.$store.commit('getUpLoadUrl', '')
      _this.$store.commit('getFileName', '')
      _this.$store.commit('getShow', false)
    },
    finishEdit () { // 完成编辑的方法
      const data = {
        id: this.process.process_id,
        userId: this.$store.state.userInfo.userId,
        title: this.process.process_title,
        worker: this.worker,
        content: this.process.process_content,
        status: 1,
        aboutPicture: this.$store.state.upLoadUrl,
        aboutFile: this.$store.state.upLoadFileUrl
      }
      process.editProcess(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          console.log('成功了！')
          this.newLawdialogVisible = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    editProcess (row) { // 打开编辑弹窗
      this.newLawdialogVisible = true
      this.title = '编辑流程'
      this.process.process_id = row.id
      this.process.process_title = row.title
      this.process.process_worker = row.worker
      this.process.process_content = row.content
      this.process.process_about_file = row.aboutFile
      this.process.process_about_picture = row.aboutPicture
      const _this = this
      _this.$store.commit('getUpLoadFileUrll', row.aboutFile)
      // console.log(this.$store.state.upLoadUrl)
      _this.$store.commit('getShow', true)
    },
    handleClose () {
      this.newLawdialogVisible = false
    },
    deleteProcess (row) { // 删除流程
      console.log(row)
      this.$confirm('确认删除？')
        .then(_ => {
          const params = { id: row.id }
          process.deleteProcess(params).then(res => {
            this.$message({
              message: '删除成功',
              type: 'warning'
            })
            this.getList()
          })
        })
        .catch(_ => {})
    },
    selectProcess () { // 查询进程
      const params = { title: this.input, current: this.currentPage, size: this.pageSize }
      console.log(this.input)
      if (this.input === '') {
        this.getList()
      } else {
        process.getProcessList(params).then(res => {
          console.log(res)
          this.total = res.data.total
          this.tableData = res.data.records
        })
      }
    }
  }
}
</script>
 <style lang="scss" scoped>
 .input{
   height: 50px;
   background: #FFFFFF;
   font-size: 14px;
   border: 0px red solid;
   display: flex;
   align-items: center;
 }
 .table{
   background: #FFFFFF;
   font-size: 14px;
   padding: 10px;
 }
 .add{
   height: 50px;
   margin-top: 10px;
   padding-top: 5px;
   display: flex;
   align-items: center;
   background: #FFFFFF;
 }
 .block{
   height: 40px;
   background: #FFFFFF;
 }
 </style>
