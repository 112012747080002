import request from '../../utils/axios'

const presale = {
  getPresaleList: function (parames) { // 查询接口
    return request.get('edu/question/findListByProductId', { params: parames })
  },
  goodList: function (parames) { // 点赞公共
    return request.get('edu/question/fabulous', { params: parames })
  },
  editPresale: function (parames) { // 编辑接口
    return request.get('edu/question/update', { params: parames })
  },
  addPresale: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/question/add',
      method: 'get',
      params: parames
    })
  },
  deletePresale: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/question/delete', { params })
  }
}

export default presale
