<!-- 材料产品配置的组建 -->
<template>
    <div class="material_config">
        <el-row style="text-align: left;">
           <el-col :span="14">
             <span style="margin-left: 9px;">材料检索：</span>
             <el-select v-model="value" filterable remote :remote-method="moreMaterial" placeholder="请选择相关流程" style="width: 80%;">
                 <el-option
                   v-for="item in materialList"
                   :key="item.value"
                   :label="item.name"
                   :value="item.id">
                 </el-option>
             </el-select>
           </el-col>
           <el-col :span="5" style="margin-top: 10px;">
            <el-radio v-model="radio" label="0">代理机构</el-radio>
            <el-radio v-model="radio" label="1">企业自己</el-radio>
           </el-col>
           <el-col :span="2" :offset="1">
             <el-button type="primary" size="small" @click="AddMaterialProduct">添加材料</el-button>
           </el-col>
         </el-row>
         <el-row style="margin-top: 10px;">
           <el-col :span="24">
             <el-table
               :data="tableData"
               style="width: 100%">
               <el-table-column
                 prop="worker"
                 label="材料提供者"
                 :formatter="formatStatus"
                 width="100">
               </el-table-column>
               <el-table-column
                 prop="name"
                 label="材料名称"
                 width="250">
               </el-table-column>
               <el-table-column
                 prop="mark"
                 label="材料备注">
               </el-table-column>
               <el-table-column
                 fixed="right"
                 label="操作"
                 width="70">
                 <template slot-scope="scope">
                   <el-button
                     @click.native.prevent="deleteMaterialProduct(scope.row)"
                     type="text"
                     size="small">
                     移除
                   </el-button>
                 </template>
               </el-table-column>
             </el-table>
           </el-col>
         </el-row>
    </div>
   </template>

<script>
import material from '../../../api/product/material'
import materialProduct from '../../../api/product/materialProduct'
export default {
  name: 'material_config',
  components: {},
  data () {
    return {
      radio: '1',
      materialList: [],
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      value: ''
    }
  },
  computed: {
    formatStatus () {
      return (row) => {
        switch (row.worker) {
          case 0:
            return '机构提供'
          case 1:
            return '企业自己'
          default:
            return ''
        }
      }
    }
  },
  mounted () {
    this.getMaterialList()
    this.getMaterialProduct()
  },
  methods: {
    getMaterialList () { // 获得材料的列表
      const data = {
        current: this.currentPage,
        size: this.pageSize
      }
      material.getMaterialList(data).then(res => {
        this.materialList = res.data.records
      })
    },
    moreMaterial (query) { // 更多材料
      const data = {
        name: query
      }
      material.getTenMaterialList(data).then(res => {
        console.log(res)
        this.materialList = res.data
      })
    },
    getMaterialProduct () { // 获得材料和产品对应数据
      const data = {
        productId: this.$store.state.productId
      }
      materialProduct.getMaterialProductList(data).then(res => {
        this.tableData = res.data
      })
    },
    AddMaterialProduct () { // 增加一条数据
      if (this.value === '') {
        this.$message({
          message: '未选中任何材料',
          type: 'info'
        })
      } else {
        const data = {
          productId: this.$store.state.productId,
          myselfId: this.value,
          worker: this.radio,
          status: 1
        }
        materialProduct.addMaterialProduct(data).then(res => {
          if (res.code === 0) {
            this.newLawdialogVisible = false
            this.$message({
              message: '新增成功',
              type: 'success'
            })
            this.value = null
            this.getMaterialProduct()
          }
        })
      }
    },
    deleteMaterialProduct (row) { // 移除一条数据
      const params = { id: row.aboutId }
      materialProduct.deleteMaterialProduct(params).then(res => {
        console.log(res)
        if (res.code === 0) {
          this.$message({
            message: '移除成功',
            type: 'success'
          })
          this.getMaterialProduct()
        }
      })
    }
  }
}
</script>
   <style lang="scss" scoped>
   .product_config{
     margin-top: -25px;
   }
   </style>
