import request from '../../utils/axios'

const company = {
  getCompanyList: function (parames) { // 查询接口
    return request.get('edu/company/findList', { params: parames })
  },
  getTenCompanyList: function (parames) { // 查询接口10个
    return request.get('edu/company/findTenCompany', { params: parames })
  },
  editCompany: function (parames) { // 编辑接口
    return request.get('edu/company/update', { params: parames })
  },
  addCompany: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/company/add',
      method: 'get',
      params: parames
    })
  },
  deleteCompany: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/company/delete', { params })
  }
}

export default company
