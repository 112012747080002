<!-- 法律产品配置的组建 -->
<template>
    <div class="law_config">
      <el-row v-for="item in this.tagList" :key="item.id">
        <el-col :span="5"><h3>{{ item.name }}</h3></el-col>
        <el-col :span="19" style="text-align: left;margin-top: 10px;" >
              <el-radio :label="tag.id" v-model="item.selectedValueId" border  @change="addOrUpdateTag(item)" v-for="tag in item.valueList
" :key="tag.id">{{ tag.name }}</el-radio>
        </el-col>
      </el-row>
    </div>
   </template>

<script>
import tag from '../../../api/product/tag'
import tagProduct from '../../../api/product/tagProduct'
export default {
  name: 'tag_config',
  components: {},
  data () {
    return {
      tagList: [],
      currentPage: 1,
      pageSize: 30
    }
  },
  mounted () {
    this.getTag()
  },
  methods: {
    addOrUpdateTag (item) { // 获取初始标签新增或者删除
      console.log(item)
      const data = {
        myselfId: item.id,
        valueId: item.selectedValueId,
        productId: this.$store.state.productId
      }
      tagProduct.addOrEditTag(data).then(res => {
        console.log(res)
      })
    },
    getTag () { // 获取初始标签
      const data = {
        current: this.currentPage,
        size: this.pageSize,
        productId: this.$store.state.productId
      }
      tag.getTag(data).then(res => {
        console.log(res)
        this.tagList = res.data.records
      })
    }
  }
}
</script>
   <style lang="scss" scoped>
   .product_config{
     margin-top: -25px;
   }
   </style>
