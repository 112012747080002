<!-- 客户类型占比 -->
<template>
 <div class="customer">
    <div class="echart" id="mychartPie" :style="myChartStyleRadar" style="margin-top: -10px;"></div>
 </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: '',
  components: {},
  data () {
    return {
      myChartStyleRadar: { float: 'left', width: '100%', height: '560px' },
      CustomerOption: {
        legend: {
          // 图例
          x: 'right',
          y: 'top',
          top: 70,
          left: 900,
          orient: 'vertical'
        },
        toolbox: {
          // 生成图片
          feature: {
            saveAsImage: {}
          }
        },
        title: {
          // 设置饼图标题，位置设为顶部居中
          text: '用户画像占比图:',
          top: '0%',
          left: 'left'
        },
        series: [
          {
            type: 'pie',
            label: {
              show: true,
              formatter: '{b}\n({c})\n{d}%' // b代表名称，c代表对应值，d代表百分比
            },
            radius: '45%', // 饼图半径(管图的大小)
            data: []
          }
        ]
      }
    }
  },
  mounted () {
    this.CustomerOption.series[0].data = this.$store.state.customerPieDate
    this.$nextTick(
      () => {
        this.initEcharts()
      }
    )
  },
  methods: {
    initEcharts () {
      var mychartPie = echarts.init(document.getElementById('mychartPie'))
      mychartPie.setOption(this.CustomerOption)
      window.addEventListener('resize', () => {
        this.myChartMoney.resize()
      })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
