<!-- 企业管理 -->
<template>
  <div class="personMessage">
    <div class="message">
      <el-row>
        <el-col :span="22">
          <div>公司名：{{ company.name }}</div>
          <div style="margin-left: 150px;margin-top: 5px;letter-spacing: 1px;">{{company.slogan}}</div>
        </el-col>
        <el-col :span="2">
          <img src="https://fengyutang-1305685513.cos.ap-beijing.myqcloud.com/logo/logo.png" alt="logo" style="width:50px;height:50px" lazy>
        </el-col>
      </el-row>
    </div>
    <div class="basic">
      <div style="float: right;">
        <el-button icon="el-icon-edit" circle></el-button>
      </div>
      <el-descriptions title="基本信息">
          <el-descriptions-item label="用户名">kooriookami</el-descriptions-item>
          <el-descriptions-item label="手机号">18100000000</el-descriptions-item>
          <el-descriptions-item label="居住地">苏州市</el-descriptions-item>
          <el-descriptions-item label="备注">
            <el-tag size="small">学校</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="联系地址">江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="basic">
      <el-row>
        <el-col :span="16">
          <template>
            <el-table
              :data="tableData"
              height="250"
              border
              style="width: 100%">
              <el-table-column
                prop="date"
                label="日期"
                width="180">
              </el-table-column>
              <el-table-column
                prop="name"
                label="姓名"
                width="180">
              </el-table-column>
              <el-table-column
                prop="address"
                label="地址">
              </el-table-column>
            </el-table>
          </template>
        </el-col>
        <el-col :span="8">
          <template>
            <el-table
              :data="tableData"
              height="250"
              border
              style="width: 100%">
              <el-table-column
                prop="date"
                label="日期"
                width="180">
              </el-table-column>
              <el-table-column
                prop="name"
                label="姓名"
                width="180">
              </el-table-column>
              <el-table-column
                prop="address"
                label="地址">
              </el-table-column>
            </el-table>
          </template>
        </el-col>
      </el-row>
    </div>
     <div class="table">
      <div style="text-align: left;">
        <h4>高管信息</h4>
      </div>
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="一致性 Consistency" name="1">
          <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
          <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
        </el-collapse-item>
        <el-collapse-item title="反馈 Feedback" name="2">
          <div>控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；</div>
          <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
        </el-collapse-item>
        <el-collapse-item title="效率 Efficiency" name="3">
          <div>简化流程：设计简洁直观的操作流程；</div>
          <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
          <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div>
        </el-collapse-item>
        <el-collapse-item title="可控 Controllability" name="4">
          <div>用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；</div>
          <div>结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。</div>
        </el-collapse-item>
      </el-collapse>
     </div>
  </div>
 </template>

<script>
export default {
  name: 'personMessage',
  components: {},
  data () {
    return {
      windowHeight: 0,
      company: null,
      activeNames: ['1'],
      tableData: [{
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-08',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-06',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-07',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }]
    }
  },
  mounted () {
    this.company = JSON.parse(localStorage.getItem('companyHistory')) // 选中的公司
  },
  methods: {
    handleChange (val) {
      console.log(val)
    }
  }
}
</script>
 <style lang="scss" scoped>
 .message{
  padding: 15px;
  background: #FFFFFF;
  height: 50px;
 }
 .table{
   margin-top: 5px;
   background: #FFFFFF;
   padding: 15px;
   padding-bottom: 160px;
 }
 .basic{
   margin-top: 10px;
   background: #FFFFFF;
   padding: 15px;
 }
 </style>
