<!-- 登录页面 -->
<template>
  <div>
    <!-- <Head></Head> -->
    <div class="out" :style="csstop2">
      <el-card class="card">
        <el-row>
          <el-col :span="24">
            <h2>手机号登录/注册</h2>
            <span style="color: #cdcdcd;font-size: 15px;">未注册过的手机号将自动创建账号</span>
            <el-form :model="user" ref="user" :rules="loginRules" class="demo-ruleForm">
              <el-form-item prop="phone">
                <el-input placeholder="请输入手机号" v-model="user.phone" class="input-with-select">
                  <el-button slot="prepend" icon="el-icon-mobile-phone"></el-button>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input placeholder="请输入密码" v-model="user.password" class="input-with-select" showPassword>
                  <el-button slot="prepend" icon="el-icon-unlock" ></el-button>
                </el-input>
              </el-form-item>
              <el-form-item class="agreement">
                <el-button type="primary" size="medium" @click="Login" style="width: 270px;height: 40px;margin-top: 20px;">注册/登录</el-button>
              </el-form-item>
            </el-form>
            <!-- 用户协议模块 -->
            <div class="agreement" style="margin-top: 35px;">
              <el-checkbox v-model="checked"></el-checkbox>
              <span style="margin-left: 5px;font-size: 14px;">点击登录视同同意《用户协议》</span>
            </div>
            <div style="margin-top: 30px;font-size: 14px;">
              暂时不登录，返回&nbsp;<el-button @click="BackToStudy" type="text">学习中心</el-button>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
 </template>

<script>
import Login from '../../api/other/login'
import getheight from '../../utils/getheight'

export default {
  name: 'personMessage',
  components: {
    // Head
  },
  mixins: [getheight],
  data () {
    return {
      windowHeight: 0,
      checked: true,
      user: {
        phone: '',
        password: ''
      },
      loginRules: {
        phone: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'blur'
          },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: '手机号格式不正确',
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: '密码不能为空',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 16,
            message: '密码长度正确，应该为6-16位',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    csstop2 () {
      return {
        '--top': this.windowHeight - 0 + 'px'
      }
    }
  },
  mounted () {

  },
  methods: {
    Login () { // 登录按钮
      if (this.checked) {
        const data = {
          phone: this.user.phone,
          password: this.user.password
        }
        Login.addOrLoginUser(data).then(res => {
          console.log(res.data)
          // 登录不成功
          if (res.data.id === null) {
            this.$message.error('账号或密码不正确')
          } else {
            // 登录成功
            this.$router.push('/personMessage')
            // 登录信息存入缓存
            localStorage.setItem('user', JSON.stringify(res.data))
            // 设置登录状态为登录
            localStorage.setItem('ifLogin', JSON.stringify(true))
            console.log('登录成功')
            console.log(JSON.parse(localStorage.getItem('ifLogin')))
            if (res.data.company.length > 0) {
              // 如果企业 字段 长度大于零说明有关联企业
              // 将企业状态设置为 有企业
              localStorage.setItem('ifCompany', JSON.stringify(true))
              // 并将企业信息存入缓存
              localStorage.setItem('companyList', JSON.stringify(res.data.company))
              // console.log(456)
              // console.log(localStorage.getItem('company'))
              // 同时设定选中的企业
              if (res.data.companyHistory === null) {
                // console.log(123)
                localStorage.setItem('companyHistory', JSON.stringify(res.data.company[0]))
              }
            } else { // 如果小于0，就说明没有关联企业
              localStorage.setItem('ifCompany', JSON.stringify(false))
            }
            this.$message.success('登录成功')
          }
        })
      } else {
        this.$message.error('请先勾选同意用户协议！！！')
      }
    },
    BackToStudy () { // 返回学习中心
      this.$router.push('/studyland')
    }
  }
}
</script>
 <style lang="scss" scoped>
 .out{
  height: var(--top);
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: 0px;
  display: flex; /* 使用Flexbox布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background-image: url('https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/login.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.card{
  height: 460px;
  width: 350px;
  margin-left: 53%;
  border-radius: 15px;
  margin-top: 45px;
  padding: 15px;
}
.demo-ruleForm{
  margin-top: 20px;
}
.agreement{
  display: flex; /* 使用Flexbox布局 */
  justify-content: center; /* 水平居中 */
}
.input-with-select{
  width: 98%;
}
 </style>
