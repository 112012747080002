<template>
  <div class="personLeft">
    <el-menu
      router
      :default-active="$route.path"
      default-openeds="1"
      unique-opened
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose">
      <el-submenu index="1">
          <template slot="title">
              <i class="el-icon-location"></i>
              <span>个人中心</span>
          </template>
          <el-menu-item-group>
              <el-menu-item index="/personMessage" v-show="[1, 2, 3].includes(2)">基础信息</el-menu-item>
              <el-menu-item index="/productall">企业管理</el-menu-item>
              <el-menu-item index="/productall">业绩汇总</el-menu-item>
              <el-menu-item index="/areaList">合同管理</el-menu-item>
              <el-menu-item index="/attributeList">海报管理</el-menu-item>
              <el-menu-item index="/tagList">方案管理</el-menu-item>
              <el-menu-item index="/product/skulist">我的评论</el-menu-item>
              <el-menu-item index="/product/skulist">我的日报</el-menu-item>
          </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'productleft',
  components: {},
  data () {
    return {
      defalut: []
    }
  },
  created () {},
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    }
  },
  mounted () {},
  computed: {}
}

</script>
<style scoped>
.el-menu-vertical-demo{
  border-right: none;
}
</style>
