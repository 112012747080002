<!-- 标签页面 -->
<template>
  <div class="tag">
     <el-row class="input">
       <el-col :span="2">标签名</el-col>
         <el-col :span="5">
           <el-input
             size="small"
             style="width: 250px;"
             placeholder="请输入标签名"
             @change="selectTag"
             v-model="input_name">
           </el-input>
         </el-col>
         <el-col :span="2">标签值</el-col>
         <el-col :span="5">
           <el-input
             size="small"
             style="width: 250px;"
             placeholder="请输入标签值"
             @change="selectTag"
             v-model="input_value">
           </el-input>
         </el-col>
     </el-row>
     <!-- 新增标签按钮 -->
     <el-row class="add">
       <el-col :span="2">
         <el-button @click="newTag" type="primary" size="small" v-show="[1, 2, 3].includes(3)">新增标签</el-button>
       </el-col>
     </el-row>
     <!-- 标签表格 -->
     <el-row class="table">
       <el-col :span="24">
         <el-table
           :data="tableData"
           stripe
           :height=windowHeight-250
           style="width: 100%">
           <el-table-column
             prop="name"
             label="标签名"
             width="180">
           </el-table-column>
           <el-table-column
             prop="value"
             show-overflow-tooltip
             label="标签值"
             width="200">
           </el-table-column>
           <el-table-column
             prop="quantity"
             label="标签值数量"
             width="120">
           </el-table-column>
           <el-table-column
             prop="createName"
             label="操作人"
             width="100">
           </el-table-column>
           <el-table-column
             prop="createTime"
             label="上传时间"
             width="180">
           </el-table-column>
           <el-table-column
             prop="editName"
             label="最后编辑人"
             width="100">
           </el-table-column>
           <el-table-column
             prop="lastEditTime"
             label="最后编辑时间"
             width="180">
           </el-table-column>
           <el-table-column
             fixed="right"
             label="操作"
             width="120">
             <template slot-scope="scope">
               <el-button @click="editLaw(scope.row)" type="text" size="small">编辑标签</el-button>
               <el-button @click="deleteAttName(scope.row)" type="text" size="small">删除</el-button>
             </template>
           </el-table-column>
         </el-table>
       </el-col>
     </el-row>
     <div class="block">
       <el-row>
         <!-- 分页功能 -->
         <el-col :span="12" :offset="10">
           <el-pagination
             @size-change="handleSizeChange"
             @current-change="handleCurrentChange"
             :current-page="currentPage"
             :page-sizes="[10, 20, 30, 40]"
             :page-size="10"
             layout="total, sizes, prev, pager, next, jumper"
             :total="this.total">
           </el-pagination>
         </el-col>
       </el-row>
     </div>
     <!-- // 新增和编辑的模板 -->
     <el-dialog
       :title="title"
       :visible.sync="dialogVisible"
       width="48%"
       :before-close="handleClose">
       <span>
         <el-form ref="law" :model="tag" label-width="80px">
           <el-form-item label="标签名称">
             <el-col :span="18">
               <el-input v-model="tag.name" placeholder="请输入标签名"></el-input>
             </el-col>
           </el-form-item>
           <el-form-item label="标签值名">
             <el-input type="textarea" v-model="tag.value" :rows="7" placeholder="每行一个标签值"></el-input>
           </el-form-item>
         </el-form>
       </span>
       <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="finishAdd">确 定</el-button>
      </span>
     </el-dialog>
     <!-- 编辑标签 -->
     <el-drawer
      title="编辑标签"
      :visible.sync="drawer"
      :before-close="handleClose">
      <el-row style="padding-left: 20px;">
        <el-col :span="16"><el-input v-model="name" placeholder="请输入新的标签名" size="small"></el-input></el-col>
        <el-col :span="6"><el-button type="success" round size="small" @click="finishEditAttName">更新标签名</el-button></el-col>
      </el-row>
      <el-row style="padding-left: 20px;margin-top: 10px;">
        <el-col :span="16"><el-input v-model="value" placeholder="请输入新的标签值" size="small"></el-input></el-col>
        <el-col :span="6"><el-button type="primary" size="small" @click="addAttValue">新增标签值</el-button></el-col>
      </el-row>
      <el-row style="padding: 20px;">
        <template>
        <el-table
          :data="att_tableData"
          style="width: 100%">
          <el-table-column
            prop="name"
            label="标签值">
          </el-table-column>
          <el-table-column
          fixed="right"
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button @click="deleteTagValue(scope.row)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
        </el-table>
      </template>
      </el-row>
    </el-drawer>
  </div>
 </template>

<script>
import getheight from '../../../utils/getheight'
import tag from '../../../api/product/tag'

export default {
  name: 'law',
  mixins: [getheight],
  components: {},
  data () {
    return {
      input: '',
      title: '新增标签',
      drawer: false,
      windowHeight: 0,
      tableData: [],
      att_tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: null,
      dialogVisible: false,
      tag: {
        name: '',
        value: ''
      },
      input_name: '',
      input_value: '',
      input_product: '',
      name: '',
      value: '',
      id: ''
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () { // 获得规定的列表
      const data = {
        current: this.currentPage,
        size: this.pageSize,
        name: this.input_name,
        value: this.input_value,
        product: this.input_product
      }
      tag.getTag(data).then(res => {
        console.log(res)
        this.total = res.data.total
        this.tableData = res.data.records
      })
    },
    newTag () { // 打开新增弹窗
      this.dialogVisible = true
      this.title = '新增标签'
      this.tag = {}
    },
    finishAdd () { // 新增标签组
      console.log('-------')
      const data = {
        name: this.tag.name,
        value: this.tag.value,
        createId: this.$store.state.userInfo.userId
      }
      tag.addTag(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          console.log('成功了！')
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.dialogVisible = false
          this.getList()
        }
      })
      console.log('----+++====++---')
    },
    editLaw (row) { // 打开编辑
      this.drawer = true
      this.name = row.name
      this.id = row.id
      this.getTagValue()
    },
    getTagValue () { // 获取标签值表
      const data = {
        current: this.currentPage,
        size: this.pageSize,
        tagId: this.id
      }
      tag.getTagValue(data).then(res => {
        console.log(res)
        this.att_tableData = res.data
      })
    },
    deleteAttName (row) { // 删除标签名
      console.log(row.id)
      const data = {
        id: row.id
      }
      tag.deleteTagName(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          console.log('成功了！')
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        } else {
          this.$message({
            message: res.message,
            type: 'danger'
          })
        }
      })
    },
    deleteTagValue (row) { // 删除标签值
      console.log('--------------')
      console.log(row.id)
      const data = {
        id: row.id
      }
      tag.deleteTagValue(data).then(res => {
        console.log(res)
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.getList()
      })
    },
    finishEditAttName () { // 编辑标签名
      const data = {
        id: this.id,
        editId: this.$store.state.userInfo.userId,
        name: this.name,
        status: 1
      }
      tag.editTagName(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          console.log('成功了！')
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    addAttValue () { // 新增标签值
      const data = {
        tagId: this.id,
        name: this.value
      }
      tag.addTagValue(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          console.log('成功了！')
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
          this.drawer = true
        }
      })
    },
    handleClose () { // 关闭弹窗
      this.dialogVisible = false
      this.drawer = false
    },
    // 翻页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getList()
    },
    selectTag () { // 查询
      const params = { name: this.input }
      if (this.input === '') {
        this.getList()
      } else {
        tag.getTag(params).then(res => {
          console.log(res)
          this.total = res.data.total
          this.tableData = res.data.records
        })
      }
    }
  }
}
</script>
 <style lang="scss" scoped>
 .input{
   height: 50px;
   background: #FFFFFF;
   font-size: 14px;
   border: 0px red solid;
   display: flex;
   align-items: center;
 }
 .table{
   background: #FFFFFF;
   font-size: 14px;
   padding: 10px;
 }
 .add{
   height: 50px;
   margin-top: 10px;
   padding-top: 5px;
   display: flex;
   align-items: center;
   background: #FFFFFF;
 }
 .block{
   height: 40px;
   background: #FFFFFF;
 }
 </style>
