<template>
 <div class="access">
  <Head></Head>
  <el-row style="padding: 20px;">
    <el-col :span="7">
      <h3>最新数据:</h3>
      <div class="number">
        <div style="font-weight: bold;">今日日活:<span style="font-size: 30px;">1211个</span></div>
        <div style="margin-top: 10px;color: azure;">昨天日活:109个&nbsp;&nbsp;&nbsp;增长率：15%</div>
      </div>
      <h3>客户分布:</h3>
      <div>排名前8的省份</div>
    </el-col>
    <el-col :span="11">
      <h3>最近趋势:</h3>
      <div style="text-align:center;">
        <span class="demonstration">请选择时间区间（默认最近7天）</span>
        <el-date-picker
          v-model="value"
          @change="getData"
          value-format="yyyy-MM-DD"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <el-card class="card">这线图</el-card>
    </el-col>
    <el-col :span="6" style="padding: 0px 25px">
      <h3>产品排名:</h3>
      <template>
        <el-table
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          @current-change="handleCurrentChange"
          style="width: 100%">
          <el-table-column
            type="index"
            label="名次"
            width="50">
          </el-table-column>
          <el-table-column
            property="name"
            label="产品名"
            width="150">
          </el-table-column>
          <el-table-column
            property="number"
            label="点击数"
            width="120">
          </el-table-column>
        </el-table>
      </template>
    </el-col>
  </el-row>
 </div>
</template>

<script>
import Head from '../../components/Head'
export default {
  name: '',
  userIP: null,
  components: {
    Head
  },
  data () {
    return {
      tableData: [
        { name: '普通有限公司' }, { name: '专名ing胜地' }],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      value: ''
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    getData () {
      console.log(this.value)
    }
  }
}
</script>
<style lang="scss" scoped>
.card{
  margin-top: 10px;
  margin: 25px;
}
.number{
  background: linear-gradient(to top, #1790ff, #00bfff);
  color: #FFFFFF;
  padding: 15px;
  height: 75px;
  margin: 25px;
}
</style>
