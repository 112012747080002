<!-- 主页 -->
<template>
 <div class="home">
  <Head></Head>
  <div class="content" :style="csstop">
    <div class="welcome">欢迎词的内容{{ windowHeight }}
      <!-- 游客身份看到的内容 -->
      <div>您当前的身份为游客！登录后，可以进行，在线考试、进入个人中心</div>
      <!-- 学习者能看到的内容 -->
      <div>您当前的身份为学习者！挂载企业后，可以查看企业授权的全部信息</div>
      <!-- 挂载企业能看到的内容 -->
      <div>好了，现在给用户呈现一些他最想看到的东西！</div>
    </div>
  </div>
 </div>
</template>

<script>
import Head from '../../components/Head.vue'
import getheight from '../../utils/getheight'

export default {
  name: 'home',
  mixins: [getheight],
  components: {
    Head
  },
  data () {
    return {
      windowHeight: 0
    }
  },
  computed: {
    csstop () {
      return {
        '--top': this.windowHeight - 60 + 'px'
      }
    }
  },
  mounted () {
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.content{
  width: 100%;
  height: var(--top);
  display: flex;
  // border: 1px red solid;
  justify-content: center;
  align-items: center;
  .welcome{
    border: 1px blue solid;
    height: 600px;
    width: 70%;
    border-radius: 10px;
  }
}
</style>
