
<template>
 <div class="">

 </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>

</style>

<!-- <template>
    <div>
        <span @click="handleScreenFull">
            <i class="el-icon-rank" v-if="screenState">1</i>
            <i class="el-icon-full-screen" v-else>2</i>
        </span>
    </div>
</template>

<script>

import screenfull from 'screenfull'

export default {

  data () {
    return {
      screenState: false
    }
  },
  created () {
    this.init()
  },
  beforeDestroy () {
    if (screenfull.isEnabled) {
      screenfull.off('change', this.change)
    }
  },

  methods: {

    // 注册全屏的change事件

    init () {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change)
      }
    },

    change () {
      this.screenState = screenfull.isFullscreen
    },

    // 点击切换全屏

    handleScreenFull () {
      if (screenfull.isEnabled) {
        screenfull.toggle()
      } else {
        this.$message.info('你的浏览器不支持全屏')
      }
    }

  }

}
</script> -->
