import request from '../../utils/axios'

const tag = {
  getTag: function (parames) { // 查询接口
    return request.get('edu/tagName/findList', { params: parames })
  },
  getTagValue: function (parames) { // 查询接口
    return request.get('edu/tagValue/findByTagId', { params: parames })
  },
  editTagName: function (parames) { // 编辑标签名接口
    return request.get('edu/tagName/update', { params: parames })
  },
  addTag: function (parames) { // 新增标签名接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/tagName/add',
      method: 'get',
      params: parames
    })
  },
  addTagValue: function (parames) { // 新增标签值接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/tagValue/add',
      method: 'get',
      params: parames
    })
  },
  deleteTagName: function (params) { // 删除标签名接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/tagName/delete', { params })
  },
  deleteTagValue: function (params) { // 删除标签值接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/tagValue/delete', { params })
  }
}

export default tag
