<!-- 学习中心的总页面 -->
<template>
 <div class="studyland">
  <div class="headout">
    <div class="headin">
      <Head></Head>
    </div>
  </div>
    <el-container>
      <aside style="height: 800px;">
        <div style="background: #FFFFFF;padding-top: 10px;font-weight: bold;">
          <el-row style="text-align: left;">
            <el-col :span="18" :offset="2">产品目录</el-col>
            <!-- <el-col :span="4"><i class="el-icon-refresh"></i></el-col> -->
          </el-row>
        </div>
      <!-- 这是左边的导航栏 -->
        <div ref="resize" class="resize">
        <div ref="resizeHandle" class="handle-resize">
          <el-tree
              :data="data"
              node-key="id"
              style="background-color: white;"
              :default-expanded-keys="productIdList"
              :default-checked-keys="[]"
              :indent="0"
              highlight-current
              @node-click="handleNodeClick"
              accordion
              :props="defaultProps"
              :filter-node-method="filterNode"
              ref="tree">
              <template v-slot="{ node, data }">
                <span class="custom-tree-node">
                  <span>
                    <!-- <img v-if="!data.children&&node.level!==1" src="../../assets/icons/tree_open_1.png" alt=""> -->
                    <!-- 一级关闭图标 -->
                    <img v-if="node.expanded&&node.level==1" src="../../assets/icons/tree_close_1.png" alt="">
                    <!-- 一级展开图标 -->
                    <img v-else-if="!node.expanded&&node.level==1" src="../../assets/icons/tree_open.png" alt="">
                    <!-- 二级关闭图标 -->
                    <img v-show="node.data.children" v-else-if="node.expanded&&node.level==2" src="../../assets/icons/tree_close_1.png" alt="">
                    <!-- 二级展开图标 -->
                    <img v-show="node.data.children" v-else-if="!node.expanded&&node.level==2" src="../../assets/icons/tree_open.png" alt="">
                    <!-- 二级关闭图标 -->
                    <img v-show="node.data.children" v-else-if="node.expanded&&node.level==3" src="../../assets/icons/tree_close_1.png" alt="">
                    <!-- 二级展开图标 -->
                    <img v-show="node.data.children" v-else-if="!node.expanded&&node.level==3" src="../../assets/icons/tree_open.png" alt="">
                  </span>
                  <div class="label" :style="fontSize(node,data)">{{ node.label }}</div>
                </span>
              </template>
            </el-tree>
        </div>
        </div>
      </aside>
      <!-- 这是主体部分 -->
     <el-container>
        <!-- 这是搜索的位置 -->
        <el-header>
          <div class="top">
            <el-row class="input">
              快速查询
              <el-col :span="5">
                <el-input
                  size="small"
                  style="width: 250px;"
                  placeholder="输入产品关键字快速查询"
                  v-model="filterText">
                  <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
              </el-col>
              <div @mouseenter="onMouseOver"  @mouseleave="onMouseOut" style="color: #c0c0c0;">历史记录</div>
              <el-col :span="4">
                <el-tag type="success">{{ this.$store.state.productName }}</el-tag>
              </el-col>
              <el-col :span="5" :offset="4">
                <el-button type="text" icon="el-icon-edit">设置</el-button>
                <el-button type="text" icon="el-icon-edit">纠错</el-button>
                <el-button type="text" icon="el-icon-edit">说明</el-button>
                &nbsp;<router-link style="font-size: 14px;font-weight: bold;" target="_blank" to="videoTutorials">视频教程</router-link>
              </el-col>
              <el-col :span="2">
                 <FullScreen></FullScreen>
              </el-col>
            </el-row>
          </div>
        </el-header>
        <!-- 历史记录 -->
        <div class="history" v-if="history" @mouseenter="onMouseOver"  @mouseleave="onMouseOut2">
          <ul v-for="(item, index) in this.productList" :key="index" style="text-align: left;">
            <li style="width: 25%;float: left;margin-bottom: 10px;" @click="chooseInHistory(item)">{{item.label}}</li>
          </ul>
        </div>
        <!-- 这是学习中心核心内容 -->
        <el-mian class="main" v-if="ifRefresh">
          <el-tabs tab-position="right" class="tabs" v-model="tabsNum" @tab-click="changeTbaNum">
            <el-tab-pane label="产品概述" name="1" class="tab" :lazy="true">
              <Productbasic></Productbasic>
            </el-tab-pane>
            <el-tab-pane label="知识拓展" name="2" class="tab" :lazy="true">
              <Productexpand></Productexpand>
            </el-tab-pane>
            <el-tab-pane label="产品海报" name="3" class="tab" :lazy="true">
             <ProductPoster></ProductPoster>
            </el-tab-pane>
            <el-tab-pane label="售前咨询" name="4" class="tab" :lazy="true">
              <Presales></Presales>
            </el-tab-pane>
            <el-tab-pane label="交付案例" name="5" class="tab" :lazy="true">
              <ProductCase></ProductCase>
            </el-tab-pane>
            <el-tab-pane label="流程与材料" name="6" class="tab" :lazy="true">
              <ProductMaterial></ProductMaterial>
            </el-tab-pane>
            <el-tab-pane label="产品报价" name="7" class="tab">定时任务补偿，呈现本公司不同sku最近的成交价，平均价等信息</el-tab-pane>
            <el-tab-pane label="方案与合同" name="8" class="tab">行业与企业</el-tab-pane>
            <el-tab-pane label="优势与竞品" name="9" class="tab">1、呈现企业想让员工看到的其他信息</el-tab-pane>
            <el-tab-pane label="补贴明细" name="10" class="tab">
              <div>销售合同最好不要自己独占一个页面</div>
              <div>销售合同只有企业自己的，没有行业通用的</div>
              <div>只能法务上传，上传的位置应在的企业管理产品的位置，设置一个合同中心，管理的地方，只有一个匹配，并加一个备注，区分具体某个spu！</div>
            </el-tab-pane>
            <el-tab-pane label="售后须知" name="11" class="tab">1、关联产品；2、年检等相关情况；3、关于退费；4、本公司成单业务员明细</el-tab-pane>
            <el-tab-pane label="友情链接" name="12" class="tab">
              <div>销售合同最好不要自己独占一个页面</div>
              <div>销售合同只有企业自己的，没有行业通用的</div>
              <div>只能法务上传，上传的位置应在的企业管理产品的位置，设置一个合同中心，管理的地方，只有一个匹配，并加一个备注，区分具体某个spu！</div>
            </el-tab-pane>
          </el-tabs>
        </el-mian>
     </el-container>
    </el-container>
 </div>
</template>

<script>
import Head from '../../components/Head'
import getheight from '../../utils/getheight'
import Productbasic from '../../components/study/Productbasic'
import Productexpand from '../../components/study/Productexpand'
import ProductPoster from '../../components/study/ProductPoster'
import ProductCase from '../../components/study/ProductCase'
import Presales from '../../components/study/Presales'
import ProductMaterial from '../../components/study/ProductMaterial'
import FullScreen from '../../components/utils/FullScreen'
import access from '../../api/other/access'
import product from '../../api/product/product'
import axios from 'axios'

export default {
  name: 'studyland',
  props: {
    resizeConf: {
      type: Object,
      default: () => ({
        width: 200, // 初始宽度
        widthRange: [200, 250] // 宽度范围
      })
    }
  },
  mixins: [getheight],
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    }
  },
  components: {
    Head,
    Productbasic,
    Productexpand,
    ProductPoster,
    Presales,
    ProductCase,
    ProductMaterial,
    FullScreen
  },
  computed: {
    csstop () {
      return {
        '--left': this.windowHeight - 67 + 'px'
      }
    }
  },
  data () {
    return {
      tabsNum: '1',
      num: 0, // 控制移入移出
      history: false,
      productIdList: [], // 控制选中的id
      productList: [], // 历史选择记录
      tabPosition: 'left', // 学习内容导航栏
      ip: null, // 用户的ip地址
      windowHeight: 0,
      filterText: '',
      data: [],
      ifRefresh: true,
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  mounted () {
    // 处理第一个被选中的产品
    if (localStorage.getItem('productId') === null) {
      this.productIdList.push(1039)
      localStorage.setItem('productId', 1039)
    } else {
      this.productIdList.push(parseInt(localStorage.getItem('productId')))
    }
    this.findTree()
    // this.data = JSON.parse(localStorage.getItem('TreeData'))
    // 用户信息
    this.getUserIp().then(data => {
      this.ip = data.ip
    })
    // 处理tab的值
    if (localStorage.getItem('tabsNum') === null) {
      this.tabsNum = '1'
    } else {
      this.tabsNum = localStorage.getItem('tabsNum')
    }
    // 处理历史记录的值-----------------------------------
    if (localStorage.getItem('productList') === null) {
      this.productList = [] //
    } else {
      this.productList = JSON.parse(localStorage.getItem('productList')) //
    }
    this.dragControllerDiv(this.$refs.resize, this.$refs.resizeHandle)
  },
  methods: {
    findTree () { // 获得产品导航栏
      product.getTree().then(res => {
        this.data = res.data
        // localStorage.setItem('TreeData', JSON.stringify(res.data))
      })
    },
    async getUserIp () {
      try {
        const response = await axios.get('https://ipinfo.io/json')
        return response.data
      } catch (error) {
        console.error('Error fetching IP address:', error)
      }
    },
    // 鼠标移入
    onMouseOver () {
      this.history = true
      this.num++
    },
    // 鼠标移出
    onMouseOut () {
      setTimeout(() => {
        if (this.num > 1) {
          this.history = true
        } else {
          this.history = false
          this.num = 0
        }
      }, 500)
    },
    onMouseOut2 () {
      this.history = false
      this.num = 0
    },
    changeTbaNum (tab) { // 存tab选中数字
      localStorage.setItem('tabsNum', tab._props.name)
    },
    filterNode (value, data) { // 过滤产品
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    chooseInHistory (item) { // 从历史记录中选择产品
      console.log(item)
      localStorage.setItem('productId', item.id)
      // 关掉历史记录
      this.history = false
      // 刷新页面
      this.updateRefresh()
    },
    updateRefresh () { // 重新选择产品
      // console.log('刷新了！')
      this.ifRefresh = false
      this.$nextTick(() => {
        this.ifRefresh = true
        this.tabsNum = '1'
      })
    },
    handleNodeClick (data) { // 选中的产品
      if (data.children === null) {
        localStorage.setItem('productId', data.id)
        this.accessRecords()
        if (this.productList.length <= 19) { // 真实数据中要去重复++++++++++++++++++++++++++
          this.productList.push(data)
          localStorage.setItem('productList', JSON.stringify(this.productList))
        } else {
          this.productList.shift()
          this.productList.push(data)
          localStorage.setItem('productList', JSON.stringify(this.productList))
        }
        // 刷新页面
        this.updateRefresh()
      }
    },
    accessRecords () { // 访问记录
      const data = {
        companyId: JSON.parse(localStorage.getItem('companyHistory')).id,
        visitorId: JSON.parse(localStorage.getItem('user')).id,
        productId: localStorage.getItem('productId'),
        ip: this.ip
      }
      console.log(data)
      access.addAccess(data).then(res => {
        if (res.code === 0) {
          this.dialogVisibleNew = false
          this.$message({
            message: '新增成功',
            type: 'success'
          })
        }
      })
    },
    fontSize (node, data) {
      if (node.level === 1 || node.level === 2) return 'font-size:16px' // 一二级字体
      return 'font-size:' + (17 - node.level * 1) + 'px' // 三级以下字号减小
    },
    // 控制导航栏伸缩
    dragControllerDiv: function (resizeBox, resizeHandle) {
      resizeBox.style.width = this.resizeConf.width + 'px'
      // 鼠标按下事件
      resizeHandle.onmousedown = e => {
        const resizeWidth = resizeBox.offsetWidth
        const startX = e.clientX // 水平坐标
        // 鼠标拖动事件
        document.onmousemove = ev => {
          const moveX = ev.clientX
          const moveLen = resizeWidth + (moveX - startX)
          if (this.resizeConf.widthRange[0] <= moveLen && this.resizeConf.widthRange[1] >= moveLen) {
            resizeBox.style.width = moveLen + 'px'
          }
        }
        // 鼠标松开事件
        document.onmouseup = function () {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.history{
  background: white;
  border: 1px solid #f00606;
  height: 200px;
  width: 70%;
  padding: 10px;
  margin-top: 65px;
  position: fixed;
  z-index: 12;
}
// 拉伸导航栏----
.resize {
  background: white;
  // position: relative;
  // height: 1500px;
  word-wrap: break-word;
  border: 0px solid #f00606;
  .handle-resize {
    cursor: col-resize;
    // position: fixed;
    // height: 1200px;
    z-index: 8;
    // background: green;
    width: 200px;
    left:2px;
    padding-bottom: 10px;
    padding-top: 5px;
    border-left: 0px solid #c5c5c5;
    border-right: 0px solid #c5c5c5;
    top: 0px;
  }
}
.headout{
  height: 60px;
  width: 100%;
  .headin{
    position: fixed;
    width: 100%;
    z-index: 9;
  }
}
.top{
  border: 0px red solid;
  height: 58px;
  width: 100%;
  margin-left: -14px;
  margin-top: 1px;
  .input{
  width: 100%;
  z-index: 9;
  padding-left: 20px;
  background: white;
  position: fixed;
  height: 58px;
  border: 0px red solid;
  display: flex;
  align-items: center;
  box-shadow: 0px 1px 3px #DCDCDC;
}
}
.main{
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  // border: 1px rgb(111, 0, 255) solid;
  .tabs{
    width: 100%;
    background: white;
    // border: 1px rgb(0, 255, 4) solid;
    .tab{
      width: 100%;
      // background: yellow;
    }
  }
}
.studyland{
  background: #F5F5F5;
  // background: yellow;
}
.custom-tree-node {
  display: flex;
  img {
    width: 14px;
    height: 14px;
  }
  .label {
    margin-left: 5px;
  }
}
:deep(.el-tree) {
  .el-tree-node {
    position: relative;
    padding-left: 5px;
  }
  .el-tree-node__children {
    padding-left: 10px;
  }
  // 自定义图标中隐藏自带箭头
  .el-tree-node__content>.el-tree-node__expand-icon {
    position: absolute;
    opacity: 0;
  }
  // 禁止旋转
  .el-tree .el-tree-node__expand-icon.expanded {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  // 节点高度与边距
  .el-tree-node__content {
    height: 25px;
  }
  .el-tree-node {
    margin: 5px 5px;
  }
  //高亮字体颜色
  .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    color: #ee7355 !important;
    background-color: transparent; // 设置选中背景颜色
  }
  // 设置竖线
  .el-tree-node .is-focusable:before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -10px;
    border-left: 1px solid #e5e7ec;
    height: calc(100% + 3px);
  }
}
.list-left {
  width: 214px;
  height: auto;
  margin: 10px 0 20px 20px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}
// 鼠标移入高亮
.custom-tree-node:hover {
  color: #ee7355 !important;
}
</style>
