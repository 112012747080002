import request from '../../utils/axios'

const process = {
  getProcessList: function (parames) { // 查询接口
    return request.get('edu/process/findList', { params: parames })
  },
  getTenProcessList: function (parames) { // 查询接口10个
    return request.get('edu/process/findTenProcess', { params: parames })
  },
  editProcess: function (parames) { // 编辑接口
    return request.get('edu/process/update', { params: parames })
  },
  addProcess: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/process/add',
      method: 'get',
      params: parames
    })
  },
  deleteProcess: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/process/delete', { params })
  }
}

export default process
