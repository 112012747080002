<!-- 个人中心 -->
<template>
 <div class="center">
  <Head></Head>
  <div>
      <el-container>
        <el-aside class="aside" width="200px">
          <div style="width: 200px;">
            <PersonLeft></PersonLeft>
          </div>
        </el-aside>
        <el-main  style="background: #F8F8FF;padding:7px;height: 100%;">
          <div class="main">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
     </div>
 </div>
</template>

<script>
import Head from '../../components/Head'
import PersonLeft from '../../components/left/PersonLeft.vue'
export default {
  name: 'center',
  components: {
    Head,
    PersonLeft
  },
  data () {
    return {

    }
  },
  mounted () {
    console.log('-++++++--' + localStorage.getItem('ifLogin'))
    // this.ifLogin = localStorage.getItem('ifLogin')
    // this.ifCompany = localStorage.getItem('ifCompany')
  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>

</style>
