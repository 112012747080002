<!--  -->
<template>
 <div class="crm">
  <Head></Head>
    这是CRM系统，不是你想进进能进来的
 </div>
</template>

<script>
import Head from '../../components/Head'
export default {
  name: '',
  components: {
    Head
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>

</style>
