<template>
    <div class="video">
        <Head></Head>
        <el-row style="padding-top: 20px;padding-bottom: -10px;">
            <el-col :span="3" :offset="1">
                <div style="font-size: 20px;font-weight: bold;">视频教程</div>
            </el-col>
            <el-col :span="6" :offset="12">
                <el-select v-model="value" filterable @change="SelectOne"
                   remote :remote-method="moreVideo" placeholder="请输入视频名" style="width: 80%;">
                 <el-option
                   v-for="item in videoNameList"
                   :key="item.value"
                   :label="item.name"
                   :value="item.id">
                 </el-option>
             </el-select>
            </el-col>
        </el-row>
        <div class="divider">
            <el-divider class="divider"></el-divider>
        </div>
        <el-container>
            <el-aside width="250px">
                <el-menu
                    unique-opened="true"
                    class="el-menu-vertical-demo"
                    @select="handleSelect">
                    <el-submenu index="1">
                        <template slot="title">
                        <i class="el-icon-location"></i>
                        <span>工商业务</span>
                        </template>
                        <el-menu-item index="100">大陆地区</el-menu-item>
                        <el-menu-item index="101">华北地区</el-menu-item>
                        <el-menu-item index="102">东北地区</el-menu-item>
                        <el-menu-item index="103">华东地区</el-menu-item>
                        <el-menu-item index="104">华中地区</el-menu-item>
                        <el-menu-item index="105">华南地区</el-menu-item>
                        <el-menu-item index="106">西南地区</el-menu-item>
                        <el-menu-item index="107">西北地区</el-menu-item>
                    </el-submenu>
                    <el-menu-item index="2">
                        <i class="el-icon-menu"></i>
                        <span slot="title">财税业务</span>
                    </el-menu-item>
                    <el-submenu index="3">
                        <template slot="title">
                        <i class="el-icon-location"></i>
                        <span>知识产权</span>
                        </template>
                        <el-menu-item index="301">商标业务</el-menu-item>
                        <el-menu-item index="302">专利业务</el-menu-item>
                        <el-menu-item index="303">软著业务</el-menu-item>
                    </el-submenu>
                    <el-submenu index="4">
                        <template slot="title">
                        <i class="el-icon-location"></i>
                        <span>科创业务</span>
                        </template>
                        <el-menu-item index="401">科小</el-menu-item>
                        <el-menu-item index="402">国高新</el-menu-item>
                        <el-menu-item index="403">专精特新</el-menu-item>
                    </el-submenu>
                    <el-menu-item index="5">
                        <i class="el-icon-menu"></i>
                        <span slot="title">认证业务</span>
                    </el-menu-item>
                    <el-submenu index="6">
                        <template slot="title">
                        <i class="el-icon-location"></i>
                        <span>人事业务</span>
                        </template>
                        <el-menu-item index="601">社保</el-menu-item>
                        <el-menu-item index="602">公积金</el-menu-item>
                    </el-submenu>
                </el-menu>
            </el-aside>
            <el-mian style="width: 100%;padding-bottom: 80px;">
                <div v-show="number === 3" style="width: 100%;" v-for="item in videoList" :key="item.id">
                    <el-row>
                        <el-col :span="3"><h2>{{ item.areaName }}</h2></el-col>
                    </el-row>
                    <div class="mian">
                        <div class="item" v-for="video in item.videos" :key="video.id">
                            <span><span style="font-weight: bold;">视频名 : </span>{{ video.name }}</span>
                            <div><span style="font-size: 12px;color: #C0C0C0;">更新时间：{{ video.updateTime }}</span></div>
                            <video :id="video.id" class="video" width="260" height="180" controls preload="none"
                            :poster= 'video.link + "?x-oss-process=video/snapshot,t_1"'
                            @mouseleave="onMouseOut"
                            >
                                <source
                                    :src=video.link
                                    type="video/mp4">
                            </video>&nbsp;<span class="tag-read" style="color: #1E90FF;font-size: 15px;"
                            :data-clipboard-text='[ video.name + line + video.link]'  @click="copy">复制</span>
                            <div><span style="font-weight: bold;">视频简介 : </span>{{ video.mark }}</div>
                        </div>
                    </div>
                </div>
                <div v-show="number === 9" style="width: 100%;">
                    <el-row>
                        <el-col :span="5"><h2>热门推荐的视频</h2></el-col>
                    </el-row>
                    <div class="mian">
                        <div class="item" v-for="item in videoList2" :key="item.id">
                            <span><span style="font-weight: bold;">视频名 : </span>{{ item.name }}</span>
                            <div><span style="font-size: 12px;color: #C0C0C0;">更新时间：{{ item.updateTime }}</span></div>
                            <video :id="item.id" class="video" width="260" height="180" controls preload="none"
                            :poster= 'item.link + "?x-oss-process=video/snapshot,t_1"'
                            @mouseleave="onMouseOut"
                            >
                                <source
                                    :src=item.link
                                    type="video/mp4">
                            </video>&nbsp;<span class="tag-read" style="color: #1E90FF;font-size: 15px;"
                            :data-clipboard-text='[ item.name + line + item.link]'  @click="copy">复制</span>
                            <div><span style="font-weight: bold;">视频简介 : </span>{{ item.mark }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="number === 1" style="width: 100%;">
                    <el-row>
                        <el-col :span="5"><h2>您选中的视频</h2></el-col>
                    </el-row>
                    <div class="mian">
                        <div class="item">
                            <span><span style="font-weight: bold;">视频名 : </span>{{ videoList3.name }}</span>
                            <div><span style="font-size: 12px;color: #C0C0C0;">更新时间：{{ videoList3.updateTime }}</span></div>
                            <video  class="video" width="260" height="180" controls preload="none"
                            :poster= 'videoList3.link + "?x-oss-process=video/snapshot,t_1"'
                            @mouseleave="onMouseOut"
                            >
                                <source
                                    :src="videoList3.link"
                                    type="video/mp4">
                            </video>&nbsp;<span class="tag-read" style="color: #1E90FF;font-size: 15px;"
                            :data-clipboard-text='[ videoList3.name + line + videoList3.link]'  @click="copy">复制</span>
                            <div><span style="font-weight: bold;">视频简介 : </span>{{ videoList3.mark }}</div>
                        </div>
                    </div>
                </div>
            </el-mian>
        </el-container>
    </div>
  </template>

<script>
import Head from '../../components/Head'
import video from '../../api/study/video'
import Clipboard from 'clipboard'
export default {
  name: 'home',
  components: {
    Head
  },
  data () {
    return {
      line: '\n', // 换行
      number: null,
      poster: '?vframe/jpg/offset/0.01/w/260/h/180',
      videoList: [], // 视频列表
      videoList2: [], // 视频列表
      value: '', // 视频id视频名
      videoNameList: [] // 在下拉中出现视频列表
    }
  },
  mounted () {
    // 拿排名前9的数据 将topNineList 当作 videoList，刷新后，还是这些数据，name排名前9的数据
    this.getNineVideo()
  },
  methods: {
    onMouseOut (e) {
      // console.log(e.fromElement.id)
      // 获得被移出的视频
      var myVideo = document.getElementById(e.fromElement.id)
      // 视频暂停
      myVideo.pause()
    },
    handleSelect (keyPath) { // 选中pid，获得一个新的videoList
      this.number = 3
      const data = {
        pid: keyPath
      }
      video.getVideoListByPid(data).then(res => {
        console.log(res)
        this.videoList = res.data
      })
    },
    SelectOne () { // 选中一个视频；选中后，赋值给videoList，name为查询到的视频
      this.number = 2
      const data = {
        id: this.value
      }
      video.getVideoListById(data).then(res => {
        this.videoList3 = res.data
        this.number = 1
      })
    },
    getNineVideo () { // 热门推荐的视频
      this.number = 9
      video.getTopNineList().then(res => {
        console.log(res)
        this.videoList2 = res.data
      })
    },
    moreVideo (query) { // 通过视频名称的字段获得备选视频
      console.log(query)
      const data = {
        name: query
      }
      video.getVideoName(data).then(res => {
        console.log(res)
        this.videoNameList = res.data
      })
    },
    myScript () {
      console.log('视频开始播放')
    },
    copy () { // 复制文案的方法
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        this.$message({
          type: 'success',
          message: '复制成功!'
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$message({
          type: 'info',
          message: '该浏览器不支持自动复制!'
        })
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>

  <style scoped>
    .divider{
        width: 90%;
        margin-left: 5%;
    }
    .el-menu-vertical-demo{
        font-weight: bold;
    }
    .mian{
        width: 98%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        padding: 0px 10px 10px 10px;
    }
    .item{
        background-color: rgba(255, 255, 255, 0.8);
        height: 283px;
        border: 1px solid #cdcdcd;
        border-radius: 2px;
        text-align: left;
        padding: 10px;
    }
    .video{
        border: 0px solid rgb(230, 12, 12);
        margin-top: 10px;
    }
  </style>
