<template>
  <div class="productAll">
    <!-- 顶部的筛选拦 -->
    <div style="background: #FFFFFF;font-size: 14px;">
      <el-row class="input">
        <el-col :span="2">全局搜索</el-col>
        <el-col :span="5">
          <el-input
            size="small"
            style="width: 250px;"
            placeholder="请输入内容"
            v-model="input">
          </el-input>
        </el-col>
        <el-col :span="2">所属类目</el-col>
        <el-col :span="6">
          <el-cascader
            placeholder="请选择产品类目"
            size="small"
            :props="{
              value:'id',
              label:'name'
            }"
            v-model="category_select"
            :options="this.$store.state.categoryList"
            style="width: 290px;"
            clearable
            :show-all-levels="false"
            filterable></el-cascader>
        </el-col>
        <el-col :span="5">
          <el-button size="small" type="primary"  icon="el-icon-search" @click="selectProduct">查询</el-button>
          <el-button size="small" type="info"  @click="selectMessageNull">重置</el-button>
        </el-col>
        <el-col :span="3">
        </el-col>
      </el-row>
    </div>
    <!-- 产品主体 -->
    <div style="background: #FFFFFF;">
      <el-row class="add">
        <el-col :span="2">
          <el-button @click="newProduct" type="primary" size="small" v-show="[1, 2, 3].includes(3)">新增产品</el-button>
        </el-col>
      </el-row>
      <div class="product">
        <el-table
          :data="tableData"
          :fit=false
          show-overflow-tooltip
          :height=windowHeight-249
          :cell-style="{padding: '4px'}"
          style="width: 100%;text-align: left;font-size: 15px;">
          <el-table-column
            type="selection"
            fixed="left"
            width="55">
          </el-table-column>
          <el-table-column
            prop="sort"
            label="产品编号"
            width="100">
          </el-table-column>
          <el-table-column
            prop="name"
            label="产品名称"
            width="220">
          </el-table-column>
          <el-table-column
            prop="categoryList"
            label="产品类目"
            width="220">
          </el-table-column>
          <el-table-column
            prop="status"
            label="产品状态"
            width="100"
            :filters="[{ text: '上架', value: 1 }, { text: '审核中', value: 2 }, { text: '草稿', value: 3 }, { text: '下架', value: 4 }]"
            :filter-method="filterTag"
            filter-placement="bottom-end">
            <template slot-scope="scope">
              <el-tag
                type="success'"
                disable-transitions>
                <span v-if="scope.row.status === 1">上架</span>
                <span v-if="scope.row.status === 2">审核中</span>
                <span v-if="scope.row.status === 3">草稿</span>
                <span v-if="scope.row.status === 4">下架</span>
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="grossMargin"
            label="毛利率%"
            width="100">
          </el-table-column>
          <el-table-column
            prop="deliveryRate"
            label="交付率%"
            width="100">
          </el-table-column>
          <el-table-column
            prop="closeRate"
            label="成单率%"
            width="100">
          </el-table-column>
          <el-table-column
            prop="unitPrice"
            label="客单价¥"
            width="100">
          </el-table-column>
          <el-table-column
            prop="radarCommunicate"
            label="沟通成本"
            width="50">
          </el-table-column>
          <el-table-column
            prop="radarDifficulty"
            label="办理难度"
            width="50">
          </el-table-column>
          <el-table-column
            prop="radarSpeed"
            label="办理速度"
            width="50">
          </el-table-column>
          <el-table-column
            prop="radarAftercare"
            label="售后难度"
            width="50">
          </el-table-column>
          <el-table-column
            prop="radarMaterial"
            label="材料难度"
            width="50">
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            width="200">
          </el-table-column>
          <el-table-column
            prop="process"
            label="申报时间"
            width="200">
          </el-table-column>
          <el-table-column
            prop="inspect"
            label="年检时间"
            width="200">
          </el-table-column>
          <el-table-column
            prop="startTime"
            label="出现时间"
            width="200">
          </el-table-column>
          <el-table-column
            prop="endTime"
            label="结束时间"
            width="200">
          </el-table-column>
          <el-table-column
            prop="description"
            label="产品简介"
            width="400">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="140">
            <template slot-scope="scope">
              <el-button @click="configProduct(scope.row)" type="text" size="small">配置</el-button>
              <el-button @click="editProduct(scope.row)" type="text" size="small">编辑</el-button>
              <el-button @click="downProduct(scope.row)" type="text" size="small" v-if="scope.row.status === 1">下架</el-button>
              <el-button @click="downProduct(scope.row)" type="text" size="small" v-if="scope.row.status === 2 || scope.row.status === 4">上架</el-button>
              <el-button @click="downProduct(scope.row)" type="text" size="small" v-if="scope.row.status === 3">通过</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 产品导入自己公司库 -->
        <div class="block">
          <el-row>
            <el-col :span="2">
              <div style="font-size: 14px;margin-top: 6px;">已经选中19条</div>
            </el-col>
            <el-col :span="2">
              <el-button type="primary" size="small">批量入库</el-button>
            </el-col>
             <!-- 分页功能 -->
            <el-col :span="12" :offset="6">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="10"
                style="margin: 5px;padding: 0px;"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.total">
              </el-pagination>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 配置标签 -->
    <el-dialog
      title="产品配置"
      :visible.sync="configVisible"
      width="75%"
      :before-close="handleClose">
      <span>
        <ProductConfig v-if="show"></ProductConfig>
      </span>
    </el-dialog>
    <!-- 新建产品和编辑产品 -->
    <el-dialog
      :title="title"
      :visible.sync="newDialogVisible"
      width="88%"
      :before-close="handleClose">
      <span>
        <el-form ref="product" :model="product" label-width="120px" class="form" >
          <el-row>
            <el-col :span="10">
              <el-form-item label="产品名称" prop="name">
                <el-input v-model="product.name" placeholder="请输入产品名称"></el-input>
              </el-form-item>
            </el-col>
            <!-- 产品所属类目 -->
            <el-col :span="10" :offset="1">
              <el-form-item label="所属类目" prop="select">
                <el-cascader
                  placeholder="请选择产品类目"
                  :props="{
                    value:'id',
                    label:'name'
                  }"
                  v-model="category_id_list"
                  @change="changeCategory"
                  :options="this.$store.state.categoryList"
                  style="width: 100%;"
                  clearable
                  :show-all-levels="true"
                  filterable></el-cascader>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <el-form-item label="产品类型" prop="select">
                <el-radio-group v-model="product_type">
                  <el-radio label="1">行业通用</el-radio>
                  <el-radio label="2">仅限本企业</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="地域级别" prop="select">
                <el-select v-model="AreaValue" placeholder="请选择地域级别">
                  <el-option
                    v-for="item in AreaLevelList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="主管部门" prop="select">
                <el-select v-model="product.government" multiple filterable placeholder="请选择主管部门" style="width: 90%;">
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name">
                </el-option>
              </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="5">
              <el-form-item label="毛利率 %" prop="number">
                <el-input v-model="product.gross_margin" placeholder="请输入毛利率"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="交付率 %" prop="number">
                <el-input v-model="product.delivery_rate" placeholder="请输入交付率"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="成单率 %" prop="number">
                <el-input v-model="product.close_rate" placeholder="请输入成单率"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="客单价 ¥" prop="number">
                <el-input v-model="product.unit_price" placeholder="请输入客单价"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="年订单 (个）" prop="number">
                <el-input v-model="product.order_number" placeholder="请输入市场年平均订单量"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 雷达图 -->
          <el-row>
            <el-col :span="7">
              <el-form-item label="沟通成本" prop="select">
                <el-rate
                  v-model="product.radar_communicate"
                  :texts="['无需沟通', '简单沟通', '正常沟通', '沟通成本高', '大量沟通']"
                  style="margin-top: 10px;"
                  show-text>
                </el-rate>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="办理难度" prop="select">
                <el-rate
                  v-model="product.radar_difficulty"
                  :texts="['毫无难度', '轻微难度', '正常难度', '相对困难', '极其困难']"
                  style="margin-top: 10px;"
                  show-text>
                </el-rate>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="交付速度" prop="select">
                <el-rate
                  v-model="product.radar_speed"
                  :texts="['实时交付', '快速交付', '正常速度', '周期较长', '周期很长']"
                  style="margin-top: 10px;"
                  show-text>
                </el-rate>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="售后难度" prop="select">
                <el-rate
                  v-model="product.radar_aftercare"
                  :texts="['无需售后', '稍微售后', '政策售后', '稍后稍多', '售后很多']"
                  style="margin-top: 10px;"
                  show-text>
                </el-rate>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="材料难度" prop="select">
                <el-rate
                  v-model="product.radar_material"
                  :texts="['无需材料', '简单材料', '一般多的材料', '材料准备的比较多', '材料准备的非常多']"
                  style="margin-top: 10px;"
                  show-text>
                </el-rate>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="产品简介" prop="describe">
            <el-input type="textarea" v-model="product.describe" style="width: 90%;" maxlength="30" show-word-limit></el-input>
          </el-form-item>
          <el-row>
            <el-col :span="6">
              <el-form-item label="出现时间" prop="name">
                <el-input v-model="product.start_time" placeholder="请输入产品出现时间"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="终止时间" prop="name">
                <el-input v-model="product.end_time" placeholder="请输入产品终止时间"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="申报时间" prop="name">
                <el-input v-model="product.process" placeholder="请输入产品申报时间"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="年检时间" prop="name">
                <el-input v-model="product.inspect" placeholder="请输入产品年检时间"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </span>
      <!-- 新建产品的底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="setProductNull" v-if="this.title ==='新增产品'">重置</el-button>
        <el-button type="primary" @click="finishAdd" v-if="this.title ==='新增产品'">提交</el-button>
        <el-button type="success" @click="finishEditProduct" v-if="this.title ==='编辑产品'">完成编辑</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import getheight from '../../../utils/getheight'
import ProductConfig from '../../../components/product/ProductConfig'
import government from '../../../api/product/government'
import product from '../../../api/product/product'
import category from '../../../api/product/category'
export default {
  name: '',
  mixins: [getheight],
  components: {
    ProductConfig
  },
  data () {
    return {
      input: '',
      product_type: '1',
      category_id_list: [],
      category_select: [],
      govern: '',
      user: null,
      category_id: null, // 所属类目
      product: {
        id: '', // 产品id
        company_id: '', // 所属企业
        name: '', // 产品名
        status: '', // 状态
        userId: '', // 用户ID
        describe: '', // 产品描述
        gross_margin: '', // 毛利率
        delivery_rate: '', // 交付率
        close_rate: '', // 成单率
        unit_price: '', // 平均客单价
        order_number: '', // 年平均订单量
        create_time: '', // 创建时间
        start_time: '', // 开始时间
        end_time: '', // 结束时间
        process: '', // 申报时间
        inspect: '', // 年检时间
        radar_communicate: null, // 沟通成本
        radar_difficulty: null, // 办理难度
        radar_speed: null, // 交付速度
        radar_aftercare: null, // 售后难度
        radar_material: null, // 材料难度
        government: []
      },
      AreaValue: '', // 地域级别
      AreaLevelList: [
        { value: 1, label: '国家级' },
        { value: 2, label: '省级' },
        { value: 3, label: '市级' },
        { value: 4, label: '区县级' },
        { value: 5, label: '国际地区' }
      ],
      title: '新增产品',
      currentPage: 1,
      pageSize: 10,
      total: null,
      configVisible: false, // 产品配置的弹窗
      newDialogVisible: false, // 新建产品的标签
      productTags: '',
      windowHeight: 0,
      rules: {
        name: [
          { required: true, message: '请输入产品名', trigger: 'blur' },
          { min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'blur' }
        ],
        number: [
          { required: true, message: '请输入对应内容', trigger: 'blur' },
          { type: 'number', message: '仅支持数字类型' }
        ],
        describe: [
          { required: true, message: '请输入描述内容', trigger: 'blur' }
        ],
        select: [
          { required: true, trigger: 'blur' }
        ]
      },
      tableData: [],
      show: true,
      options: []
    }
  },
  created () {},
  methods: { // 配置产品
    configProduct (row) {
      this.configVisible = true
      const _this = this
      _this.$store.commit('getProductId', row.id)
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 10)
      // console.log(this.$store.state.productId)
    },
    handleClose () { // 关闭弹窗
      this.configVisible = false
      this.newDialogVisible = false
    },
    getList () { // 获得规定的列表
      const data = {
        current: this.currentPage,
        size: this.pageSize
      }
      product.getProduct(data).then(res => {
        this.total = res.data.total
        this.tableData = res.data.records
      })
    },
    newProduct () { // 新增产品
      this.title = '新增产品'
      this.newDialogVisible = true
      this.setProductNull()
    },
    finishAdd () { // 完成新增
      this.newDialogVisible = false
      // 将数组转成字符串
      this.govern = this.product.government.join(',')
      // 取最后一个数值
      this.category_id = this.category_id_list[this.category_id_list.length - 1]
      const data = {
        userId: this.user.id,
        name: this.product.name,
        type: this.product_type,
        areaLevel: this.AreaValue,
        government: this.govern,
        categoryId: this.category_id,
        description: this.product.describe,
        closeRate: this.product.close_rate,
        orderNumber: this.product.order_number,
        process: this.product.process,
        inspect: this.product.inspect,
        deliveryRate: this.product.delivery_rate,
        unitPrice: this.product.unit_price,
        grossMargin: this.product.gross_margin,
        radarAftercare: this.product.radar_aftercare,
        radarCommunicate: this.product.radar_communicate,
        radarDifficulty: this.product.radar_difficulty,
        radarMaterial: this.product.radar_material,
        radarSpeed: this.product.radar_speed,
        startTime: this.product.start_time,
        endTime: this.product.end_time
      }
      product.addProduct(data).then(res => {
        console.log('新增返回的数据' + res.code)
        if (res.code === 0) {
          this.newLawdialogVisible = false
          this.$message({
            message: '新增成功',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    editProduct (row) { // 编辑产品
      this.title = '编辑产品'
      this.newDialogVisible = true
      this.product.id = row.id
      this.product_type = row.type.toString() // 产品的类型
      this.category_id_list = row.categoryId // 所属类目
      this.category_id = row.categoryId // 所属类目
      this.product.name = row.name // 产品名
      this.product.status = row.status
      this.product.government = row.governments // 产品管理部门
      this.AreaValue = row.areaLevel // 产品级别
      this.product.describe = row.description // 产品描述
      this.product.create_time = row.createTime // 产品开始时间
      this.product.start_time = row.startTime // 产品开始时间
      this.product.end_time = row.endTime // 产品结束时间
      this.product.gross_margin = row.grossMargin // 毛利率
      this.product.delivery_rate = row.deliveryRate // 交付率
      this.product.close_rate = row.closeRate // 成单率
      this.product.unit_price = row.unitPrice // 平均客单价
      this.product.order_number = row.orderNumber // 年订单量
      this.product.process = row.process // 申报时间
      this.product.inspect = row.inspect // 年检时间
      this.product.radar_communicate = row.radarCommunicate // 沟通成本
      this.product.radar_difficulty = row.radarDifficulty // 办理难度
      this.product.radar_speed = row.radarSpeed // 交付速度
      this.product.radar_aftercare = row.radarAftercare // 售后难度
      this.product.radar_material = row.radarMaterial // 材料难度
    },
    changeCategory () {
      // 拿到正确的类目id
      this.category_id = this.category_id_list[this.category_id_list.length - 1]
    },
    finishEditProduct () {
      this.newDialogVisible = false
      // 将数组转成字符串
      this.govern = this.product.government.join(',')
      // 取最后一个数值
      // this.category_id = this.category_id_list[this.category_id_list.length - 1]
      const data = {
        id: this.product.id,
        userId: this.user.id,
        name: this.product.name,
        status: this.product.status,
        type: this.product_type,
        areaLevel: this.AreaValue,
        government: this.govern,
        categoryId: this.category_id,
        description: this.product.describe,
        closeRate: this.product.close_rate,
        orderNumber: this.product.order_number,
        process: this.product.process,
        inspect: this.product.inspect,
        deliveryRate: this.product.delivery_rate, // 别的都没事，这个更新不成功
        unitPrice: this.product.unit_price,
        grossMargin: this.product.gross_margin,
        radarAftercare: this.product.radar_aftercare,
        radarCommunicate: this.product.radar_communicate,
        radarDifficulty: this.product.radar_difficulty,
        radarMaterial: this.product.radar_material,
        radarSpeed: this.product.radar_speed,
        createTime: this.product.create_time,
        startTime: this.product.start_time,
        endTime: this.product.end_time
      }
      product.editProduct(data).then(res => {
        if (res.code === 0) {
          this.newLawdialogVisible = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    downProduct () { // 下架产品
      this.$confirm('您确认下架？')
        .then(_ => {
          this.$message({
            type: 'success',
            message: '下架成功'
          })
        })
        .catch(_ => {
          this.$message({
            type: 'info',
            message: '取消下架'
          })
        })
    },
    // 翻页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getList()
    },
    // 将产品输入框设置为空
    setProductNull () {
      this.product = {}
    },
    // 状态的筛选
    filterTag (value, row) {
      return row.status === value
    },
    // 获得政府部门
    getGovernment () {
      government.getGovernmentList().then(res => {
        this.options = res.data.records
      })
    },
    //
    selectMessageNull () {
      this.category_select = null
      this.category_id = null
      this.input = ''
      this.getList()
    },
    // 查询方法
    selectProduct () { // 查询部门
      // 取最后一个数值
      this.category_id = this.category_select[this.category_select.length - 1]
      const params = { name: this.input, categoryId: this.category_id, current: this.currentPage, size: this.pageSize }
      if (this.input === '' && this.category_id === null) {
        this.getList()
      } else {
        product.getProduct(params).then(res => {
          this.total = res.data.total
          this.tableData = res.data.records
        })
      }
    },
    getCateGory () { // 先加载类目
      category.getCategory().then(data => {
        const _this = this
        _this.$store.commit('getCategoryList', data.data)
      }
      )
    }
  },
  mounted () {
    this.getCateGory()
    this.user = JSON.parse(localStorage.getItem('user'))
    this.getList()
    this.getGovernment()
  },
  computed: {}
}

</script>
<style scoped lang="scss">
.add{
  height: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.input{
width: 98%;
margin-left: 1%;
height: 50px;
border: 0px red solid;
display: flex;
align-items: center;
}
.product{
  padding: 5px;
}
.block{
  margin-top: 10px;
  margin-bottom: 10px;
}
.tag{
  margin-bottom: 3px;
}
.form{
text-align: left;
font-weight: bold;
}
</style>
