<!-- 地域管理 -->
<template>
 <div class="area">
    这是地域管理
 </div>
</template>

<script>
export default {
  name: 'area',
  components: {},
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>

</style>
