import request from '../../utils/axios'

const processProduct = {
  getProcessProductList: function (parames) { // 查询接口
    return request.get('edu/ProcessProduct/findList', { params: parames })
  },
  editProcessProduct: function (parames) { // 编辑接口
    return request.get('edu/ProcessProduct/update', { params: parames })
  },
  addProcessProduct: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/ProcessProduct/add',
      method: 'get',
      params: parames
    })
  },
  deleteProcessProduct: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/ProcessProduct/delete', { params })
  },
  auditProcessProduct: function (params) { // 过审接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/ProcessProduct/audit', { params })
  }
}

export default processProduct
