import axios from 'axios'
import router from '../router'
import { Message } from 'element-ui'

const request = axios.create({
  baseURL: 'http://api.fengyutangfly.cn',
  // dataType: 'jsonp', // 数据类型为jsonp
  // jsonp: 'jsonpCallback',
  // responseType: 'json',
  timeout: 30 * 1000
})
request.interceptors.response.use(function (res) {
  // console.log(res, 55)
  if (res.data.code === 401) {
    Message.error('登录失效')
    // 在状态管理里面，让登录的内容失效
    // 回到最初页面，让用户重新登录
    router.push('/')
  }
  return res.data
})
export default request
