<!-- 个人中心基础信息 -->
<template>
 <div class="personMessage">
    <div class="table" :style="csstop2">
      <div style="float: right;">
        <el-button icon="el-icon-edit" circle @click="changeMessage"></el-button>
      </div>
      <el-descriptions title="用户信息" style="width: 98%;">
          <el-descriptions-item label="姓名">{{ user.name }}</el-descriptions-item>
          <el-descriptions-item label="性别">
            <el-tag size="small">{{ user.gender == 0 ? '男':'女'}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="手机号">{{ user.phone }}</el-descriptions-item>
          <el-descriptions-item label="工作地">{{ user.areaName }}</el-descriptions-item>
          <el-descriptions-item label="邮箱">{{ user.email }}</el-descriptions-item>
          <el-descriptions-item label="微信">{{ user.weChat }}</el-descriptions-item>
          <el-descriptions-item label="邮寄地址" :span="2">{{ user.address }}</el-descriptions-item>
      </el-descriptions>
      <el-divider></el-divider>
      <div style="text-align: left;height: 350px;">
        <h4>行业认证</h4>
        <div class="attestation">
          <template>
            <el-image
                style="width: 130px; height: 130px;border-radius: 5px;"
                :src="require('@/assets/attestation.png')"
                fit="fit"></el-image>
                <div>工商专家</div>
            </template>
            <template>
            <el-image
                style="width: 130px; height: 130px;border-radius: 5px;"
                :src="require('@/assets/attestation.png')"
                fit="fit"></el-image>
                <div>高新专家</div>
            </template>
            <template>
             <el-image
             style="width: 130px; height: 130px;border-radius: 5px;"
             :src="require('@/assets/unattestation.png')"
             fit="fit"></el-image>
             <div>专精特新专家</div>
         </template>
        </div>
      </div>
      <el-divider></el-divider>
      <div style="text-align: left;">
        <h4>隐私设置</h4>
        <el-button style="margin-top: -24px;" type="text" @click="passDialogVisible = true">修改密码</el-button>
      </div>
      <el-dialog
        title="修改密码"
        :visible.sync="passDialogVisible"
        width="45%"
        :before-close="handleClose">
        <span>
          <el-form label-position="left" label-width="120px" :model="password">
            <el-form-item label="当前密码">
              <el-input v-model="password.old" style="width: 80%;"></el-input>
            </el-form-item>
            <el-form-item label="新密码">
              <el-input v-model="password.new" style="width: 80%;" show-password></el-input>
            </el-form-item>
            <el-form-item label="新密码确认">
              <el-input v-model="password.new2" style="width: 80%;" show-password></el-input>
            </el-form-item>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="passDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="passDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="编辑个人信息"
        :visible.sync="dialogVisible"
        width="55%"
       >
        <span>
          <el-form ref="person" :model="person" label-width="80px">
            <el-col :span="11" >
              <el-form-item label="姓名">
                <el-input v-model="person.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item label="性别">
                <el-radio-group v-model="person.gender">
                  <el-radio :label="0">男</el-radio>
                  <el-radio :label="1">女</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="手机">
                <el-input v-model="person.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item label="工作地">
                <el-select v-model="person.area" filterable remote :remote-method="findArea"  placeholder="请选择经营地区" style="width: 70%;">
                  <el-option
                    v-for="item in allAreaList"
                    :key="item.value"
                    :label="item.fullName"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="邮箱">
                <el-input v-model="person.email"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11" :offset="1">
              <el-form-item label="微信">
                <el-input v-model="person.weChat"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="23">
              <el-form-item label="邮寄地址">
                <el-input v-model="person.address"></el-input>
              </el-form-item>
            </el-col>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="finishChangeMessage" style="margin-right: 30px;">完成编辑</el-button>
        </span>
      </el-dialog>
    </div>
 </div>
</template>

<script>
import getheight from '../../../utils/getheight'
import area from '../../../api/other/area'
import user from '../../../api/other/login'
export default {
  name: 'personMessage',
  components: {},
  mixins: [getheight],
  data () {
    return {
      windowHeight: 0,
      user: null,
      passDialogVisible: false,
      dialogVisible: false,
      allAreaList: [], // 所有的区域
      person: {
        id: '',
        name: '',
        phone: '',
        area: '',
        address: '',
        email: '',
        weChat: '',
        gender: ''
      },
      password: { // 修改密码的地方
        old: '',
        new: '',
        new2: ''
      }
    }
  },
  computed: {
    csstop2 () {
      return {
        '--top': this.windowHeight - 102 + 'px'
      }
    }
  },
  mounted () {
    this.user = JSON.parse(localStorage.getItem('user'))
    this.person = this.user
  },
  methods: {
    handleClose (done) {
      this.passDialogVisible = false
    },
    changeMessage () { // 打开编辑个人信息的弹窗
      this.dialogVisible = true
    },
    finishChangeMessage () { // 完成信息编辑
      this.dialogVisible = false
      const data = {
        id: this.person.id,
        name: this.person.name,
        area: this.person.area,
        address: this.person.address,
        email: this.person.email,
        weChat: this.person.weChat,
        gender: this.person.gender
      }
      console.log('这是')
      console.log(data)
      user.editUser(data).then(res => {
        // 登录信息更新缓存
        console.log(res)
        if (res.code === 0) {
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          const data2 = {
            id: this.person.id
          }
          user.getUserList(data2).then(res => {
            console.log(res.data.list[0])
            localStorage.setItem('user', JSON.stringify(res.data.list[0]))
          })
        }
      })
    },
    findArea (query) { // 获得地域的值
      console.log('低于')
      console.log(query)
      const data = {
        name: query
        // level: 4
      }
      area.getAreaListByName(data).then(res => {
        console.log(res)
        this.allAreaList = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-footer{
  margin-top: 30px;

}
.table{
  height: var(--top);
  background: #FFFFFF;
  padding: 15px;
  padding-bottom: 20px;
}
.attestation{
  display: flex;
}
</style>
