<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>
<script>

export default {
  name: 'app',
  privide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  mounted () {
  },
  methods: {
    reload () {
      this.this.isRouterAlive = false
      this.$nextTick(() => {
        this.this.isRouterAlive = true
      })
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0px;
  margin: -8px;
}
html{
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
}
</style>
