<!-- 法律产品配置的组建 -->
<template>
    <div class="law_config">
        <el-row style="text-align: left;">
           <el-col :span="16">
             <span style="margin-left: 9px;">法条检索：</span>{{ lawItemId }}
             <el-select v-model="lawItemId" filterable placeholder="请选择相关法律及法条" style="width: 80%;">
                 <el-option
                   v-for="item in lawList"
                   :key="item.id"
                   :label="item.lawName"
                   :value="item.id">
                   <span style="float: left;">{{ item.lawName }}({{ item.lawItem }})</span>
                   <span style="float: right; color: #8492a6; font-size: 13px">---{{ item.lawContent }}</span>
                 </el-option>
             </el-select>
           </el-col>
           <el-col :span="2" :offset="4">
             <el-button type="primary" size="small" @click="AddLawProduct">添加法条</el-button>
           </el-col>
         </el-row>
         <el-row style="margin-top: 10px;">
           <el-col :span="24">
             <el-table
               :data="tableData"
               style="width: 100%">
               <el-table-column
                 prop="lawName"
                 label="法律名称"
                 width="150">
               </el-table-column>
               <el-table-column
                 prop="lawItem"
                 label="条款序列"
                 width="100">
               </el-table-column>
               <el-table-column
                 prop="lawContent"
                 show-overflow-tooltip
                 label="法条内容">
               </el-table-column>
               <el-table-column
                 fixed="right"
                 label="操作"
                 width="70">
                 <template slot-scope="scope">
                   <el-button
                     @click.native.prevent="deleteLawProduct(scope.row)"
                     type="text"
                     size="small">
                     移除
                   </el-button>
                 </template>
               </el-table-column>
             </el-table>
           </el-col>
         </el-row>
    </div>
   </template>

<script>
import law from '../../../api/product/law'
import lawProduct from '../../../api/product/lawProduct'
export default {
  name: 'law_config',
  components: {},
  data () {
    return {
      lawItemId: '',
      lawList: [],
      tableData: [],
      currentPage: 1,
      pageSize: 20
    }
  },
  mounted () {
    this.getLawList()
    this.getLawProduct()
  },
  methods: {
    getLawList () { // 获得法律的列表
      const data = {
        current: this.currentPage,
        size: this.pageSize
      }
      law.getLawList(data).then(res => {
        const array = res.data.records
        for (let index = 0; index < array.length; index++) {
          const element = array[index].lawContent.substring(0, 30)
          array[index].lawContent = element
        }
        this.lawList = array
      })
    },
    getLawProduct () { // 获得法律和产品对应数据
      const data = {
        productId: this.$store.state.productId
      }
      lawProduct.getLawProductList(data).then(res => {
        this.tableData = res.data
      })
    },
    AddLawProduct () { // 增加一条数据
      if (this.lawItemId === '') {
        this.$message({
          message: '未选中任何法律',
          type: 'info'
        })
      } else {
        const data = {
          productId: this.$store.state.productId,
          myselfId: this.lawItemId,
          status: 1
        }
        lawProduct.addLawProduct(data).then(res => {
          if (res.code === 0) {
            this.newLawdialogVisible = false
            this.$message({
              message: '新增成功',
              type: 'success'
            })
            this.lawItemId = null
            this.getLawProduct()
          }
        })
      }
    },
    deleteLawProduct (row) { // 移除一条数据
      const params = { id: row.aboutId }
      lawProduct.deleteLawProduct(params).then(res => {
        if (res.code === 0) {
          this.$message({
            message: '移除成功',
            type: 'success'
          })
          this.getLawProduct()
        }
      })
    }
  }
}
</script>
   <style lang="scss" scoped>
   .product_config{
     margin-top: -25px;
   }
   </style>
