<template>
  <div class="personLeft">
    <el-menu
      router
      :default-active="$route.path"
      default-openeds="1"
      unique-opened
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose">
      <el-submenu index="1">
          <template slot="title">
              <i class="el-icon-location"></i>
              <span>考试中心</span>
          </template>
          <el-menu-item-group>
              <el-menu-item index="/category" v-show="[1, 2, 3].includes(2)">考试规则</el-menu-item>
              <el-menu-item index="/productall">考题管理</el-menu-item>
              <el-menu-item index="/productall">开始考试</el-menu-item>
              <el-menu-item index="/productall">考试记录</el-menu-item>
              <el-menu-item index="/areaList">考试排名</el-menu-item>
              <el-menu-item index="/attributeList">我的荣誉</el-menu-item>
              <el-menu-item index="/tagList">其他</el-menu-item>
          </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'productleft',
  components: {},
  data () {
    return {
      defalut: []
    }
  },
  created () {},
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    }
  },
  mounted () {},
  computed: {}
}

</script>
<style scoped>
.el-menu-vertical-demo{
  border-right: none;
}
</style>
