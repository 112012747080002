<!--  -->
<template>
 <div class="exam">
  <Head></Head>
  <div>
      <el-container>
        <el-aside class="aside" width="200px">
          <div style="width: 200px;">
            <ExamLeft></ExamLeft>
          </div>
        </el-aside>
        <el-main  style="background: #F8F8FF;padding:7px;height: 100%;">
          <div class="main">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
     </div>
 </div>
</template>

<script>
import Head from '../../components/Head'
import ExamLeft from '../../components/left/ExamLeft'
export default {
  name: '',
  components: {
    Head,
    ExamLeft
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>

</style>
