import request from '../../utils/axios'

const material = {
  getMaterialList: function (parames) { // 查询接口
    return request.get('edu/material/findList', { params: parames })
  },
  getTenMaterialList: function (parames) { // 查询接口10个
    return request.get('edu/material/findTenMaterial', { params: parames })
  },
  editMaterial: function (parames) { // 编辑接口
    return request.get('edu/material/update', { params: parames })
  },
  addMaterial: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/material/add',
      method: 'get',
      params: parames
    })
  },
  deleteMaterial: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/material/delete', { params })
  }
}

export default material
