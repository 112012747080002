<template>
  <div class="category">
    <div style="background: #FFFFFF;padding-bottom: 10px;">
      <div style="height: 10px;"></div>
      <div style="font-size: 20px;font-weight: bold;">企服行业产品类目表</div>
      <div style="color:#FA8072;font-size: 13px;margin-top: 25px;">注：大家对类目划分有新的想法，欢迎提交工单与我们探讨，感谢！</div>
    </div>
    <div class="table">
      <el-table
        :data="this.$store.state.categoryList"
        style="width: 100%;"
        :height=windowHeight-195
        row-key="id"
        border
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column
          prop="name"
          label="类目名称"
          width="300">
        </el-table-column>
        <el-table-column
          prop="description"
          label="类目简介"
          width="450">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleAdd(scope.$index, scope.row)">添加子类目</el-button>
            <el-button
              size="mini"
              @click="openHandleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="loadedProducts"
          label="挂载产品数"
          width="120">
        </el-table-column>
      </el-table>
    </div>
    <!-- 表单弹窗 -->
    <el-dialog
      title="产品类目"
      :visible.sync="dialogVisible"
      width="45%"
      :before-close="handleClose">
      <span>
        <el-form ref="category" :model="category" label-width="100px"  :rules="rules">
          <!-- <el-form-item label="上级类目:">
            <el-input v-model="category.pid" style="width: 80%;float: left;"></el-input>
          </el-form-item> -->
          <el-form-item label="类目名称:" prop="name">
            <el-input v-model="category.name" style="width: 80%;float: left;"></el-input>
          </el-form-item>
          <el-form-item label="类目描述:" prop="description">
            <el-input v-model="category.description" style="width: 80%;float: left;"></el-input>
          </el-form-item>
          <el-form-item label="类目序号:" prop="sort">
            <el-input v-model="category.sort" style="width: 80%;float: left;"></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="medium">取 消</el-button>
        <el-button type="primary" @click="finishEdit" size="medium" v-if="buttonType">更 新</el-button>
        <el-button type="success" @click="finishAdd" size="medium" v-if="!buttonType">新 增</el-button>
      </span>
    </el-dialog>
   </div>
  </template>
<script>
import getheight from '../../../utils/getheight'
import category from '../../../api/product/category'

export default {
  mixins: [getheight],
  data () {
    return {
      windowHeight: 0,
      category: {
        id: '',
        name: '',
        userId: '',
        sort: '',
        description: '',
        updateTime: '',
        pid: ''
      },
      buttonType: false, // 新增和编辑的切换
      dialogVisible: false, // 弹窗是否打开
      tableData: [], // 类目的数据
      rules: {
        name: [
          { required: true, message: '请输入类目名称', trigger: 'blur' },
          { min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入描述类目', trigger: 'blur' },
          { min: 7, max: 20, message: '长度在 7 到 20 个字符', trigger: 'blur' }
        ],
        sort: [
          { required: true, message: '请输入类别序号', trigger: 'blur' }
          // { type: 'number', message: '请输入正确的序号类型' }
        ]
      }
    }
  },
  mounted: function () {

  },
  methods: {
    // 关闭弹窗
    handleClose (done) {
      done()
    },
    // 打开新增类目------------新增
    handleAdd (index, row) {
      this.setcategoryNull()
      this.dialogVisible = true
      this.buttonType = false
      console.log(row.id)
      this.category.pid = row.id
    },
    // 完成新增----------------新增
    finishAdd () {
      const data = {
        name: this.category.name,
        description: this.category.description,
        sort: this.category.sort,
        pid: this.category.pid
      }
      category.addCategory(data).then(res => {
        console.log(res)
        this.dialogVisible = false
        this.$message({
          message: '新增成功',
          type: 'success'
        })
        this.getCateGory()
      })
    },
    // 打开编辑类目窗口
    openHandleEdit (index, row) {
      this.dialogVisible = true
      this.buttonType = true
      this.category.id = row.id
      this.category.name = row.name
      this.category.description = row.description
      this.category.sort = row.sort
      this.category.pid = row.pid
    },
    // 完成编辑进行提交
    finishEdit () {
      const data = {
        id: this.category.id,
        name: this.category.name,
        description: this.category.description,
        sort: this.category.sort,
        pid: this.category.pid
      }
      category.editCategory(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          console.log('成功了！')
          this.dialogVisible = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getCateGory()
        }
      })
    },
    // 删除类目
    handleDelete (index, row) {
      this.$confirm('确认删除【' + row.name + '】类目吗？')
        .then(_ => {
          if (row.children != null || row.number > 0) {
            this.$notify({
              title: '删除失败',
              message: '类目下有产品或子类目，禁止删除！',
              type: 'error',
              duration: 2500
            })
          } else {
            console.log('-----')
            const params = { id: row.id }
            console.log(params)
            category.deleteCategory(params).then(response => {
              console.log('User deleted successfully:', response.data)
              if (response.data) {
                this.$message({
                  type: 'success',
                  message: '删除成功'
                })
                this.getCateGory()
              }
            })
          }
        })
        .catch(_ => {})
    },
    // 重新请求一次
    getCateGory () {
      category.getCategory().then(data => {
      // console.log('返回的结果')
        const _this = this
        _this.$store.commit('getCategoryList', data.data)
      }
      )
    },
    // 新增前输入框置空
    setcategoryNull () {
      this.category.id = ''
      this.category.name = ''
      this.category.description = ''
      this.category.sort = ''
      this.category.pid = ''
    }
  }
}
</script>
<style scoped lang="less">
.category{
  padding: 0px;
  margin: 0px;
}
/deep/.el-table .cell{
    display: flex;
}
.table{
  background: #FFFFFF;
  padding: 5px;
  padding-bottom: 20px;
}
</style>
