<!-- 产品配置的组建 -->
<template>
 <div class="product_config">
  <el-tabs type="border-card">
     <!-- 属性配置 -->
     <el-tab-pane :lazy="true">
      <span slot="label"><i class="el-icon-s-grid"></i> 属性配置</span>
      <AttributeConfig></AttributeConfig>
    </el-tab-pane>
    <!-- 标签配置 -->
    <el-tab-pane style="padding: 10px;" :lazy="true">
      <span slot="label"><i class="el-icon-document-copy"></i> 标签配置</span>
      <TagConfig></TagConfig>
    </el-tab-pane>
    <!-- 流程 -->
    <el-tab-pane :lazy="true">
      <span slot="label"><i class="el-icon-set-up"></i> 流程配置</span>
      <ProcessConfig></ProcessConfig>
    </el-tab-pane>
    <!-- 法律配置 -->
    <el-tab-pane :lazy="true">
      <span slot="label"><i class="el-icon-collection"></i> 法律配置</span>
      <LawConfig></LawConfig>
    </el-tab-pane>
     <!-- 材料 -->
    <el-tab-pane :lazy="true">
      <span slot="label"><i class="el-icon-notebook-2"></i> 材料配置</span>
      <MaterialConfig></MaterialConfig>
    </el-tab-pane>
  </el-tabs>
 </div>
</template>

<script>
import LawConfig from './config/LawConfig'
import MaterialConfig from './config/MaterialConfig'
import ProcessConfig from './config/ProcessConfig'
import AttributeConfig from './config/AttributeConfig'
import TagConfig from './config/TagConfig.vue'
export default {
  name: '',
  components: {
    LawConfig,
    MaterialConfig,
    ProcessConfig,
    AttributeConfig,
    TagConfig
  },
  data () {
    return {

    }
  },
  mounted () {
    console.log('总配置')
  },
  methods: {
    change (value) {
      console.log(value)
    }
  }
}
</script>
<style lang="scss" scoped>
.product_config{
  margin-top: -25px;
}
</style>
