import request from '../../utils/axios'

const government = {
  getGovernmentList: function (parames) { // 按照页码和条数查询接口
    return request.get('edu/government/findList', { params: parames })
  },
  editGovernment: function (parames) { // 编辑接口
    return request.get('edu/government/update', { params: parames })
  },
  addGovernment: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/government/add',
      method: 'get',
      params: parames
    })
  },
  deleteGovernment: function (params) { // 编辑接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/government/delete', { params })
  }
}

export default government
