<!-- 流程产品配置的组建 -->
<template>
    <div class="law_config">
        <el-row style="text-align: left;">
          <el-col :span="2" style="margin-top: 10px;">
            <span style="margin-left: 7px;">流程检索：</span>
          </el-col>
           <el-col :span="10">
             <el-select v-model="value" filterable remote :remote-method="moreProcess" placeholder="请选择相关流程" style="width: 80%;">
                 <el-option
                   v-for="item in processList"
                   :key="item.value"
                   :label="item.name"
                   :value="item.id">
                 </el-option>
             </el-select>
           </el-col>
           <el-col :span="2">
              <span style="float: right;margin-top: 10px;">流程序号：</span>
            </el-col>
           <el-col :span="3">
              <el-input-number style="margin-left: 5px;margin-top: 2px;" v-model="num" size="medium"  :min="1" :max="50"></el-input-number>
            </el-col>
           <el-col :span="1" :offset="3" style="margin-top: 3px;">
             <el-button type="primary" size="small" @click="AddLawProduct">添加流程</el-button>
           </el-col>
         </el-row>
         <el-row style="margin-top: 10px;">
          <el-col :span="2" style="margin-top: 10px;">
            <span style="margin-left: 3px;">所需时间：</span>
          </el-col>
          <el-col :span="8">
            <el-input v-model="timeNumber" placeholder="请输入流程所需时长（支持区间）"></el-input>
          </el-col>
          <el-col :span="10"  style="margin-top: 10px;margin-left: 10px;">
            <el-radio v-model="radio" label="1">工作日</el-radio>
            <el-radio v-model="radio" label="2">自然日</el-radio>
            <el-radio v-model="radio" label="3">月</el-radio>
          </el-col>
         </el-row>
         <el-row style="margin-top: 10px;">
           <el-col :span="24">
             <el-table
               :data="tableData"
               style="width: 100%">
               <el-table-column
                 prop="process.title"
                 label="进程名称"
                 width="210">
               </el-table-column>
               <el-table-column
                 prop="timeNumber"
                 label="所需时间"
                 width="120">
               </el-table-column>
               <el-table-column
                 prop="timeType"
                 label="时间类型"
                 width="100">
                 <template slot-scope="scope">
                  <el-tag
                    :type="scope.row.timeType === 1 ? 'primary' : scope.row.timeType === 2 ? 'success':'danger'"
                    disable-transitions>{{scope.row.timeType === 1 ? '工作日' : scope.row.timeType ===  2 ? '自然日' : '月'}}</el-tag>
                </template>
               </el-table-column>
               <el-table-column
                 prop="sort"
                 label="流程序号"
                 width="80">
               </el-table-column>
               <el-table-column
                 prop="process.content"
                 show-overflow-tooltip
                 label="进程内容">
               </el-table-column>
               <el-table-column
                 fixed="right"
                 label="操作"
                 width="100">
                 <template slot-scope="scope">
                   <el-button
                     @click.native.prevent="editProcessProduct(scope.row)"
                     type="text"
                     size="small">
                     编辑
                   </el-button>
                   <el-button
                     @click.native.prevent="deleteProcessProduct(scope.row)"
                     type="text"
                     size="small">
                     移除
                   </el-button>
                 </template>
               </el-table-column>
             </el-table>
           </el-col>
         </el-row>
         <el-dialog
            title="编辑关联信息"
            :visible.sync="dialogVisible"
            append-to-body
            width="30%">
            <span>
              <el-form label-position="right" label-width="80px" :model="formLabelAlign">
                <el-form-item label="所需时间">
                  <el-input v-model="formLabelAlign.timeNumber" style="width: 70%;"></el-input>
                </el-form-item>
                <el-form-item label="时间类型">
                  <template>
                    <el-radio-group v-model="formLabelAlign.timeType">
                      <el-radio :label="1">工作日</el-radio>
                      <el-radio :label="2">自然日</el-radio>
                      <el-radio :label="3">月</el-radio>
                    </el-radio-group>
                  </template>
                </el-form-item>
                <el-form-item label="排列序号">
                  <el-input v-model="formLabelAlign.sort"></el-input>
                </el-form-item>
              </el-form>
            </span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="finishEdit">确 定</el-button>
            </span>
          </el-dialog>
    </div>
   </template>

<script>
import process from '../../../api/product/process'
import processProduct from '../../../api/product/processProduct'
export default {
  name: 'process_config',
  components: {},
  data () {
    return {
      num: 1,
      timeNumber: '',
      radio: '1',
      processList: [],
      tableData: [],
      dialogVisible: false,
      formLabelAlign: {
        id: null,
        myselfId: null,
        timeNumber: '',
        sort: '',
        timeType: ''
      },
      currentPage: 1,
      pageSize: 20,
      value: ''
    }
  },
  mounted () {
    this.getProcessList()
    this.getProcessProduct()
  },
  methods: {
    editProcessProduct (row) { // 编辑关联数据
      this.dialogVisible = true
      this.formLabelAlign.id = row.id
      // this.formLabelAlign.myselfId = row.process.id
      this.formLabelAlign.timeNumber = row.timeNumber
      this.formLabelAlign.sort = row.sort
      this.formLabelAlign.timeType = row.timeType
      console.log(row)
    },
    finishEdit () { // 完成编辑
      const data = {
        id: this.formLabelAlign.id,
        timeNumber: this.formLabelAlign.timeNumber,
        sort: this.formLabelAlign.sort,
        timeType: this.formLabelAlign.timeType,
        status: 1
      }
      console.log(data)
      processProduct.editProcessProduct(data).then(res => {
        if (res.code === 0) {
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getProcessProduct()
          this.dialogVisible = false
        }
      })
    },
    getProcessList () { // 初步获得步骤的列表
      process.getTenProcessList().then(res => {
        console.log(res)
        this.processList = res.data
      })
    },
    moreProcess (query) { // 获得更多步骤的列表
      const data = {
        name: query
      }
      process.getTenProcessList(data).then(res => {
        console.log(res)
        this.processList = res.data
      })
    },
    getProcessProduct () { // 获得步骤和产品对应数据
      const data = {
        productId: this.$store.state.productId
      }
      processProduct.getProcessProductList(data).then(res => {
        this.tableData = res.data
      })
    },
    AddLawProduct () { // 增加一条数据
      if (this.value === '') {
        this.$message({
          message: '未选中任何流程',
          type: 'info'
        })
      } else {
        const data = {
          productId: this.$store.state.productId,
          myselfId: this.value,
          sort: this.num * 10,
          timeType: this.radio,
          timeNumber: this.timeNumber,
          status: 1
        }
        processProduct.addProcessProduct(data).then(res => {
          if (res.code === 0) {
            this.newLawdialogVisible = false
            this.$message({
              message: '新增成功',
              type: 'success'
            })
            this.value = null
            this.num = 1
            this.radio = '1'
            this.timeNumber = null
            this.getProcessProduct()
          }
        })
      }
    },
    deleteProcessProduct (row) { // 移除一条数据
      const params = { id: row.id }
      processProduct.deleteProcessProduct(params).then(res => {
        if (res.code === 0) {
          this.$message({
            message: '移除成功',
            type: 'success'
          })
          this.getProcessProduct()
        }
      })
    }
  }
}
</script>
   <style lang="scss" scoped>
   .product_config{
     margin-top: -25px;
   }
   </style>
