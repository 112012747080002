import request from '../../utils/axios'

const attributeProduct = {
  getAttributeProductList: function (parames) { // 查询接口
    return request.get('edu/attributeProduct/findList', { params: parames })
  },
  editLaw: function (parames) { // 编辑接口
    return request.get('edu/attributeProduct/update', { params: parames })
  },
  addAttributeProduct: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/attributeProduct/add',
      method: 'get',
      params: parames
    })
  },
  deleteAttributeProduct: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/attributeProduct/delete', { params })
  },
  auditAttributeProduct: function (params) { // 过审接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/attributeProduct/audit', { params })
  }
}

export default attributeProduct
