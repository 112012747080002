/* eslint-disable no-constant-condition */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Show from '../views/generic/Show.vue'
import Login from '../views/generic/Login.vue'
import Home from '../views/generic/Home.vue'
import Studyland from '../views/study/Studyland.vue'
import CRM from '../views/crm/Crm.vue'
import Exam from '../views/exam/Exam.vue'
import Center from '../views/other/Center.vue'
import PersonMessage from '../views/other/person/PersonMessage.vue'
import Message from '../views/other/Message.vue'
import Product from '../views/product/Product.vue'
import ProductAll from '../views/product/basic/ProductAll.vue'
import Category from '../views/product/basic/Category.vue'
import Tag from '../views/product/basic/Tag.vue'
import Area from '../views/product/basic/Area.vue'
import Attribute from '../views/product/basic/Attribute.vue'
import Law from '../views/product/high/Law.vue'
import Government from '../views/product/high/Government.vue'
import Material from '../views/product/high/Material.vue'
import Process from '../views/product/high/Process.vue'
import Deliverable from '../views/product/high/Deliverable.vue'
import Company from '../views/company/Company.vue'
import MyCompany from '../views/company/center/MyCompany.vue'
import AccessRecords from '../views/generic/AccessRecords.vue'
import VideoTutorials from '../views/study/VideoTutorials.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '展示页',
    component: Show
  },
  {
    path: '/login',
    name: '登录',
    component: Login
  },
  {
    path: '/home',
    name: '主页',
    component: Home
  },
  {
    path: '/access',
    name: '访问记录',
    component: AccessRecords
  },
  { // 企业库
    path: '/company',
    name: '企业库',
    component: Company,
    children: [
      {
        path: '/myCompany',
        name: '产品库',
        component: MyCompany
      }
    ]
  },
  {
    path: '/crm',
    name: 'CRM系统',
    component: CRM
  },
  {
    path: '/studyland',
    name: '学习中心',
    component: Studyland
  },
  {
    path: '/videoTutorials',
    name: '视频教程',
    component: VideoTutorials
  },
  {
    path: '/exam',
    name: '考试中心',
    component: Exam
  },
  {
    path: '/message',
    name: '消息中心',
    component: Message
  },
  {
    path: '/center',
    name: '个人中心',
    component: Center,
    children: [
      {
        path: '/personMessage',
        name: '基础信息',
        component: PersonMessage
      }
    ]
  },
  { // 产品库
    path: '/product',
    name: '产品库',
    component: Product,
    children: [
      {
        path: '/productall',
        name: '产品库',
        component: ProductAll
      },
      {
        path: '/category',
        name: '类目表',
        component: Category
      },
      {
        path: '/tagList',
        name: '标签表',
        component: Tag
      },
      {
        path: '/lawList',
        name: '法律表',
        component: Law
      },
      {
        path: '/government',
        name: '主管部门表',
        component: Government
      },
      {
        path: '/material',
        name: '材料大全',
        component: Material
      },
      {
        path: '/process',
        name: '流程大全',
        component: Process
      },
      {
        path: '/deliverable',
        name: '交付物大全',
        component: Deliverable
      },
      {
        path: '/areaList',
        name: '地域表',
        component: Area
      },
      {
        path: '/attributeList',
        name: '属性表',
        component: Attribute
      }
    ]
  },
  {
    path: '*', // 重定向页面地址
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
// 前置导航
router.beforeEach((to, from, next) => {
  // 01 如果没有登录，用户只能查看home/login/show/studyland这些页面
  if (to.path === '/' || to.path === '/home' || to.path === '/studyland' || to.path === '/login') {
    next()
  } else {
    // 02 判断有没有token
    if (JSON.parse(localStorage.getItem('user')).id != null) {
      // 判断是不是进入的考试中心
      if (to.path === '/exam' || to.path === '/center' || to.path === '/message') {
        next()
      } else {
        // 判断该用户是否挂载了企业，就能进入任何该用户拥有的路由了
        if (2) {
          next()
        }
      }
    } else { // 02 如果没有登录
      router.push('/home')
    }
  }
})
// 后置导航
router.afterEach((to, from) => {
  // 去掉路由中的锚点
  if (to.hash) {
    history.replaceState(null, null, to.path)
  }
})

export default router
