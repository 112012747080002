<!-- 个人中心基础信息 -->
<template>
  <div class="personMessage">
    <el-form :model="company" ref="company" label-width="100px" class="demo-company">
      <el-form-item label="企业全称" prop="name">
        <el-input v-model="company.fullName" style="width: 70%;" placeholder="请输入公司全称"></el-input>
      </el-form-item>
      <el-form-item label="企业简称" prop="name">
        <el-input v-model="company.simpleName" style="width: 50%;" placeholder="请填写公司简称/品牌名称"></el-input>
      </el-form-item>
      <el-form-item label="经营区域" prop="region">
        <el-select v-model="company.area" filterable remote :remote-method="findArea"  placeholder="请选择经营地区" style="width: 50%;">
              <el-option
                v-for="item in allAreaList"
                :key="item.value"
                :label="item.fullName"
                :value="item.id">
              </el-option>
            </el-select>
      </el-form-item>
      <el-form-item label="成立时间" required>
        <el-col :span="10">
          <el-form-item prop="date">
            <el-date-picker type="date" placeholder="选择日期" v-model="company.date" style="width: 100%;"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="公司简介" prop="desc">
        <el-input type="textarea" maxlength="200" :rows="4"  v-model="company.introduce" placeholder="请选择输入企业简介"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('company')" style="margin-left: 60%;">立即创建</el-button>
        <el-button @click="resetForm('company')">重置</el-button>
      </el-form-item>
    </el-form>
   </div>
 </template>
<script>
import area from '../../../api/other/area'
import company from '../../../api/company/company'

export default {
  data () {
    return {
      company: {
        fullName: '',
        simpleName: '',
        area: '',
        date: '',
        introduce: ''
      },
      allAreaList: [] // 所有的区域
    }
  },
  methods: {
    findArea (query) { // 获得地域的值
      console.log('低于')
      console.log(query)
      const data = {
        name: query
        // level: 4
      }
      area.getAreaListByName(data).then(res => {
        console.log(res)
        this.allAreaList = res.data
      })
    },
    submitForm () { // 提交新增企业按钮
      const data = {
        name: this.company.fullName,
        brand: this.company.simpleName,
        areaId: this.company.area,
        introduce: this.company.introduce,
        setTime: this.company.date
      }
      company.addCompany(data).then(res => {
        if (res.code === 0) {
          this.$message({
            message: '添加成功，等待审核',
            type: 'success'
          })
          const _this = this
          _this.$store.commit('getNewCompanyIfShow', false)
        }
      })
    },
    resetForm () { // 重置信息
      this.company = {}
      const _this = this
      _this.$store.commit('getNewCompanyIfShow', false)
    }
  }
}
</script>
<style>
.personMessage{
  text-align: left;
}
</style>
