<!-- 交付物大全 -->
<template>
  <div class="deliverable">
     <el-row class="input">
       <el-col :span="2">全局搜索</el-col>
         <el-col :span="5">
           <el-input
             size="small"
             style="width: 250px;"
             placeholder="请输入交付物名称"
             prefix-icon="el-icon-search"
             @change="selectDeliverable"
             v-model="input">
           </el-input>
         </el-col>
     </el-row>
     <!-- 新增交付物按钮 -->
     <el-row class="add">
       <el-col :span="2">
         <el-button @click="newDeliverable" type="primary" size="small" v-show="[1, 2, 3].includes(3)">新增交付物</el-button>
       </el-col>
     </el-row>
     <!-- 交付物表格 -->
     <el-row class="table">
       <el-col :span="24">
         <el-table
           :data="tableData"
           stripe
           :height=windowHeight-250
           style="width: 100%">
           <el-table-column
             prop="name"
             label="交付物名称"
             width="300">
           </el-table-column>
           <el-table-column
             prop="format"
             label="格式"
             width="120">
           </el-table-column>
           <el-table-column
             prop="mark"
             label="交付物备注">
           </el-table-column>
           <el-table-column
             prop="userId"
             label="操作人"
             width="100">
           </el-table-column>
           <el-table-column
             fixed="right"
             label="操作"
             width="120">
             <template slot-scope="scope">
               <el-button @click="editDeliverable(scope.row)" type="text" size="small">编辑</el-button>
               <el-button @click="deleteDeliverable(scope.row)" type="text" size="small">删除</el-button>
             </template>
           </el-table-column>
         </el-table>
       </el-col>
     </el-row>
     <div class="block">
       <el-row>
         <!-- 分页功能 -->
         <el-col :span="12" :offset="10">
           <el-pagination
             @size-change="handleSizeChange"
             @current-change="handleCurrentChange"
             :current-page="currentPage"
             :page-sizes="[10, 20, 30, 40]"
             :page-size="10"
             layout="total, sizes, prev, pager, next, jumper"
             :total="this.total">
           </el-pagination>
         </el-col>
       </el-row>
     </div>
     <!-- 新增和编辑的模板 -->
     <el-dialog
       :title="title"
       :visible.sync="newLawdialogVisible"
       width="52%"
       :before-close="handleClose">
       <span>
         <el-form ref="law" :model="deliverable" label-width="90px">
           <el-form-item label="交付物名称">
             <el-col :span="18">
               <el-input v-model="deliverable.deliverable_name" placeholder="请输入交付物全称"></el-input>
             </el-col>
           </el-form-item>
           <el-form-item label="格式" prop="select">
            <el-col :span="18">
              <el-select v-model="deliverable.deliverable_format" e placeholder="请选择格式" style="width: 100%;">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
             </el-col>
           </el-form-item>
           <el-form-item label="交付物备注">
             <el-input type="textarea" maxlength="90"  show-word-limit v-model="deliverable.deliverable_mark" placeholder="请输入交付物备注"></el-input>
           </el-form-item>
           <el-form-item label="上传例样">
              <div style="float: left;margin-top: 20px;">
                <UpLoad></UpLoad>
              </div>
           </el-form-item>
           <el-form-item>
             <el-button type="primary" @click="finishAdd" v-if="this.title === '新增交付物'">立即创建</el-button>
             <el-button type="success" @click="finishEdit" v-if="this.title === '编辑交付物'">编辑完成</el-button>
             <el-button @click="newLawdialogVisible = false">取消</el-button>
           </el-form-item>
         </el-form>
       </span>
     </el-dialog>
  </div>
 </template>

<script>
import getheight from '../../../utils/getheight'
import deliverable from '../../../api/product/deliverable'
import UpLoad from '../../../components/utils/UpLoad.vue'
export default {
  name: 'deliverable',
  mixins: [getheight],
  components: {
    UpLoad
  },
  data () {
    return {
      input: '',
      title: '新增交付物',
      windowHeight: 0,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: null,
      newLawdialogVisible: false,
      value: '',
      options: [{
        value: 'Word',
        label: 'Word'
      }, {
        value: 'PDF',
        label: 'PDF'
      }, {
        value: 'Excel',
        label: 'Excel'
      }, {
        value: 'PPT',
        label: 'PPT'
      }, {
        value: '照片',
        label: '照片'
      }, {
        value: '实物',
        label: '实物'
      }],
      deliverable: {
        deliverable_id: '',
        deliverable_name: '',
        deliverable_format: '',
        deliverable_link: '',
        deliverable_mark: ''
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 翻页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getList()
    },
    getList () { // 获得规定的列表
      const data = {
        current: this.currentPage,
        size: this.pageSize
      }
      deliverable.getDeliverableList(data).then(res => {
        console.log(res)
        this.total = res.data.total
        this.tableData = res.data.records
      })
    },
    finishAdd () { // 新增
      const data = {
        userId: this.$store.state.userInfo.userId,
        name: this.deliverable.deliverable_name,
        format: this.deliverable.deliverable_format,
        mark: this.deliverable.deliverable_mark,
        link: this.$store.state.upLoadUrl
      }
      deliverable.addDeliverable(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          console.log('成功了！')
          this.newLawdialogVisible = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    newDeliverable () { // 打开新增弹窗
      this.deliverable = {}
      this.newLawdialogVisible = true
      this.title = '新增交付物'
      const _this = this
      _this.$store.commit('getUpLoadUrl', '')
      _this.$store.commit('getShow', false)
    },
    finishEdit () { // 完成编辑的方法
      const data = {
        id: this.deliverable.deliverable_id,
        userId: this.$store.state.userInfo.userId,
        name: this.deliverable.deliverable_name,
        format: this.deliverable.deliverable_format,
        mark: this.deliverable.deliverable_mark,
        status: 1,
        link: this.$store.state.upLoadUrl
      }
      deliverable.editDeliverable(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          console.log('成功了！')
          this.newLawdialogVisible = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    editDeliverable (row) { // 打开编辑弹窗
      this.newLawdialogVisible = true
      this.title = '编辑交付物'
      this.deliverable.deliverable_id = row.id
      this.deliverable.deliverable_name = row.name
      this.deliverable.deliverable_format = row.format
      this.deliverable.deliverable_mark = row.mark
      this.deliverable.deliverable_link = row.link
      const _this = this
      _this.$store.commit('getUpLoadUrl', row.link)
      _this.$store.commit('getSrcList', new Array(this.$store.state.upLoadUrl))
      console.log(this.$store.state.upLoadUrl)
      _this.$store.commit('getShow', true)
    },
    handleClose () {
      this.newLawdialogVisible = false
    },
    deleteDeliverable (row) { // 删除交付物
      console.log(row)
      this.$confirm('确认删除？')
        .then(_ => {
          const params = { id: row.id }
          deliverable.deleteDeliverable(params).then(res => {
            this.$message({
              message: '删除成功',
              type: 'warning'
            })
            this.getList()
          })
        })
        .catch(_ => {})
    },
    selectDeliverable () { // 查询部门
      const params = { name: this.input, current: this.currentPage, size: this.pageSize }
      console.log(this.input)
      if (this.input === '') {
        this.getList()
      } else {
        deliverable.getDeliverableList(params).then(res => {
          console.log(res)
          this.total = res.data.total
          this.tableData = res.data.records
        })
      }
    }
  }
}
</script>
 <style lang="scss" scoped>
 .input{
   height: 50px;
   background: #FFFFFF;
   font-size: 14px;
   border: 0px red solid;
   display: flex;
   align-items: center;
 }
 .table{
   background: #FFFFFF;
   font-size: 14px;
   padding: 10px;
 }
 .add{
   height: 50px;
   margin-top: 10px;
   padding-top: 5px;
   display: flex;
   align-items: center;
   background: #FFFFFF;
 }
 .block{
   height: 40px;
   background: #FFFFFF;
 }
 </style>
