// 产品大全
import request from '../../utils/axios'

const product = {
  getTree: function () { // 查询接口
    return request.get('edu/product/findTree')
  },
  getProduct: function (parames) { // 查询树形结构
    return request.get('edu/product/findList', { params: parames })
  },
  getOneProduct: function (parames) { // 查询接口
    return request.get('edu/product/findOneProduct', { params: parames })
  },
  editProduct: function (parames) { // 编辑接口
    return request.get('edu/product/update', { params: parames })
  },
  addProduct: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/product/add',
      method: 'get',
      params: parames
    })
  },
  deleteProduct: function (params) { // 编辑接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/product/delete', { params })
  }
}

export default product
