<!-- 产品拓展 -->
<template>
 <div class="productexpand" v-if="ifRefresh">
    <el-row :gutter="5">
      <el-col :span="18">
        <!-- 产品概念 -->
          <el-card style="width: 100%;margin-top: 10px;">
            <h4 style="display: flex;">
              <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/concept.png"
              alt="概念"  style="margin-top: 0px;width: 25px;height: 25px;">
              &nbsp;&nbsp;<span style="margin-top: 2px;">产品概念：</span></h4>
            <p>{{ messageTableData.concept[0].message}}</p>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="10">填写时间：{{ messageTableData.concept[0].createTime }}</el-col>
              <el-col :span="3">贡献人：{{ messageTableData.concept[0].createId }}</el-col>
              <el-col :span="5">点赞数：{{ messageTableData.concept[0].good }} <i class="el-icon-delete"></i></el-col>
              <el-col :span="3" :offset="3"><el-button type="primary" @click="moreMessage(1)" size="mini">更多</el-button></el-col>
            </el-row>
          </el-card>
          <!-- 产品作用 -->
          <el-card style="width: 100%;margin-top: 10px;">
            <h4 style="display: flex;">
              <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/value.png"
              alt="作用"  style="margin-top: 0px;width: 25px;height: 25px;">
              &nbsp;&nbsp;<span style="margin-top: 2px;">产品作用：</span></h4>
            <p>{{messageTableData.role.content}}</p>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="18">共有{{messageTableData.role.num}}条描述，当前展示排名前{{messageTableData.role.num >=3 ? '3' : messageTableData.role.num}}，更多数据请点击“更多”！</el-col>
              <el-col :span="3" :offset="3"><el-button type="primary" @click="moreMessage(2)" size="mini">更多</el-button></el-col>
            </el-row>
          </el-card>
          <!-- 产品门槛 -->
          <el-card style="width: 100%;margin-top: 10px;">
            <h4 style="display: flex;">
              <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/concept.png"
              alt="门槛"  style="margin-top: 0px;width: 25px;height: 25px;">
              &nbsp;&nbsp;<span style="margin-top: 2px;">产品门槛：</span></h4>
            <p>{{messageTableData.threshold.content}}</p>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="18">共有{{messageTableData.threshold.num}}条描述，当前展示排名前{{messageTableData.threshold.num >=3 ? '3' : messageTableData.threshold.num}}，更多数据请点击“更多”！</el-col>
              <el-col :span="3" :offset="3"><el-button type="primary" @click="moreMessage(3)" size="mini">更多</el-button></el-col>
            </el-row>
          </el-card>
          <!-- 产品危害 -->
          <el-card style="width: 100%;margin-top: 10px;">
            <h4 style="display: flex;">
              <img src="https://fengyutang2024-03-241730.oss-cn-beijing.aliyuncs.com/harm.png"
              alt="危害"  style="margin-top: 0px;width: 25px;height: 25px;">
              &nbsp;&nbsp;<span style="margin-top: 2px;">不办危害：</span></h4>
            <p>{{messageTableData.harm.content}}</p>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="18">共有{{messageTableData.harm.num}}条描述，当前展示排名前{{messageTableData.harm.num >=3 ? '3' : messageTableData.harm.num}}，更多数据请点击“更多”！</el-col>
              <el-col :span="3" :offset="3"><el-button type="primary" @click="moreMessage(4)" size="mini">更多</el-button></el-col>
            </el-row>
          </el-card>
      </el-col>
      <!-- 右侧的内容 -->
      <el-col :span="6" style="margin-top: 10px;">
        <el-row>
          <el-col :span="23">
            <el-card style="padding: -5px;">
              <span style="font-weight: bold;">业务必看:</span>
              <el-row class="takeCare">
                <!-- <el-col :span="9">nhnu</el-col> -->
                <span class="span-width">
                  <p>共计18条</p>
                </span>
                <el-divider direction="vertical" class="vertical-divider"></el-divider>
                <span class="span-width">
                  <el-button type="success" size="mini" @click="moreMessage(6)" icon="el-icon-search">查看</el-button>
                </span>
              </el-row>
            </el-card>
            <el-card>
              <span style="font-weight: bold;">客户标签: <el-button type="text" @click="lookBar">占比图</el-button></span>
              <ul v-for="item in messageTableData.customer" :key="item.id">
                <li><el-tag>{{ item.message }}</el-tag></li>
              </ul>
              <el-divider></el-divider>
              <el-row>
              <el-col :span="18">共有{{messageTableData.cnall}}个标签！</el-col>
              <el-col :span="3" :offset="1"><el-button size="mini" @click="moreMessage(5)" type="primary">更多</el-button></el-col>
            </el-row>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 一个类型的具体信息 -->
    <el-dialog
      title="产品信息"
      :visible.sync="dialogVisibleList"
      width="80%"
      :before-close="handleClose">
      <span>
        <div style="margin-top: -30px;margin-left: 5px;">
          <el-button type="success" @click="openAdd" size="small">添加信息</el-button>
        </div>
        <el-table
          :data="TableData"
          style="width: 100%;margin-top: 0px;">
          <el-table-column
            prop="message"
            label="信息内容"
            width="500">
          </el-table-column>
          <el-table-column
            prop="createId"
            label="创建人"
            width="80">
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="创建时间"
            width="180">
          </el-table-column>
          <el-table-column
            prop="good"
            show-overflow-tooltip
            label="点赞数量">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="130">
            <template slot-scope="scope" style="display: flex;justify-content: center;align-items: center; ">
              <!-- 当用户的公司是平台的时候，只能进行编辑和删除 -->
                <template v-if="scope.row.createId == userId">
                  <!-- 编辑按钮 -->
                  <el-button
                    size="mini" round
                    @click="openEdit(scope.row)"><i class="el-icon-edit"></i></el-button>
                  <!-- 删除按钮 -->
                  <el-button
                    size="mini" round
                    @click="handleDelete(scope.row)"><i class="el-icon-delete"></i></el-button>
                </template>
                <!-- 当用户的公司为其他公司时候，可以进行点缀 -->
                <template v-else>
                  <template v-if="scope.row.fabulousPerson.includes(userId)">
                    <!-- 已经赞的提示 -->
                    <el-button
                      size="mini" round plain type="info" disabled="true"
                      ><i class="el-icon-thumb">已赞</i></el-button>
                  </template>
                  <template v-else>
                    <!-- 点赞按钮 -->
                    <el-button
                    size="mini" round plain
                    type="success"
                    @click="handleGood(scope.row)"><i class="el-icon-thumb">赞</i></el-button>
                  </template>
                </template>
              </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="10"
                style="margin: 5px;padding: 0px;"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.total">
        </el-pagination>
      </span>
    </el-dialog>
    <!-- 呈现的饼状图 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose">
      <span>
       <div style="height: 550px;">
        <ChartCustomer></ChartCustomer>
      </div>
      </span>
    </el-dialog>
    <!-- 新增信息 -->
    <el-dialog
      title="信息"
      :visible.sync="dialogVisibleNew"
      width="30%"
      :before-close="handleClose">
      <span>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="message">
        </el-input>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleNew = false">取 消</el-button>
        <el-button type="primary" v-if="ifAdd" @click="handleAdd">确 定</el-button>
        <el-button type="success" v-if="!ifAdd"  @click="handleEdit">完 成</el-button>
      </span>
    </el-dialog>
 </div>
</template>

<script>
import message from '../../api/study/message'
import ChartCustomer from '../../components/chart/ChartCustomer'
export default {
  name: 'productexpand',
  components: {
    ChartCustomer
  },
  data () {
    return {
      ifRefresh: true,
      dialogVisible: false,
      dialogVisibleNew: false,
      dialogVisibleList: false,
      ifAdd: true,
      message: '',
      productId: null,
      fabulousPerson: '',
      createId: '',
      good: 0,
      currentPage: 1,
      pageSize: 10,
      total: null,
      userId: '',
      id: '',
      messageType: null,
      messageTypeList: [],
      messageTableData: [],
      TableData: []
    }
  },
  mounted () {
    this.productId = localStorage.getItem('productId')
    this.getMessageList()
    this.userId = JSON.parse(localStorage.getItem('user')).id
    // console.log(localStorage.getItem('productId'))
  },
  methods: {
    updateRefresh () { // 重新加载
      console.log('刷新了！')
      this.ifRefresh = false
      this.$nextTick(() => {
        this.ifRefresh = true
      })
    },
    moreMessage (num) { // 查看一个类目的详情
      this.messageType = num
      this.getMessageList2()
      this.dialogVisibleList = true
    },
    handleGood (row) { // 点赞
      if (this.userId === undefined) {
        this.$message({
          message: '您尚未登录，请登录后参与点赞！',
          type: 'error'
        })
      } else {
        const data = {
          id: row.id,
          createId: this.userId
        }
        message.goodMessage(data).then(res => {
          this.getMessageList2()
        })
      }
    },
    openAdd () { // 打开新增
      if (this.userId === undefined) {
        this.$message({
          message: '您尚未登录，请登录后参与新增信息！',
          type: 'error'
        })
      } else {
        this.ifAdd = true
        this.message = ''
        this.dialogVisibleNew = true
      }
    },
    openEdit (row) { // 打开编辑
      this.dialogVisibleNew = true
      this.message = row.message
      this.id = row.id
      this.createId = row.createId
      this.good = row.good
      this.productId = row.productId
      this.messageType = row.messageType
      this.fabulousPerson = row.fabulousPerson
      this.ifAdd = false
    },
    handleEdit () { // 编辑
      const data = {
        id: this.id,
        messageType: this.messageType,
        productId: this.productId,
        message: this.message,
        good: this.good,
        createId: this.createId,
        fabulousPerson: this.fabulousPerson,
        companyId: 1,
        status: 1
      }
      message.editMessage(data).then(res => {
        if (res.code === 0) {
          this.getMessageList2()
          this.dialogVisibleNew = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
        }
      })
    },
    handleDelete (row) { // 删除
      const data = {
        id: row.id
      }
      message.deleteMessage(data).then(res => {
        if (res.code === 0) {
          this.getMessageList2()
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        }
      })
    },
    handleAdd () { // 新增完成
      const data = {
        createId: this.userId,
        message: this.message,
        messageType: this.messageType,
        fabulousPerson: '1',
        companyId: 1,
        status: 1,
        good: 1,
        productId: this.productId
      }
      message.addMessage(data).then(res => {
        if (res.code === 0) {
          this.getMessageList2()
          this.dialogVisibleNew = false
          this.$message({
            message: '新增成功',
            type: 'success'
          })
        }
      })
    },
    handleClose () { // 关闭弹窗
      this.dialogVisibleList = false
      this.dialogVisible = false
      this.dialogVisibleNew = false
      this.updateRefresh()
      // location.reload()
    },
    getMessageList  () { // 获得产品多元素
      const data = {
        current: this.currentPage,
        size: this.pageSize,
        // productId: this.$store.state.productId,
        productId: this.productId,
        messageType: this.messageType
      }
      message.getMessageList(data).then(res => {
        if (res.data.records[0].concept.length === 0) {
          // console.log('这说明没有数据')
          const obj = { message: '暂无数据' }
          this.messageTableData = res.data.records[0]
          this.messageTableData.concept.push(obj)
        } else {
          // console.log(res.data.records)
          this.messageTableData = res.data.records[0]
          const _this = this
          _this.$store.commit('getCustomerPieDate', res.data.records[0].elements)
        }
      })
    },
    getMessageList2  () { // 获得产品单元素
      const data = {
        current: this.currentPage,
        size: this.pageSize,
        productId: this.productId,
        messageType: this.messageType
      }
      message.getMessageList(data).then(res => {
        this.TableData = res.data.records
        this.total = res.data.total
      })
    },
    // 翻页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getMessageList2()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getMessageList2()
    },
    lookBar () { // 查看饼状图
      this.dialogVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
.takeCare{
  width: 100%;
  margin-top: 10px;
  height: 70px;
  text-align: center;
  border-radius: 5px;
  border: 0px red solid;
  padding: 3px;
  background: linear-gradient(to top, #1790ff, #00bfff);
}
.productexpand{
  text-align: left;
  padding: 0px 10px;
  width: 100%;
  border: 0px red solid;
  margin-bottom: 20px;
  background: white;
}
li{
  margin-top: 5px;
  margin-left: -2px;
}
.vertical-divider{
  height: 58px;
  margin: 4px 0;
}
.span-width {
    display: inline-block; /* 或者 'block' */
    width: 49%; /* 设置所需的宽度 */
    color: #ffffff;
    padding: 0px; /* 添加内边距 */
  }
</style>
