import request from '../../utils/axios'

const login = {
  getUserList: function (parames) { // 查询接口
    return request.get('edu/user/findList', { params: parames })
  },
  editUser: function (parames) { // 编辑接口
    return request.get('edu/user/update', { params: parames })
  },
  addOrLoginUser: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/user/service',
      method: 'get',
      params: parames
    })
  },
  deleteUser: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/user/delete', { params })
  }
}

export default login
