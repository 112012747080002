<!-- 管理部门大全 -->
<template>
 <div class="government">
    <el-row class="input">
      <el-col :span="2">全局搜索</el-col>
        <el-col :span="5">
          <el-input
            size="small"
            style="width: 250px;"
            placeholder="请输入部门名称"
            prefix-icon="el-icon-search"
            @change="selectGoverment"
            v-model="input">
          </el-input>
        </el-col>
    </el-row>
    <!-- 新增法律按钮 -->
    <el-row class="add">
      <el-col :span="2">
        <el-button @click="newGovernment" type="primary" size="small" v-show="[1, 2, 3].includes(3)">新增部门</el-button>
      </el-col>
    </el-row>
    <!-- 法律表格 -->
    <el-row class="table">
      <el-col :span="24">
        <el-table
          :data="tableData"
          stripe
          :height=windowHeight-250
          style="width: 100%">
          <el-table-column
            prop="fullName"
            label="部门名称"
            width="300">
          </el-table-column>
          <el-table-column
            prop="name"
            label="简称"
            width="80">
          </el-table-column>
          <el-table-column
            prop="station"
            label="网站链接"
            width="220">
          </el-table-column>
          <el-table-column
            prop="mark"
            label="部门简介">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100">
            <template slot-scope="scope">
              <el-button @click="editGovernment(scope.row)" type="text" size="small">编辑</el-button>
              <el-button @click="deleteGovernment(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div class="block">
      <el-row>
        <!-- 分页功能 -->
        <el-col :span="12" :offset="10">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.total">
          </el-pagination>
        </el-col>
      </el-row>
    </div>
    <!-- 新增和编辑的模板 -->
    <el-dialog
      :title="title"
      :visible.sync="newLawdialogVisible"
      width="48%"
      :before-close="handleClose">
      <span>
        <el-form ref="law" :model="government" label-width="80px">
          <el-form-item label="部门名称">
            <el-col :span="18">
              <el-input v-model="government.government_fulLname" placeholder="请输入部门全称"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="部门简称">
            <el-col :span="18">
              <el-input v-model="government.government_name" placeholder="请输入部门简称"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="网站网址">
            <el-col :span="18">
              <el-input v-model="government.government_station" placeholder="请输入部门网站链接"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="部门简介">
            <el-input type="textarea" maxlength="90"  show-word-limit v-model="government.government_mark" placeholder="请输入部门简介"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="finishAdd" v-if="this.title === '新增部门'">立即创建</el-button>
            <el-button type="success" @click="finishEdit" v-if="this.title === '编辑部门'">编辑完成</el-button>
            <el-button @click="newLawdialogVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </span>
    </el-dialog>
 </div>
</template>

<script>
import getheight from '../../../utils/getheight'
import government from '../../../api/product/government'
export default {
  name: 'government',
  mixins: [getheight],
  components: {},
  data () {
    return {
      input: '',
      title: '新增部门',
      windowHeight: 0,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: null,
      newLawdialogVisible: false,
      government: {
        government_id: '',
        government_fulLname: '',
        government_name: '',
        government_station: '',
        government_mark: ''
      }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 翻页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getList()
    },
    getList () { // 获得规定的列表
      const data = {
        current: this.currentPage,
        size: this.pageSize
      }
      government.getGovernmentList(data).then(res => {
        console.log(res)
        this.total = res.data.total
        this.tableData = res.data.records
      })
    },
    finishAdd () { // 新增部门
      const data = {
        id: this.government.government_id,
        fullName: this.government.government_fulLname,
        name: this.government.government_name,
        mark: this.government.government_mark,
        station: this.government.government_station
      }
      government.addGovernment(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          console.log('成功了！')
          this.newLawdialogVisible = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    newGovernment () { // 打开新增弹窗
      this.government = {}
      this.newLawdialogVisible = true
      this.title = '新增部门'
    },
    finishEdit () { // 完成编辑的方法
      const data = {
        id: this.government.government_id,
        fullName: this.government.government_fulLname,
        name: this.government.government_name,
        mark: this.government.government_mark,
        station: this.government.government_station
      }
      government.editGovernment(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          console.log('成功了！')
          this.newLawdialogVisible = false
          this.$message({
            message: '编辑成功',
            type: 'success'
          })
          this.getList()
        }
      })
    },
    editGovernment (row) { // 打开编辑弹窗
      this.newLawdialogVisible = true
      this.title = '编辑部门'
      this.government.government_id = row.id
      this.government.government_fulLname = row.fullName
      this.government.government_name = row.name
      this.government.government_mark = row.mark
      this.government.government_station = row.station
    },
    handleClose () {
      this.newLawdialogVisible = false
    },
    deleteGovernment (row) { // 删除部门
      console.log(row)
      this.$confirm('确认删除？')
        .then(_ => {
          const params = { id: row.id }
          government.deleteGovernment(params).then(res => {
            this.$message({
              message: '删除成功',
              type: 'warning'
            })
            this.getList()
          })
        })
        .catch(_ => {})
    },
    selectGoverment () { // 查询部门
      const params = { fullName: this.input }
      if (this.input === '') {
        this.getList()
      } else {
        government.getGovernmentList(params).then(res => {
          console.log(res)
          this.total = res.data.total
          this.tableData = res.data.records
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.input{
  height: 50px;
  background: #FFFFFF;
  font-size: 14px;
  border: 0px red solid;
  display: flex;
  align-items: center;
}
.table{
  background: #FFFFFF;
  font-size: 14px;
  padding: 10px;
}
.add{
  height: 50px;
  margin-top: 10px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
}
.block{
  height: 40px;
  background: #FFFFFF;
}
</style>
