export default {
//   computed: {
//     // 滚动区高度
//     scrollerHeight: function () {
//       return (window.innerHeight - 110) + 'px' // 自定义高度需求
//     }
//   }
//   window.addEventListener('resize', this.scrollerHeight) // 在窗口大小改变时重新获取窗口高度
  mounted () {
    this.getWindowHeight() // 在初始化时获取窗口高度
    window.addEventListener('resize', this.getWindowHeight) // 在窗口大小改变时重新获取窗口高度
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowHeight) // 组件销毁时移除resize事件监听器
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
    }
  }
}
