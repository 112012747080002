<!-- 学习中心产品概述 -->
<template>
 <div class="productBasic">
  <!-- <div>产品简介：好产品\好产品好产品\好产111的哦说32432432大是大非好产产品\好产111的哦说432432大是大非好产产品\好产111的哦说32432432大是大非好产品\好品\好产品好产品好产</div> -->
  <el-row>
    <el-col :span="16">
      <h3>基础信息:</h3>
      <div style="border: 0px red solid;font-weight: bold;padding-left: 2%;">
        <p>产品名称：{{ productBasic.name }}</p>
        <p>产品类目：{{ productBasic.categoryList }}</p>
        <p>办理时间：申报-{{ productBasic.process }};年检-{{ productBasic.inspect }}</p>
        <p>产品周期：{{ productBasic.startTime }}-{{ productBasic.endTime }}</p>
        <p>主管部门：
          <el-tag v-for="item in productBasic.governments" :key="item.id" style="margin-left: 10px;">{{item}}</el-tag>
        </p>
        <p>相关法律：<el-button type="text" @click="lookLaw">法律详情</el-button></p>
        <p style="width: 600px;">产品简介：{{ productBasic.description }}</p>
      </div>
    </el-col>
    <el-col :span="8">
      <h3>产品评估:</h3>
      <!-- <el-card>
        <CharDifficulty v-if="showSon"></CharDifficulty>
      </el-card> -->
      <CharDifficulty v-if="showSon"></CharDifficulty>
    </el-col>
  </el-row>
  <!-- 基础数据模块 -->
  <h3>基础数据:</h3>
  <div class="att">
    <el-card class="att_item">年平均单量：<br><span class="span">{{ productBasic.orderNumber }}个</span></el-card>
    <el-card class="att_item">平均客单价：<br><span class="span">{{ productBasic.unitPrice }}元</span></el-card>
    <el-card class="att_item">产品成单率：<br><span class="span">{{ productBasic.closeRate }}%</span></el-card>
    <el-card class="att_item">产品交付率：<br><span class="span">{{ productBasic.deliveryRate }}%</span></el-card>
    <el-card class="att_item">产品毛利率：<br><span class="span">{{ productBasic.grossMargin }}%</span></el-card>
  </div>
  <!-- 产品属性模块 -->
  <h3>产品属性:</h3>
  <p style="margin-left: 1%;">该产品关联{{ attribute_number }}种属性，地域级别为{{ productBasic.areaLevel ===1 ? '国家级' : (productBasic.areaLevel ===2 ? '省级' : (productBasic.areaLevel ===3 ? '市级' : '区县级'))}}，共计拥有{{allSpu}}个spu！</p>
  <div>
    <el-table
      :data="productBasic.attribute"
      style="width: 100%">
      <el-table-column
        prop="name"
        label="属性名"
        width="180">
      </el-table-column>
      <el-table-column
        prop="quantity"
        label="属性值数量"
        width="180">
      </el-table-column>
      <el-table-column
        prop="value"
        label="属性值">
      </el-table-column>
    </el-table>
  </div>
  <!-- 产品标签模块 -->
  <h3>产品标签:</h3>
  <div style="margin-left: 1%;">本模块，从{{ attribute_tag }}个衡量角度，对产品进行标注！帮助大家，快速认识产品特性。</div>
  <el-row v-for="item in this.productBasic.tag" :key="item.id">
        <el-col :span="5"><h3>{{ item.name }}</h3></el-col>
        <el-col :span="19" style="text-align: left;margin-top: 10px;" >
              <el-radio :label="tag.id" v-model="item.selectedValueId" border  @change="addOrUpdateTag(item)" v-for="tag in item.valueList" :key="tag.id">{{ tag.name }}</el-radio>
        </el-col>
  </el-row>
  <!-- 法律的弹窗 -->
  <el-dialog
    title="法律详情"
    :visible.sync="LookLaWButton"
    width="80%"
    :before-close="handleClose">
    <span>
      <el-table
        :data="LawTableData"
        style="width: 100%;margin-top: -25px;">
        <el-table-column
          prop="lawName"
          label="法律名称"
          width="180">
        </el-table-column>
        <el-table-column
          prop="lawItem"
          label="法律项"
          width="120">
        </el-table-column>
        <el-table-column
          prop="lawContent"
          label="法律条文">
        </el-table-column>
      </el-table>
    </span>
  </el-dialog>
 </div>
</template>

<script>
import product from '../../api/product/product'
import lawProduct from '../../api/product/lawProduct'
import CharDifficulty from '../chart/CharDifficulty.vue'
export default {
  name: 'productbasic',
  components: {
    CharDifficulty
  },
  data () {
    return {
      radio1: '1',
      showSon: false,
      productId: null,
      attribute_number: null,
      attribute_tag: null,
      LookLaWButton: false, // 查看法律详情的弹窗
      LawTableData: [], // 法律列表
      productBasic: {},
      allSpu: null
    }
  },
  mounted () {
    this.productId = localStorage.getItem('productId')
    this.getOneProduct()
  },
  methods: {
    lookLaw () { // 打开法律弹窗
      this.LookLaWButton = true
      this.getLawList()
    },
    handleClose () { // 关闭法律弹窗
      this.LookLaWButton = false
    },
    getOneProduct () { // 获得数据
      const data = {
        id: this.productId
      }
      product.getOneProduct(data).then(res => {
        this.productBasic = res.data
        this.attribute_number = res.data.attribute.length
        // spu的个数
        this.allSpu = res.data.spuNum
        this.attribute_tag = res.data.tag.length
        // 给雷达图赋值
        const _this = this
        _this.$store.commit('getDifficultyRadar', res.data.element)
        _this.$store.commit('getProductName', res.data.name)
        this.showSon = true
      })
    },
    getLawList  () { // 获得产品关联的法律
      const data = {
        productId: this.productId
      }
      lawProduct.getLawProductList(data).then(res => {
        this.LawTableData = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.productBasic{
  text-align: left;
  padding: 0px 15px;
  margin-left: 10px;
  border: 0px red solid;
  margin-bottom: 20px;
}
.att{
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 96%;
  padding-left: 2%;
  .att_item{
    background: linear-gradient(to top, #1790ff, #1790ff);
    width: 19%;
    border-radius: 5px;
    color: #cdcdcd;
    .span{
      font-size: 27px;
      color: white;
      margin-top: 5px;
    }
  }
}
</style>
