import request from '../../utils/axios'

const materialProduct = {
  getMaterialProductList: function (parames) { // 查询接口
    return request.get('edu/materialProduct/findList', { params: parames })
  },
  editMaterialProduct: function (parames) { // 编辑接口
    return request.get('edu/materialProduct/update', { params: parames })
  },
  addMaterialProduct: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/materialProduct/add',
      method: 'get',
      params: parames
    })
  },
  deleteMaterialProduct: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/materialProduct/delete', { params })
  },
  auditMaterialProduct: function (params) { // 过审接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/materialProduct/audit', { params })
  }
}

export default materialProduct
