<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card" style="margin-top: 5px;padding: 7px;">
            <el-tab-pane label="业务必问问题" name="1">
              <el-row style="border: 1px soild red;margin-top: 0px;">
                  <el-col :span="2" v-show="mustQuestionPart" :offset="0">
                      <el-button type="primary" size="mini" style="margin-bottom: 10px;" @click="addmustQuestion">添加</el-button>
                  </el-col>
                  <template>
                      <el-table
                          v-show="mustQuestionPart"
                          :data="questionTable"
                          border
                          max-height="450"
                          header-row-style="color:black"
                          style="width: 99%;margin-left: 0%;">
                          <el-table-column
                          type="index"
                          label="序号"
                          width="60">
                          </el-table-column>
                          <el-table-column
                          prop="content"
                          label="问题"
                          width="300">
                          </el-table-column>
                          <el-table-column
                          prop="answer"
                          label="必问原因"
                          width="400">
                          </el-table-column>
                          <el-table-column
                          prop="mark"
                          label="备注">
                          </el-table-column>
                          <el-table-column label="操作" width="130">
                            <template slot-scope="scope" style=" vertical-align: center;text-align: center;">
                              <el-button
                                size="mini"
                                round
                                v-if="scope.row.userId == Quid"
                                @click="handleEdit(scope.$index, scope.row)"><i class="el-icon-edit"></i></el-button>
                              <el-button
                                size="mini"
                                round
                                v-if="scope.row.userId == Quid"
                                @click="handleDelete(scope.$index, scope.row)"><i class="el-icon-delete"></i></el-button>
                                <!-- 点赞按钮 -->
                                <el-button
                                size="mini"
                                round
                                plain
                                type="success"
                                v-if="scope.row.userId != Quid && !scope.row.fabulousPerson.includes(Quid)"
                                @click="handleGood(scope.$index, scope.row)"><i class="el-icon-thumb">赞</i></el-button>
                                <!-- 已经赞的提示 -->
                                <el-button
                                size="mini"
                                round
                                plain
                                type="info"
                                disabled="true"
                                v-if="scope.row.fabulousPerson.includes(Quid)"
                                ><i class="el-icon-thumb">已赞</i></el-button>
                            </template>
                          </el-table-column>
                      </el-table>
                      <div>
                        <el-pagination
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                          :current-page="currentPage"
                          :page-sizes="[7, 14, 21, 28]"
                          :page-size="7"
                          style="margin: 5px;padding: 0px;margin-top: 20px;"
                          layout="total, sizes, prev, pager, next, jumper"
                          :total="this.total">
                        </el-pagination>
                      </div>
                  </template>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="客户常见问题" name="2">
              <el-row style="border: 1px soild red;margin-top: 0px;">
                <el-col :span="2" :offset="0">
                    <el-button type="primary" size="mini" style="margin-bottom: 10px;" @click="addcommonQuestion">添加</el-button>
                </el-col>
                <template>
                <el-table
                    :data="questionTable"
                    border
                    max-height="450"
                    header-row-style="color:black"
                    style="width: 99%;margin-left: 0%;">
                    <el-table-column
                    type="index"
                      label="序号"
                      width="60">
                    </el-table-column>
                    <el-table-column
                      prop="content"
                      label="问题"
                      width="300">
                    </el-table-column>
                    <el-table-column
                      prop="answer"
                      label="参考答案"
                      width="400">
                    </el-table-column>
                    <el-table-column
                      prop="mark"
                      label="备注">
                    </el-table-column>
                    <el-table-column label="操作" width="130">
                          <template slot-scope="scope" style="display: flex;justify-content: center;align-items: center; ">
                            <!-- 编辑按钮 -->
                            <el-button
                              size="mini"
                              round
                              v-if="scope.row.userId == Quid"
                              @click="handleEdit(scope.$index, scope.row)"><i class="el-icon-edit"></i></el-button>
                            <!-- 删除按钮 -->
                            <el-button
                              size="mini"
                              round
                              v-if="scope.row.userId == Quid"
                              @click="handleDelete(scope.$index, scope.row)"><i class="el-icon-delete"></i></el-button>
                              <!-- 点赞按钮 -->
                              <el-button
                              size="mini"
                              round
                              plain
                              type="success"
                              v-if="scope.row.userId != Quid && !scope.row.fabulousPerson.includes(Quid)"
                              @click="handleGood(scope.$index, scope.row)"><i class="el-icon-thumb">赞</i></el-button>
                              <!-- 已经赞的提示 -->
                              <el-button
                              size="mini"
                              round
                              plain
                              type="info"
                              disabled="true"
                              v-if="scope.row.fabulousPerson.includes(Quid)"
                              ><i class="el-icon-thumb">已赞</i></el-button>
                          </template>
                        </el-table-column>
                  </el-table>
                  <div>
                        <el-pagination
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                          :current-page="currentPage"
                          :page-sizes="[7, 14, 21, 28]"
                          :page-size="7"
                          style="margin: 5px;padding: 0px;margin-top: 20px;"
                          layout="total, sizes, prev, pager, next, jumper"
                          :total="this.total">
                        </el-pagination>
                      </div>
                </template>
            </el-row>
            </el-tab-pane>
            <el-tab-pane label="异议问题处理" name="3" :disabled="disabled1">
              <el-row style="border: 1px soild red;margin-top: 0px;">
                <el-col :span="2" :offset="0">
                    <el-button type="primary" size="mini" style="margin-bottom: 10px;" @click="addUnbelieve">添加</el-button>
                </el-col>
                <template>
                <el-table
                    :data="questionTable"
                    border
                    max-height="450"
                    header-row-style="color:black"
                    style="width: 99%;margin-left: 0%;">
                    <el-table-column
                    type="index"
                      label="序号"
                      width="60">
                    </el-table-column>
                    <el-table-column
                      prop="content"
                      label="问题"
                      width="350">
                    </el-table-column>
                    <el-table-column
                      prop="answer"
                      label="参考答案"
                      width="450">
                    </el-table-column>
                    <el-table-column
                      prop="mark"
                      label="备注">
                    </el-table-column>
                    <el-table-column label="操作" width="130">
                          <template slot-scope="scope" style="display: flex;justify-content: center;align-items: center; ">
                            <!-- 编辑按钮 -->
                            <el-button
                              size="mini"
                              round
                              v-if="scope.row.userId == Quid"
                              @click="handleEdit(scope.$index, scope.row)"><i class="el-icon-edit"></i></el-button>
                            <!-- 删除按钮 -->
                            <el-button
                              size="mini"
                              round
                              v-if="scope.row.userId == Quid"
                              @click="handleDelete(scope.$index, scope.row)"><i class="el-icon-delete"></i></el-button>
                              <!-- 点赞按钮 -->
                              <el-button
                              size="mini"
                              round
                              plain
                              type="success"
                              v-if="scope.row.userId != Quid && !scope.row.fabulousPerson.includes(Quid)"
                              @click="handleGood(scope.$index, scope.row)"><i class="el-icon-thumb">赞</i></el-button>
                              <!-- 已经赞的提示 -->
                              <el-button
                              size="mini"
                              round
                              plain
                              type="info"
                              disabled="true"
                              v-if="scope.row.fabulousPerson.includes(Quid)"
                              ><i class="el-icon-thumb">已赞</i></el-button>
                          </template>
                        </el-table-column>
                  </el-table>
                  <div>
                        <el-pagination
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                          :current-page="currentPage"
                          :page-sizes="[7, 14, 21, 28]"
                          :page-size="7"
                          style="margin: 5px;padding: 0px;margin-top: 20px;"
                          layout="total, sizes, prev, pager, next, jumper"
                          :total="this.total">
                        </el-pagination>
                      </div>
                </template>
            </el-row>
            </el-tab-pane>
            <el-tab-pane label="业务经验分享" name="4" :disabled="disabled1">
              <el-row style="border: 1px soild red;margin-top: 0px;">
                <el-col :span="2" :offset="0">
                    <el-button type="primary" size="mini" style="margin-bottom: 10px;" @click="addExperience">添加</el-button>
                </el-col>
                <template>
                <el-table
                    :data="questionTable"
                    border
                    max-height="450"
                    header-row-style="color:black"
                    style="width: 97%;margin-left: 0%;">
                    <el-table-column
                    type="index"
                      label="序号"
                      width="60">
                    </el-table-column>
                    <el-table-column
                      prop="content"
                      label="宝贵经验"
                      width="745">
                    </el-table-column>
                    <el-table-column
                      prop="user"
                      label="分享人"
                      width="102">
                    </el-table-column>
                    <el-table-column label="操作" width="130">
                          <template slot-scope="scope" style="display: flex;justify-content: center;align-items: center; ">
                            <!-- 编辑按钮 -->
                            <el-button
                              size="mini"
                              round
                              v-if="scope.row.userId == Quid"
                              @click="handleEdit(scope.$index, scope.row)"><i class="el-icon-edit"></i></el-button>
                            <!-- 删除按钮 -->
                            <el-button
                              size="mini"
                              round
                              v-if="scope.row.userId == Quid"
                              @click="handleDelete(scope.$index, scope.row)"><i class="el-icon-delete"></i></el-button>
                              <!-- 点赞按钮 -->
                              <el-button
                              size="mini"
                              round
                              plain
                              type="success"
                              v-if="scope.row.userId != Quid && !scope.row.fabulousPerson.includes(Quid)"
                              @click="handleGood(scope.$index, scope.row)"><i class="el-icon-thumb">赞</i></el-button>
                              <!-- 已经赞的提示 -->
                              <el-button
                              size="mini"
                              round
                              plain
                              type="info"
                              disabled="true"
                              v-if="scope.row.fabulousPerson.includes(Quid)"
                              ><i class="el-icon-thumb">已赞</i></el-button>
                          </template>
                        </el-table-column>
                  </el-table>
                  <div>
                        <el-pagination
                          @size-change="handleSizeChange"
                          @current-change="handleCurrentChange"
                          :current-page="currentPage"
                          :page-sizes="[7, 14, 21, 28]"
                          :page-size="7"
                          style="margin: 5px;padding: 0px;margin-top: 20px;"
                          layout="total, sizes, prev, pager, next, jumper"
                          :total="this.total">
                        </el-pagination>
                      </div>
                </template>
            </el-row>
            </el-tab-pane>
          </el-tabs>
    <!-- 弹窗新增信息 -->
    <el-dialog
      width="50%"
      title="感谢你的分享，这将帮助到很多同事，给你点赞！"
      :visible.sync="innerVisible"
      append-to-body>
      <template v-if="this.type== 1 || this.type==2 || this.type==3">
        <span style="font-size: 16px;" >问题提干:</span>
        <el-input minlength="12" v-model="content" placeholder="请输入问题提干" style="margin: 10px;width: 95%;"></el-input>
      </template>
      <template v-else>
        <span style="font-size: 16px;">经验分享:</span>
        <el-input minlength="12" v-model="content" type="textarea" :rows="4" placeholder="请输入经验内容" style="margin: 10px;width: 95%;"></el-input>
      </template>
      <template v-if="this.type== 1 || this.type==2 || this.type==3">
        <template v-if="this.type== 1">
          <span style="font-size: 16px;">必问原因:</span>
          <el-input v-model="answer" placeholder="请输入问题的必问原因" style="margin: 10px;width: 95%;"></el-input>
        </template>
        <template v-else>
          <span style="font-size: 16px;">参考答案:</span>
          <el-input v-model="answer" placeholder="请输入问题的参考答案" style="margin: 10px;width: 95%;"></el-input>
        </template>
        <span style="font-size: 16px;">备注信息:</span>
        <el-input v-model="mark" placeholder="请输入问题的备注信息" style="margin: 10px;width: 95%;"></el-input>
      </template>
      <div slot="footer" class="dialog-footer">
        <el-button @click="innerVisible = false">取 消</el-button>
        <template v-if="SubmitOrChange">
          <el-button type="primary" @click="submitQuestion">提 交</el-button>
        </template>
        <template v-else>
          <el-button type="success" @click="handleChange">更 新</el-button>
        </template>
    </div>
    </el-dialog>
  </div>
</template>

<script>
import presale from '../../api/study/presale'
import dateUtil from '../../utils/date'
export default {
  props: {
    childValue: String
  },
  rules: {
    name: [
      { required: true, message: '请输入问题提干', trigger: 'blur' },
      { min: 5, message: '至少输入 5 个字符', trigger: 'blur' }
    ]
  },
  data () {
    return {
      activeName: '1',
      currentPage: 1,
      pageSize: 7,
      total: null,
      productId: null,
      type: 1,
      companyId: null,
      companyIdAbout: null,
      disabled1: false,
      content: '',
      answer: '',
      mark: '',
      Quid: '',
      questionTable: [], // 所有问题
      innerVisible: false, // 内部弹窗
      mustQuestionPart: true, // 设置必问问题是否隐藏
      SubmitOrChange: true, // 设置提交还是更新
      rowId: '' // 点击编辑获取这行的id
    }
  },
  mounted: function () {
    this.productId = localStorage.getItem('productId')
    this.Quid = JSON.parse(localStorage.getItem('user')).id
    if (JSON.parse(localStorage.getItem('companyHistory')) === null) {
      this.companyIdAbout = null
    } else {
      this.companyIdAbout = JSON.parse(localStorage.getItem('companyHistory')).id
    }
    this.getAllQuestion()
  },
  methods: {
    handleClick (tab) {
      this.type = tab._props.name
      if (tab._props.name === '1') {
        this.companyId = null
      } else if (tab._props.name === '2') {
        this.companyId = null
      } else {
        if (JSON.parse(localStorage.getItem('companyHistory')) === null) {
          this.questionTable = null
          return
        } else {
          this.companyId = JSON.parse(localStorage.getItem('companyHistory')).id
        }
      }
      this.getAllQuestion()
    },
    getAllQuestion () {
      const data = {
        productId: this.productId,
        current: this.currentPage,
        companyId: this.companyId,
        type: this.type,
        size: this.pageSize
      }
      presale.getPresaleList(data).then(res => {
        console.log(res.data)
        this.total = res.data.total
        this.questionTable = res.data.records
      })
    },
    // 翻页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getAllQuestion()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getAllQuestion()
    },
    // 提交问题
    submitQuestion () {
      const data = {
        userId: this.Quid, // 不用传
        time: dateUtil.getNowMinTime(), // 不用传
        productId: this.productId,
        type: this.type,
        content: this.content,
        answer: this.answer,
        fabulousPerson: 1,
        companyId: this.companyId,
        mark: this.mark
      }
      presale.addPresale(data).then(res => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '添加成功，感谢您的参与!'
          })
          // 关闭窗口，并且把字段的内容设置为空
          this.innerVisible = false
          this.getAllQuestion()
        }
      })
    },
    // 添加必问问题
    addmustQuestion () {
      if (this.Quid === undefined) {
        this.$message({
          message: '您尚未登录，请登录后参与问题创建！',
          type: 'error'
        })
      } else {
        this.type = 1
        this.content = ''
        this.answer = ''
        this.mark = ''
        this.innerVisible = true
        this.SubmitOrChange = true
        this.companyId = null
      }
    },
    // 添加常问问题
    addcommonQuestion () {
      if (this.Quid === undefined) {
        this.$message({
          message: '您尚未登录，请登录后参与问题创建！',
          type: 'error'
        })
      } else {
        this.type = 2
        this.content = ''
        this.answer = ''
        this.mark = ''
        this.innerVisible = true
        this.SubmitOrChange = true
        this.companyId = null
      }
    },
    // 添加异议问题
    addUnbelieve () {
      if (this.Quid === undefined) {
        this.$message({
          message: '您尚未登录，请登录后参与问题创建！',
          type: 'error'
        })
      } else {
        if (this.companyIdAbout === null) {
          this.$message({
            message: '您尚未选择企业，请关联企业后参与问题创建！',
            type: 'error'
          })
        } else {
          this.type = 3
          this.content = ''
          this.answer = ''
          this.mark = ''
          this.innerVisible = true
          this.SubmitOrChange = true
          this.companyId = JSON.parse(localStorage.getItem('companyHistory')).id
        }
      }
    },
    // 添加业务经验
    addExperience () {
      if (this.Quid === undefined) {
        this.$message({
          message: '您尚未登录，请登录后参与问题创建！',
          type: 'error'
        })
      } else {
        if (this.companyIdAbout === null) {
          this.$message({
            message: '您尚未选择企业，请关联企业后参与问题创建！',
            type: 'error'
          })
        } else {
          this.type = 4
          this.content = ''
          this.answer = ''
          this.mark = ''
          this.innerVisible = true
          this.SubmitOrChange = true
          this.companyId = JSON.parse(localStorage.getItem('companyHistory')).id
        }
      }
    },
    // 编辑问题
    handleEdit (index, row) {
      // 先添加数据返回值
      this.innerVisible = true
      this.rowId = row.id
      this.type = row.type
      this.content = row.content
      this.answer = row.answer
      this.mark = row.mark
      this.SubmitOrChange = false
    },
    // 更新问题
    handleChange () {
      const data = {
        id: this.rowId,
        type: this.type,
        productId: this.productId,
        status: 1,
        userId: this.Quid,
        content: this.content,
        answer: this.answer,
        mark: this.mark
      }
      console.log(data)
      presale.editPresale(data).then(res => {
        console.log(res)
        if (res.code === '0') {
          this.$message({
            type: 'success',
            message: '添加成功，感谢您的参与!'
          })
        }
        // 关闭窗口，并且把字段的内容设置为空
        this.innerVisible = false
        this.content = ''
        this.answer = ''
        this.mark = ''
        this.getAllQuestion()
      }
      )
    },
    // 删除问题
    handleDelete (index, row) {
      this.$confirm('您确认删除吗？')
        .then(_ => {
          const data = { id: row.id }
          presale.deletePresale(data).then(insert => {
            this.$message({
              type: 'success',
              message: '删除成功，感谢您的参与!'
            })
            this.getAllQuestion()
          }
          )
        })
        .catch(_ => {})
    },
    // 点赞问题
    handleGood (index, row) {
      if (this.Quid === undefined) {
        this.$message({
          message: '您尚未登录，请登录后参与点赞！',
          type: 'error'
        })
      } else {
        const data = {
          id: row.id,
          userId: this.Quid // 不用传
        }
        presale.goodList(data).then(insert => {
          this.getAllQuestion()
        }
        )
      }
    }
  }
}
</script>
<style>
.question{
      width: 60px;
      height: 50px;
      position: fixed;
      bottom: 100px;
      right: 80px;
      z-index: 100;
  }
.dialog{
  position: fixed;
      top: -60px;
      z-index: 100;
}
</style>
