<!-- 材料与流程 -->
<template>
 <div class="material">
  <!-- 流程 -->
  <el-row :gutter="20">
    <el-col :span="16">
      <h4>办理流程:&nbsp;<el-link type="primary" style="list-style: none;"> 生成pdf</el-link></h4>
      <el-timeline style="margin-left: -37px;">
        <el-timeline-item v-for="(item, index) in processList" :key="item.id" :timestamp=di+[index+1]+bu   placement="top">
          <el-card>
            <div style="float: right;">
              <span v-if="item.process.worker === 1 ? false : true">办理周期:&nbsp;{{ item.timeNumber }}
                {{ item.timeType ===1 ? '工作日' : (item.timeType ===2 ? '自然日' : '月') }}</span>
                &nbsp;&nbsp;执行人：{{ item.process.worker ===1 ? '企业操作' : '非客户操作' }}</div>
            <h4>{{ item.process.title }} <el-link style="margin-top: -4px;" target="_blank" :href=item.process.aboutFile v-if="item.process.aboutFile !=''" type="primary">{{ item.process.fileName }}点击下载</el-link></h4>
            <p>{{ item.process.content }}</p>
            <div v-if="item.process.aboutPicture != ''">
              <viewer>
                <img :src=item.process.aboutPicture alt="logo" style="width: 150px;">
              </viewer>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </el-col>
    <!-- 快速分享 -->
    <el-col :span="8">
      <h4 style="margin-top: 27px;">快速分享:</h4>
      <el-row style="margin-left: 2%;margin-bottom: 3px;">
        <el-col :span="20">
          材料详情明细
        </el-col>
        <el-col :span="3">
          <el-button type="primary" plain round size="mini">复制</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-left: 2%;margin-bottom: 3px;">
        <el-col :span="20">
          流程详情明细
        </el-col>
        <el-col :span="3">
          <el-button type="primary" plain round size="mini">复制</el-button>
        </el-col>
      </el-row>
      <el-row style="margin-left: 2%;margin-bottom: 3px;">
        <el-col :span="20">
          流程+材料详情明细
        </el-col>
        <el-col :span="3">
          <el-button type="primary" plain round size="mini">复制</el-button>
        </el-col>
      </el-row>
      <!-- 材料 -->
      <h4>材料清单:</h4>
      <el-card>
        <div style="text-align:right;margin: -10px 0px;" >
          <span class="tag-read" style="color: #1E90FF;font-size: 15px;margin-top: 5px;" :data-clipboard-text=materailLine  @click="copy">复制</span>
        </div>
        <ol style="margin-left: -5px;">
          <li v-for="item in materailList" :key="item.id">{{ item.name }}</li>
        </ol>
        <el-divider></el-divider>
        <el-button type="primary" size="mini" @click="centerDialogVisible = true">详情</el-button>
        <p style="color: #cdcdcd;font-size: 14px;">温馨提醒：本材料清单为通用型内容，仅供参考，具体材料，需要结合SPU和SKU确定！</p>
      </el-card>
    </el-col>
  </el-row>
  <el-dialog
    title="材料详情"
    :visible.sync="centerDialogVisible"
    width="70%"
    center>
    <el-dialog
      width="70%"
      title="材料预览"
      :visible.sync="innerVisible"
      append-to-body>
      <span>
        <img :src=this.url alt="材料" style="width: 95%;">
      </span>
    </el-dialog>
    <span>
      <template>
        <el-table
          :data="materailList"
          border
          style="width: 100%">
          <el-table-column
            fixed
            type="index"
            label="序号"
            width="60">
          </el-table-column>
          <el-table-column
            prop="name"
            label="材料名称"
            width="220">
          </el-table-column>
          <el-table-column
            prop="worker"
            label="提供方"
            width="120">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.worker === 0 ? 'primary' : 'success'"
                disable-transitions>{{scope.row.worker  === 0 ? '企业提供' : '非企业提供'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            prop="format"
            label="材料类型"
            width="120">
          </el-table-column>
          <el-table-column
            prop="mark"
            label="备注"
            width="380">
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="50">
            <template slot-scope="scope">
              <el-button v-show="scope.row.link" v-if="scope.row.format === '照片'" @click="handleClick(scope.row)" type="text" size="small">预览</el-button>
              <el-button v-show="scope.row.link" v-if="scope.row.format != '照片'" @click="handleDown(scope.row)" type="text" size="small">下载</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </span>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button size="small" @click="centerDialogVisible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="centerDialogVisible = false">确 定</el-button> -->
      <el-link type="primary" style="list-style: none;margin-left: 80%;"> 生成pdf</el-link>
    </span>
  </el-dialog>
 </div>
</template>

<script>
import processProduct from '../../api/product/processProduct'
import materialProduct from '../../api/product/materialProduct'
import Clipboard from 'clipboard'
export default {
  name: '',
  components: {},
  data () {
    return {
      bu: '步',
      di: '第',
      productId: null,
      centerDialogVisible: false,
      innerVisible: false,
      url: '', // 图片预览
      url2: '', // 文件下载
      materailLine: '',
      materailList: [],
      processList: []
    }
  },
  mounted () {
    this.productId = localStorage.getItem('productId')
    this.getProcess()
    this.getMaterial()
  },
  methods: {
    handleClick (row) { // 预览图片
      this.innerVisible = true
      console.log(row)
      this.url = row.link
    },
    handleDown (row) { // 下载材料样式
      window.open(row.link, '_blank')
    },
    // 获得进程
    getProcess () {
      const data = {
        productId: this.productId
      }
      processProduct.getProcessProductList(data).then(res => {
        this.processList = res.data
        console.log(res)
      })
    },
    // 获得材料
    getMaterial () {
      const data = {
        productId: this.productId
      }
      materialProduct.getMaterialProductList(data).then(res => {
        this.materailList = res.data
        console.log(res)
        for (let index = 0; index < res.data.length; index++) {
          const element = [index + 1] + '、' + res.data[index].name + '\n'
          this.materailLine = this.materailLine + element
        }
      })
    },
    copy () { // 复制文案的方法
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        this.$message({
          type: 'success',
          message: '复制成功!'
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$message({
          type: 'info',
          message: '该浏览器不支持自动复制!'
        })
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.material{
  text-align: left;
  padding: 0px 15px;
}
</style>
