<!-- 属性产品配置的组建 -->
<template>
    <div class="law_config">
      <el-row>
        <el-col :span="16">
          <el-row style="text-align: left;">
           <el-col :span="16">
             <span style="margin-left: 9px;">属性检索：</span>{{ attributeItemId }}
             <el-select v-model="attributeItemId" filterable placeholder="请选择相关属性名" style="width: 80%;">
                 <el-option
                   v-for="item in attributeList"
                   :key="item.id"
                   :label="item.name"
                   :value="item.id">
                 </el-option>
             </el-select>
           </el-col>
           <el-col :span="2" :offset="1" >
             <el-button type="primary" style="margin-top: 3px;" size="small" @click="AddLawProduct">添加属性</el-button>
           </el-col>
         </el-row>
         <el-row style="margin-top: 10px;">
           <el-col :span="24">
             <el-table
               :data="tableData"
               style="width: 100%">
               <el-table-column
                 prop="name"
                 label="属性名称"
                 width="150">
               </el-table-column>
               <el-table-column
                 prop="lawContent"
                 label="数量"
                 width="60">
               </el-table-column>
               <el-table-column
                 prop="lawContent"
                 show-overflow-tooltip
                 label="属性值">
               </el-table-column>
               <el-table-column
                 fixed="right"
                 label="操作"
                 width="70">
                 <template slot-scope="scope">
                   <el-button
                     @click.native.prevent="deleteLawProduct(scope.row)"
                     type="text"
                     size="small">
                     移除
                   </el-button>
                 </template>
               </el-table-column>
             </el-table>
           </el-col>
         </el-row>
        </el-col>
        <el-col :span="7" style="padding: 15px;" :offset="1">
          <h3>SPU生成</h3>
          <p>规则如下，1、节点进度，每个企业可以自己去匹配在产品上，因为每个企业的产品特性不一样。但是这个一个很小的功能，找一个地方单独配置就可以。而且是订单中心的逻辑，所以不用着急。</p>
        </el-col>
      </el-row>
    </div>
   </template>

<script>
import attribute from '../../../api/product/attribute'
import attributeProduct from '../../../api/product/attributeProduct'
export default {
  name: 'attribute_config',
  components: {},
  data () {
    return {
      attributeItemId: '', // 选中的属性名
      attributeList: [], // 所有属性名
      tableData: [],
      productId: null,
      currentPage: 1,
      pageSize: 20
    }
  },
  mounted () {
    this.tableData = []
    this.getAttributeList() // 获取所有属性名
    this.getAttributeProduct() // 获取管关联表
  },
  methods: {
    getAttributeList () { // 获得属性名的列表
      const data = {
        current: this.currentPage,
        size: this.pageSize
      }
      attribute.getAttribute(data).then(res => {
        this.attributeList = res.data.records
      })
    },
    getAttributeProduct () { // 获得属性名和产品对应数据
      const data = {
        productId: this.$store.state.productId
      }
      attributeProduct.getAttributeProductList(data).then(res => {
        console.log(res.data)
        this.tableData = res.data
      })
    },
    AddLawProduct () { // 增加一条数据
      if (this.attributeItemId === '') {
        this.$message({
          message: '未选中任何属性名',
          type: 'info'
        })
      } else {
        const data = {
          productId: this.$store.state.productId,
          myselfId: this.attributeItemId,
          status: 1
        }
        attributeProduct.addAttributeProduct(data).then(res => {
          if (res.code === 0) {
            console.log('执行了')
            this.newLawdialogVisible = false
            this.$message({
              message: '新增成功',
              type: 'success'
            })
            this.attributeItemId = null
            this.getAttributeProduct()
          }
        })
      }
    },
    deleteLawProduct (row) { // 移除一条数据
      const params = { id: row.aboutId }
      attributeProduct.deleteAttributeProduct(params).then(res => {
        if (res.code === 0) {
          this.$message({
            message: '移除成功',
            type: 'success'
          })
          this.getAttributeProduct()
        }
      })
    }
  }
}
</script>
   <style lang="scss" scoped>
   .product_config{
     margin-top: -25px;
   }
   </style>
