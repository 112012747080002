<template>
  <div class="Myheader">
    <el-menu
      background-color="#1790ff"
      class="link"
      mode="horizontal"
      style="height: 60px;color: #fff;"
      menu-trigger="hover"
      unique-opened
      text-color="#fff"
      active-text-color="#fff">
      <el-menu-item style="font-size: 22px;margin-left: 20px;" @click="toApp">
            <img src="https://fengyutang-1305685513.cos.ap-beijing.myqcloud.com/logo/logo.png" alt="logo" style="width:50px;height:50px" lazy>
            公司宝
      </el-menu-item>
      <el-menu-item class="link" index="1"><router-link to="/productall">产品库</router-link></el-menu-item>
      <el-menu-item class="link" index="2"><router-link to="/myCompany">企业库</router-link></el-menu-item>
      <el-submenu index="3">
        <template slot="title">
          CRM
        </template>
        <el-menu-item class="link" index="3-1">
          <router-link  to="/center">
            <div style="color: bisque;">目标中心</div>
          </router-link>
        </el-menu-item>
        <el-menu-item class="link" index="3-2">
          <router-link  to="/center">
            <div style="color: bisque;">客户中心</div>
          </router-link>
        </el-menu-item>
        <el-menu-item class="link" index="3-3">
          <router-link  to="/center">
            <div style="color: bisque;">订单中心</div>
          </router-link>
        </el-menu-item>
        <el-menu-item class="link" index="3-4">
          <router-link  to="/center">
            <div style="color: bisque;">交付中心</div>
          </router-link>
        </el-menu-item>
      </el-submenu>
      <el-menu-item class="link" index="4"><router-link to="/access">统计库</router-link></el-menu-item>
      <el-menu-item class="link" index="5"><router-link to="/exam">考试中心</router-link></el-menu-item>
      <el-menu-item class="link" index="6"><router-link to="/studyland">学习中心</router-link></el-menu-item>
      <!-- 用户的基本信息 -->
      <el-submenu index="7" style="float: right;" v-if="ifLogin">
        <template slot="title">
          <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" size="small"></el-avatar>
          <!-- 用户名 -->
         {{ user.name  }}
        </template>
        <el-menu-item class="link" index="4-1">
          <router-link  to="/personMessage">
            <div style="color: bisque;">个人中心</div>
          </router-link>
        </el-menu-item>
        <el-menu-item class="link" index="4-3">
            <div style="color: bisque;" @click="ExitLogin">退出登录</div>
        </el-menu-item>
      </el-submenu>
      <!-- 消息中心和工单，也是登录后才能呈现 -->
      <el-menu-item class="link" style="float: right;margin: 0 0;" v-if="ifLogin">
        <router-link to="/message"><i class="el-icon-bell" style="color: white;margin: -7px;"></i>
        </router-link></el-menu-item>
      <el-menu-item class="link" style="float: right;margin: 0 0;" v-if="ifLogin">
        <router-link to="/study/studyland" style="margin: -8px;">工单</router-link></el-menu-item>
      <!-- 登录的按钮 -->
      <el-menu-item style="float: right;margin: 0 0;" v-if="!ifLogin">
        <router-link to="/login" style="margin: -8px;margin-left: 25px;margin-right: 15px;">登录</router-link>
      </el-menu-item>
      <!-- 用户身份的确定 -->
      <!-- <div v-if="ifCompany">测试</div> -->
      <el-submenu style="float: right;" index="8" v-if="ifLogin">
        <template slot="title" v-if="ifCompany">{{ companyHistory.name }}</template>
        <template slot="title" v-else>请选择所在企业</template>
        <el-menu-item class="link" index="8-1" v-if="ifLogin">
          <div style="color: bisque;" @click="bindingCompany">绑定企业</div>
        </el-menu-item>
        <!-- 下面对改用户，在职的company进行遍历 +++++++++++++++++++++++++++++++++++++++++++++++++++++++++-->
        <el-menu-item v-for="item in this.companyList" :key="item.id" @click="changeCompany(item)" class="link" index="item.id" v-show="ifLogin">
          <div style="color: bisque;">{{ item.name }}</div>
        </el-menu-item>
      </el-submenu>
      <!-- 没登录，就是游客身份 -->
      <el-menu-item style="float: right;margin: 0 0;" v-if="!ifLogin">
        <template>游客身份</template>
      </el-menu-item>
    </el-menu>
    <!-- 绑定企业的弹窗-外部 -->
    <el-dialog
      title="新增企业与添加企业"
      :visible.sync="outerVisible"
      width="50%"
      append-to-body>
      <!-- 内部的 -->
        <el-dialog
          title="新增企业"
          :visible.sync="this.$store.state.newCompanyIfShow"
          width="55%"
          @close="closeinner"
          show-close="false"
          :closeOnClickModal="this.$store.state.newCompanyIfShow"
          append-to-body>
          <span>
            <AddAndNew></AddAndNew>
          </span>
        </el-dialog>
        <!-- 外部的 -->
      <span>
        <el-row style="margin-top: -20px;">
          <el-col :span="8" style="text-align: left;">
            <el-button size="small" type="success"  @click="innerVisibleOpen">新增企业</el-button>{{ companyName }}
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px;text-align: left;">
          <el-col :span="24">
            <el-select v-model="value" filterable remote :remote-method="moreCompany" placeholder="请选择要关联的企业" style="width: 95%;">
              <el-option
                v-for="item in allCompany"
                :key="item.value"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="outerVisible = false">取 消</el-button>
        <el-button type="primary" @click="finishBinding">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import AddAndNew from '../components/company/basic/AddAndNew'
import company from '../api/company/company'
import companyUser from '../api/company/companyUser'
export default {
  name: 'Myheader',
  components: {
    AddAndNew
  },
  data () {
    return {
      ifLogin: null,
      ifCompany: null,
      companyHistory: null,
      companyName: '', // 搜索企业的名字
      companyList: [], // 用户关联的企业
      allCompany: [], // 全部企业
      user: null,
      outerVisible: false, // 外侧弹窗
      // innerVisible: this.$store.state.newCompanyIfShow, // 里面弹窗
      value: ''
    }
  },
  mounted: function () {
    console.log('头部更新了')
    console.log(JSON.parse(localStorage.getItem('user')))
    // this.refreshCompanyList() // 先更新关联的企业
    this.user = JSON.parse(localStorage.getItem('user'))
    this.ifLogin = JSON.parse(localStorage.getItem('ifLogin'))
    this.ifCompany = JSON.parse(localStorage.getItem('ifCompany'))
    this.companyHistory = JSON.parse(localStorage.getItem('companyHistory')) // 选中的公司
    this.companyList = JSON.parse(localStorage.getItem('companyList')) // 关联的公司
  },
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    innerVisibleOpen () { // 打开内部弹窗
      const _this = this
      _this.$store.commit('getNewCompanyIfShow', true)
    },
    toApp () { // 回到初始页面
      this.$router.replace('/')
    },
    moreCompany (query) { // 获取更多企业
      const data = {
        name: query
      }
      company.getTenCompanyList(data).then(res => {
        this.allCompany = res.data
      })
    },
    refreshCompanyList () { // 获得更多关联的企业
      const data = {
        userId: this.user.id
      }
      companyUser.getCompanyListById(data).then(res => {
        localStorage.setItem('companyList', JSON.stringify(res.data))
      })
    },
    bindingCompany () { // 初步获取关联企业
      this.outerVisible = true
      company.getTenCompanyList().then(res => {
        this.allCompany = res.data
      })
    },
    finishBinding () { // 提交绑定按钮
      this.outerVisible = false
      const data = {
        companyId: this.value,
        userId: this.user.id,
        status: 0,
        relation: 2
      }
      companyUser.addCompanyUser(data).then(res => {
        if (res.code === 0) {
          this.$message({
            message: res.data,
            type: 'success'
          })
        } else {
          this.$message({
            message: '提交失败，系统繁忙请稍后重试！',
            type: 'error'
          })
        }
      })
    },
    changeCompany (item) { // 改变选中的公司
      this.company = item
      localStorage.setItem('companyHistory', JSON.stringify(item))
      location.reload()
      // this.privide()
    },
    closeinner () {
      const _this = this
      _this.$store.commit('getNewCompanyIfShow', false)
    },
    ExitLogin () { // 退出登录的按钮
      this.ifLogin = false
      localStorage.setItem('user', JSON.stringify(''))
      localStorage.setItem('ifLogin', false)
      localStorage.setItem('ifCompany', false)
      localStorage.setItem('companyHistory', null)
      localStorage.setItem('companyList', null)
      location.reload()
    }
  }
}
</script>

<style>
a{
    color: #1E90FF;
    text-decoration: none;
 }
home-item{
  color: #fd7c7c;
}
.link{
  border-bottom: none;
  text-decoration: none;
}
.logo{
  /* width: 150px; */
  border: 1px red solid;
}
</style>
