import request from '../../utils/axios'

const access = {
  getAccessList: function (parames) { // 查询接口
    return request.get('edu/access/findList', { params: parames })
  },
  getAccessListByName: function (parames) { // 查询接口
    return request.get('edu/access/findListByName', { params: parames })
  },
  editAcccess: function (parames) { // 编辑接口
    return request.get('edu/access/update', { params: parames })
  },
  addAccess: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/access/add',
      method: 'get',
      params: parames
    })
  },
  deleteAccess: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/access/delete', { params })
  }
}

export default access
