import request from '../../utils/axios'

const area = {
  getAreaList: function (parames) { // 查询接口
    return request.get('edu/area/findList', { params: parames })
  },
  getAreaListByName: function (parames) { // 查询接口
    return request.get('edu/area/findListByName', { params: parames })
  },
  editArea: function (parames) { // 编辑接口
    return request.get('edu/area/update', { params: parames })
  },
  addArea: function (parames) { // 新增接口
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/area/add',
      method: 'get',
      params: parames
    })
  },
  deleteArear: function (params) { // 删除接口
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/area/delete', { params })
  }
}

export default area
