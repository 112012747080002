import request from '../../utils/axios'

const video = {
  getTopNineList: function (parames) { // 查询接口，9条数据
    return request.get('edu/video/topNineList', { params: parames })
  },
  editVideoNumber: function (parames) { // 更新接口，number+1
    return request.get('edu/video/updateNumber', { params: parames })
  },
  getVideoName: function (parames) { // 获得视频名列表
    console.log('拿到的参数' + JSON.stringify(parames))
    return request({
      url: 'edu/video/videoListByName',
      method: 'get',
      params: parames
    })
  },
  getVideoListByPid: function (params) { // 通过PId
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/video/videoListByPid', { params })
  },
  getVideoListById: function (params) { // 通过ID
    console.log('拿到的参数' + JSON.stringify(params))
    // const params = JSON.stringify(parames)
    return request.get('edu/video/findById', { params })
  }
}

export default video

// /edu/video/topNineList
// /edu/video/updateNumber
// /edu/video/videoListByName
// /edu/video/videoListByPid
// /edu/video/findById
