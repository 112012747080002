<!-- 海报页面 -->
<template>
    <div>
        <el-card style="margin: 0px;margin: 7px;">
            <el-row style="text-align: left;">
                <el-col :span="12" :offset="0">
                    <span>海报类型：</span>
                    <el-radio-group v-model="radio">
                        <el-radio :label="1">全部海报</el-radio>
                        <el-radio :label="3">公司海报</el-radio>
                        <el-radio :label="6">行业海报</el-radio>
                        <el-radio :label="9">我的海报</el-radio>
                    </el-radio-group>
                </el-col>
                <el-col :span="4" :offset="8">
                    <el-button type="primary" size="mini">添加海报</el-button>
                </el-col>
            </el-row>
        </el-card>
        <!-- <el-divider></el-divider> -->
        <div style="position:relative;margin-top: 0px;">
            <div class="container">
                <div class="column" v-for="(column,index) in columns" :key="index">
                <div
                    class="item"
                    v-for="(item,i) in column.columnArr"
                    :key="i"
                >
                <el-card :style="{ height: item.height + 'px', background: item.background }">
                    <p>{{ item.money }}</p>
                    <viewer>
                        <img :src=item.url alt="logo" style="width: 300px;">
                    </viewer>
                </el-card>
                </div>
                </div>
            </div>
        </div>
    </div>
  </template>
<script>
export default {
  data () {
    return {
      contentArr: [
        {
          url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg3.doubanio.com%2Fview%2Fphoto%2Fm%2Fpublic%2Fp2650049201.jpg&refer=http%3A%2F%2Fimg3.doubanio.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664935370&t=d4bf3e4d352c277a1bdebfcc8fda959f',
          title: '标题',
          height: '350',
          content:
            '描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分',
          money: '68.50',
          sales_volume: '281'
        },
        {
          url: 'https://img1.baidu.com/it/u=2911909188,130959360&fm=253&fmt=auto&app=138&f=JPEG?w=440&h=641',
          title: '标题',
          height: '750',
          content: '描述部分描述部分描述部分描述部分描述部分描述部分描述部分',
          money: '35.00',
          sales_volume: '1221'
        },
        {
          url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg3.doubanio.com%2Fview%2Fphoto%2Fm%2Fpublic%2Fp2650049201.jpg&refer=http%3A%2F%2Fimg3.doubanio.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664935370&t=d4bf3e4d352c277a1bdebfcc8fda959f',
          title: '标题',
          height: '770',
          content:
            '描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分',
          money: '68.50',
          sales_volume: '281'
        },
        {
          url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
          title: '标题',
          height: '580',
          content: '描述部分描述部分描述部分描述部分描述部分描述部分描述部分',
          money: '35.00',
          sales_volume: '1221'
        },
        {
          url: 'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
          title: '标题',
          height: '590',
          content:
            '描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分',
          money: '68.50',
          sales_volume: '281'
        },
        {
          url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg3.doubanio.com%2Fview%2Fphoto%2Fm%2Fpublic%2Fp2650049201.jpg&refer=http%3A%2F%2Fimg3.doubanio.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664935370&t=d4bf3e4d352c277a1bdebfcc8fda959f',
          title: '标题',
          height: '500',
          content:
            '描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分',
          money: '68.50',
          sales_volume: '281'
        },
        {
          url: 'https://img1.baidu.com/it/u=2911909188,130959360&fm=253&fmt=auto&app=138&f=JPEG?w=440&h=641',
          title: '标题',
          height: '550',
          content: '描述部分描述部分描述部分描述部分描述部分描述部分描述部分',
          money: '35.00',
          sales_volume: '1221'
        },
        {
          url: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg3.doubanio.com%2Fview%2Fphoto%2Fm%2Fpublic%2Fp2650049201.jpg&refer=http%3A%2F%2Fimg3.doubanio.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664935370&t=d4bf3e4d352c277a1bdebfcc8fda959f',
          title: '标题',
          height: '250',
          content:
            '描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分描述部分',
          money: '68.50',
          sales_volume: '281'
        }
      ],
      columns: [],
      arrIndex: [],
      loading: false,
      contentArr2: [],
      radio: 1
    }
  },
  methods: {
    getMinHeight (arr) {
      const a = []
      for (let i = 0; i < arr.length; i++) {
        a.push(parseInt(arr[i].height) + parseInt(arr[i].top))
      }
      return Math.min.apply(null, a)
    },
    getMinIndex (val) {
      for (let i = 0; i < this.columns.length; i++) {
        const height = this.columns[i].columnArr[this.columns[i].columnArr.length - 1].height
        const top = this.columns[i].columnArr[this.columns[i].columnArr.length - 1].top
        if (parseInt(height) + parseInt(top) === val) {
          this.arrIndex.push(i)
        }
      }
    },

    init () {
      this.columns = []
      const contentLen = this.contentArr.length
      // 根据可视区域的宽度判断需要几列
      const cWidth = document.documentElement.clientWidth || document.body.clientWidth
      // 假设图片宽度为330px
      const cLen = Math.floor((cWidth / 330) - 1)
      console.log(cLen)

      // 初始化每一列的第一行元素
      for (let i = 0; i < cLen; i++) {
        this.contentArr[i].top = 0 // 预设距离顶部值为0
        this.columns.push({ columnArr: [this.contentArr[i]] })
      }

      // 对剩余元素的判断，应该放到哪一列
      for (var index = cLen; index < contentLen; index++) {
        this.arrIndex = []
        const arr = [] // 找到高度最小的一列，可能有多个
        let minHeight = 0 // 高度最小的一列的高度
        let pushIndex = 0 // 高度最小的一列所在位置的索引

        for (let i = 0; i < this.columns.length; i++) {
          arr.push({
            height: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].height,
            top: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].top
          })
        }

        minHeight = this.getMinHeight(arr)
        this.getMinIndex(minHeight)
        if (this.arrIndex.length > 0) {
          pushIndex = Math.min.apply(null, this.arrIndex) // 出现高度一样的，去索引最小的
        }

        this.contentArr[index].top = minHeight + 20
        this.columns[pushIndex].columnArr.push(this.contentArr[index])
      }
    }
  },
  mounted () {
    this.init()
    window.onresize = () => {
      console.time('aa')
      this.init()
      console.timeEnd('aa')
    }
    const clientH = document.documentElement.clientHeight || document.body.clientHeight
    document.onscroll = (e) => {
      const top = e.target.documentElement.scrollTop || e.target.body.scrollTop

      const height = e.target.documentElement.scrollHeight || e.target.body.scrollHeight

      if ((top + clientH) === height) {
        this.loading = true
        this.pushElement().then(() => {
          //  从接口最新获取的元素push到目前高度最小的一列
          for (var index = 0; index < this.contentArr2.length; index++) {
            this.arrIndex = []
            const arr = [] // 找到高度最小的一列，可能有多个
            let minHeight = 0 // 高度最小的一列的高度
            let pushIndex = 0 // 高度最小的一列所在位置的索引

            for (let i = 0; i < this.columns.length; i++) {
              arr.push({
                height: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].height,
                top: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].top
              })
            }

            minHeight = this.getMinHeight(arr)
            this.getMinIndex(minHeight)
            if (this.arrIndex.length > 0) {
              pushIndex = Math.min.apply(null, this.arrIndex) // 出现高度一样的，去索引最小的
            }

            this.contentArr[index].top = minHeight + 20
            this.columns[pushIndex].columnArr.push(this.contentArr[index])
            this.loading = false
          }
        })
      }
    }
  }
}
</script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  div,
  p {
    margin: 0;
    padding: 0;
  }
  .container {
    margin: 0 auto;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-around;
    background: #ffffff;
    transition: all 0.5s ease-in-out;
  }
  .item {
    width: 360px;
    color: #000;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
  }
  .loading{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
  </style>
