<template>
  <div class="productleft">
    <el-menu
      router
      :default-active="$route.path"
      unique-opened
      class="el-menu-vertical-demo"
      @open="handleOpen"
      @close="handleClose">
      <el-submenu index="1">
          <template slot="title">
              <i class="el-icon-location"></i>
              <span>基础配置</span>
          </template>
          <el-menu-item-group>
              <el-menu-item index="/category" v-show="[1, 2, 3].includes(2)">产品类目</el-menu-item>
              <el-menu-item index="/productall">产品大全</el-menu-item>
              <!-- <el-menu-item index="/areaList">地域管理</el-menu-item> -->
              <el-menu-item index="/attributeList">属性管理</el-menu-item>
              <el-menu-item index="/tagList">标签管理</el-menu-item>
              <el-menu-item index="/product/skulist">SPU大全</el-menu-item>
          </el-menu-item-group>
      </el-submenu>
      <el-submenu index="2">
          <template slot="title">
              <i class="el-icon-location"></i>
              <span>高级配置</span>
          </template>
          <el-menu-item-group>
              <el-menu-item index="/material">材料大全</el-menu-item>
              <el-menu-item index="/process">流程大全</el-menu-item>
              <!-- <el-menu-item index="/deliverable">交付物大全</el-menu-item> -->
              <el-menu-item index="/government">主管部门</el-menu-item>
              <el-menu-item index="/lawList">法律条文</el-menu-item>
          </el-menu-item-group>
      </el-submenu>
      <el-submenu index="3">
          <template slot="title">
              <i class="el-icon-location"></i>
              <span>产品中心</span>
          </template>
          <el-menu-item-group>
              <el-menu-item index="/material1">产品管理</el-menu-item>
              <el-menu-item index="/process2">SPU管理</el-menu-item>
              <el-menu-item index="/deliverable2">SKU管理</el-menu-item>
              <el-menu-item index="/government1">材料管理</el-menu-item>
              <el-menu-item index="/lawList1">流程管理</el-menu-item>
          </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'productleft',
  components: {},
  data () {
    return {
      defalut: []
    }
  },
  created () {},
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    }
  },
  mounted () {},
  computed: {}
}

</script>
<style scoped>
.el-menu-vertical-demo{
  border-right: none;
}
</style>
