<!-- 海报页面 -->
<template>
    <div>
        <el-card style="margin: 0px;margin: 7px;">
            <el-row style="text-align: left;">
                <el-col :span="12" :offset="0">
                    <span>海报类型：</span>
                    <el-radio-group v-model="radio">
                      <el-radio v-model="radio" label="1">全部海报</el-radio>
                      <el-radio v-model="radio" v-if="posterShow" label="2">公司海报</el-radio>
                      <el-radio v-model="radio" v-if="posterShow" label="3">行业海报</el-radio>
                      <el-radio v-model="radio" v-if="posterShow" label="4">我的海报</el-radio>
                    </el-radio-group>
                </el-col>
                <el-col :span="4" :offset="8">
                    <el-button style="margin-top: -5px;" type="primary"  @click="addPoster" size="mini" v-if="posterShow">添加海报</el-button>
                </el-col>
            </el-row>
        </el-card>
        <!-- 加载海报的位置 -->
        <div style="position:relative;margin-top: 0px;">
            <div class="container">
                <div class="column" v-for="(column,index) in columns" :key="index">
                  <div
                      class="item"
                      v-for="(item,i) in column.columnArr"
                      :key="i">
                    <el-card :style="{ height: item.height + 'px', background: item.background }">
                      <div style="text-align: left;">
                        海报名称：{{ item.name }}
                      </div>
                      <el-row style="text-align: left;">
                        <el-col :span="22">贡献单位：{{ item.companyId }}</el-col>
                        <!-- <el-col :span="4"><i class="el-icon-edit"></i></el-col> -->
                        <el-col :span="2"><i class="el-icon-delete"></i></el-col>
                      </el-row>
                      <viewer>
                          <img :src=item.link alt="logo" style="width: 300px;margin-top: 5px;">
                      </viewer>
                      <div style="text-align: left;">海报文案：{{ item.copyWriting }}&nbsp;
                        <span class="tag-read" style="color: #1E90FF;font-size: 15px;" :data-clipboard-text=item.copyWriting  @click="copy">复制</span></div>
                    </el-card>
                  </div>
                </div>
            </div>
        </div>
        <div class="page">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="7"
              background="red"
              style="margin: 5px;padding: 0px;margin-top: 20px;"
              layout="total, sizes, prev, pager, next, jumper"
              :total="this.total">
            </el-pagination>
        </div>
        <!-- 这是londing的动画部分 -->
        <div class="loading" v-if="loading" v-loading="loading" element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"></div>
    <!-- 新增海报 -->
     <el-dialog
       title="添加海报"
       :visible.sync="newdialogVisible"
       width="52%"
       :before-close="handleClose">
       <span>
         <el-form ref="law" label-width="80px" :model="poster"  style="margin-top: -20px;">
           <el-form-item label="海报名称" prop="name"
           :rules="[
                  { required: true, message: '海报名称不能为空', trigger: 'blur'},
                  {min: 5, max: 12, message: '长度在 5 到 12 个字符', trigger: 'blur'}
                ]">
             <el-col :span="18">
               <el-input v-model="poster.name" placeholder="给海报起个名字方便查询"></el-input>
             </el-col>
           </el-form-item>
           <el-form-item label="海报文案" style="margin-top: 15px;">
             <el-input type="textarea" maxlength="50"  show-word-limit v-model="copy_writing" placeholder="请输入海报文案"></el-input>
           </el-form-item>
           <el-form-item label="行业海报" style="margin-top: 10px;">
            <el-row>
              <el-col :span="14">
                <div style="text-align: left;">
                  <el-radio v-model="radio2" label="1">本公司海报</el-radio>
                  <el-radio v-model="radio2" label="2">全行业海报</el-radio>
                </div>
                <div v-if="markShow" style="text-align: left;color: #cdcdcd;" class="space">
                  <p>1、行业海报所有用户都能查看</p>
                  <p>2、行业海报需要经过审核才能上线</p>
                  <p>3、行业海报不能体现单位和个人信息等内容</p>
                  <p> 4、行业海报应该预留logo和二维码位置，如图</p>
                  <p> 5、平台用户可以以行业海报为模版下载海报</p>
                  <p>6、海报页面下方文字部分会体现“贡献单位”</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div style="margin-top: 50px;" v-if="markShow">
                  <img src='https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' style="width: 150px;" alt="logo">
                </div>
              </el-col>
            </el-row>
           </el-form-item>
           <el-form-item label="上传图片">
              <div style="float: left;margin-top: 30px;">
                <UpLoad></UpLoad>
              </div>
           </el-form-item>
           <el-form-item>
             <el-button type="primary" @click="finishAdd">立即创建</el-button>
             <el-button @click="newdialogVisible = false">取消</el-button>
           </el-form-item>
         </el-form>
       </span>
     </el-dialog>
    </div>
  </template>
<script>
import UpLoad from '../../components/utils/UpLoad'
import poster from '../../api/study/poster'
import Clipboard from 'clipboard'
export default {
  components: {
    UpLoad
  },
  data () {
    return {
      newdialogVisible: false,
      radio: '1',
      radio2: '1',
      textHeight: 0,
      markShow: false,
      posterShow: true,
      poster: {
        name: ''
      },
      copy_writing: '',
      type: 1,
      type2: 1, // 上传位置固定
      productId: null,
      userId: null, // 用户id
      companyId: null,
      currentPage: 1,
      pageSize: 10,
      total: null,
      contentArr: [],
      columns: [],
      arrIndex: [],
      loading: false,
      contentArr2: []
    }
  },
  watch: {
    copy_writing: { // 这个思路是对的，但是数据需要在调整一下
      handler (newVal) {
        console.log(Math.ceil(newVal.length / 22))
        this.textHeight = Math.ceil(newVal.length / 22) * 15
      }
    },
    // 上创位置监听
    radio2: {
      handler (newVal) {
        if (newVal === '2') {
          this.markShow = true
          this.type2 = 1
        } else {
          this.markShow = false
          this.type2 = 2
        }
      }
    },
    // 点击部分监听
    radio: {
      handler (newVal) {
        console.log('监听了吗' + newVal)
        if (newVal === '1') {
          this.type = 0
          this.userId = null
          this.companyId = JSON.parse(localStorage.getItem('companyHistory')).id
          console.log('我是全部')
        } else if (newVal === '2') {
          console.log('我是公司')
          this.type = null
          this.userId = null
          this.companyId = JSON.parse(localStorage.getItem('companyHistory')).id
        } else if (newVal === '3') {
          console.log('我是行业')
          this.type = 0
          this.userId = null
          this.companyId = null
        } else {
          console.log('我是自己')
          this.type = null
          this.userId = JSON.parse(localStorage.getItem('user')).id
          this.companyId = null
        }
        this.getPoster()
      }
    }
  },
  methods: {
    copy () { // 复制文案的方法
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        this.$message({
          type: 'success',
          message: '复制成功!'
        })
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$message({
          type: 'info',
          message: '该浏览器不支持自动复制!'
        })
        // 释放内存
        clipboard.destroy()
      })
    },
    // 翻页的方法
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.pageSize = val
      this.getPoster()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.getPoster()
    },
    addPoster () { // 打开新增
      if (this.userId === null) {
        this.$message({
          message: '您尚未登录，请登录后创建自己的海报！',
          type: 'error'
        })
      } else {
        this.newdialogVisible = true
        this.init()
      }
    },
    finishAdd () { // 完成新增
      const data = {
        productId: this.productId,
        height: this.$store.state.imgHeight + this.textHeight,
        name: this.name,
        type: this.type,
        status: 1,
        link: this.$store.state.upLoadUrl,
        copyWriting: this.copy_writing,
        userId: JSON.parse(localStorage.getItem('user')).id,
        companyId: JSON.parse(localStorage.getItem('companyHistory')).id
      }
      poster.addPoster(data).then(res => {
        if (res.code === 0) {
          console.log('成功了！')
          this.newdialogVisible = false
          this.$message({
            message: '新增成功',
            type: 'success'
          })
          this.getPoster()
          this.name = null
          this.copy_writing = null
          const _this = this
          _this.$store.commit('getUpLoadUrl', null)
        }
      })
    },
    getPoster () { // 获取海报
      console.log('海报获取了！')
      const data = {
        current: this.currentPage,
        size: this.pageSize,
        type: this.type, // 变量
        userId: this.userId, // 变量
        companyId: this.companyId, // 变量
        productId: this.productId
      }
      console.log('传入的参数')
      console.log(data)
      poster.getPosterList(data).then(res => {
        console.log('海报')
        console.log(res)
        this.total = res.data.length // 此数据有误，需要改后端代码
        this.contentArr = res.data
        this.init()
      })
    },
    handleClose () {
      this.newdialogVisible = false
    },
    getMinHeight (arr) {
      const a = []
      for (let i = 0; i < arr.length; i++) {
        a.push(parseInt(arr[i].height) + parseInt(arr[i].top))
      }
      return Math.min.apply(null, a)
    },
    getMinIndex (val) {
      for (let i = 0; i < this.columns.length; i++) {
        const height = this.columns[i].columnArr[this.columns[i].columnArr.length - 1].height
        const top = this.columns[i].columnArr[this.columns[i].columnArr.length - 1].top
        if (parseInt(height) + parseInt(top) === val) {
          this.arrIndex.push(i)
        }
      }
    },

    init () {
      this.columns = []
      const contentLen = this.contentArr.length
      // 根据可视区域的宽度判断需要几列
      const cWidth = document.documentElement.clientWidth || document.body.clientWidth
      // 假设图片宽度为330px
      const cLen = Math.floor((cWidth / 330) - 1)
      // console.log(cLen)

      // 初始化每一列的第一行元素
      for (let i = 0; i < cLen; i++) {
        this.contentArr[i].top = 0 // 预设距离顶部值为0
        this.columns.push({ columnArr: [this.contentArr[i]] })
      }

      // 对剩余元素的判断，应该放到哪一列
      for (var index = cLen; index < contentLen; index++) {
        this.arrIndex = []
        const arr = [] // 找到高度最小的一列，可能有多个
        let minHeight = 0 // 高度最小的一列的高度
        let pushIndex = 0 // 高度最小的一列所在位置的索引

        for (let i = 0; i < this.columns.length; i++) {
          arr.push({
            height: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].height,
            top: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].top
          })
        }

        minHeight = this.getMinHeight(arr)
        this.getMinIndex(minHeight)
        if (this.arrIndex.length > 0) {
          pushIndex = Math.min.apply(null, this.arrIndex) // 出现高度一样的，去索引最小的
        }

        this.contentArr[index].top = minHeight + 20
        this.columns[pushIndex].columnArr.push(this.contentArr[index])
      }
    }
  },
  mounted () {
    this.productId = localStorage.getItem('productId')
    this.userId = JSON.parse(localStorage.getItem('user')).id
    this.type = null
    if (JSON.parse(localStorage.getItem('companyHistory')) === null) {
      this.posterShow = false
    } else {
      this.companyId = JSON.parse(localStorage.getItem('companyHistory')).id
    }
    this.getPoster()
    this.init()
    window.onresize = () => {
      // console.time('aa')
      this.init()
      // console.timeEnd('aa')
    }
    const clientH = document.documentElement.clientHeight || document.body.clientHeight
    document.onscroll = (e) => {
      const top = e.target.documentElement.scrollTop || e.target.body.scrollTop

      const height = e.target.documentElement.scrollHeight || e.target.body.scrollHeight

      if ((top + clientH) === height) {
        this.loading = true
        this.pushElement().then(() => {
          //  从接口最新获取的元素push到目前高度最小的一列
          for (var index = 0; index < this.contentArr2.length; index++) {
            this.arrIndex = []
            const arr = [] // 找到高度最小的一列，可能有多个
            let minHeight = 0 // 高度最小的一列的高度
            let pushIndex = 0 // 高度最小的一列所在位置的索引

            for (let i = 0; i < this.columns.length; i++) {
              arr.push({
                height: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].height,
                top: this.columns[i].columnArr[this.columns[i].columnArr.length - 1].top
              })
            }

            minHeight = this.getMinHeight(arr)
            this.getMinIndex(minHeight)
            if (this.arrIndex.length > 0) {
              pushIndex = Math.min.apply(null, this.arrIndex) // 出现高度一样的，去索引最小的
            }

            this.contentArr[index].top = minHeight + 20
            this.columns[pushIndex].columnArr.push(this.contentArr[index])
            this.loading = false
          }
        })
      }
    }
  }
}
</script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  div,
  p {
    margin: 0;
    padding: 0;
  }
  .container {
    margin: 0 auto;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-around;
    background: #ffffff;
    transition: all 0.5s ease-in-out;
  }
  .item {
    width: 360px;
    color: #000;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
  }
  .loading{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }
  .page{
    /* position:fixed;
    bottom: 30px;
    right: 300px; */
    margin-bottom: 30px;
  }
  </style>
