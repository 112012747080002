import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      userId: '',
      userName: '',
      userPower: [],
      token: 'kdfnskof',
      companyId: '12',
      company: ''
    },
    categoryList: [], // 产品类目
    srcList: [], // 图片回显用的
    imgHeight: null,
    upLoadUrl: '', // 上传图片的路径
    upLoadFileUrl: '', // 上传文件的路径
    fileName: '', // 文件名
    show: false, // 上传文件的是否展示
    productId: null, // 学习中心当前选中的产品和配置当前
    customerPieDate: [], // 学习中心信息的饼状图
    productName: '', // 学习中心呈现的产品名
    difficultyRadar: [], // 学习中心困难的雷达图
    newCompanyIfShow: false, // 新增公司的弹窗，是否呈现
    hsf: ''
  },
  mutations: {
    getCompanyId (state, companyId) {
      state.userInfo.companyId = companyId
    },
    getProductName (state, productName) {
      state.productName = productName
    },
    getUserId (state, userId) {
      state.userInfo.userId = userId
    },
    getUserName (state, userName) {
      state.userInfo.userName = userName
    },
    getCategoryList (state, categoryList) {
      state.categoryList = categoryList
    },
    getProductId (state, productId) {
      state.productId = productId
    },
    getUpLoadUrl (state, upLoadUrl) {
      state.upLoadUrl = upLoadUrl
    },
    getUpLoadFileUrl (state, upLoadFileUrl) {
      state.upLoadFileUrl = upLoadFileUrl
    },
    getFileName (state, fileName) {
      state.fileName = fileName
    },
    getShow (state, show) {
      state.show = show
    },
    getSrcList (state, srcList) {
      state.srcList = srcList
    },
    getImgHeight (state, imgHeight) {
      state.imgHeight = imgHeight
    },
    getCustomerPieDate (state, customerPieDate) {
      state.customerPieDate = customerPieDate
    },
    getDifficultyRadar (state, difficultyRadar) {
      state.difficultyRadar = difficultyRadar
    },
    getNewCompanyIfShow (state, newCompanyIfShow) {
      state.newCompanyIfShow = newCompanyIfShow
    }
  },
  actions: {
  },
  modules: {
  }
})
