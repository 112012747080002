<!-- 上传文件的工具 -->
<template>
 <div class="upload">
    <div>
        <input type="file" ref="fileInput" @change="handleFileChange">
    </div>
    <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
    <div class="demo-image__preview" v-show="this.$store.state.show">
      <span v-if="this.$store.state.upLoadUrl == ''">
        <i class="el-icon-loading" size="20px"></i>
      </span>
      <span v-else>
        <el-image
        style="width: 100px; height: 100px"
        :src="this.$store.state.upLoadUrl"
        :preview-src-list="this.$store.state.srcList">
      </el-image>
      </span>
    </div>
 </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'upload',
  components: {},
  data () {
    return {
      selectedFile: [],
      img: null
    }
  },
  mounted () {
    this.srcList.push(this.$store.state.upLoadUrl)
  },
  methods: {
    handleFileChange (event) {
      console.log(event.target.files[0])
      this.selectedFile = event.target.files[0] // 获取所选文件对象
      this.uploadFile()
    },
    uploadFile () {
      const _this = this
      _this.$store.commit('getShow', true)
      const formData = new FormData() // 创建FormData对象
      formData.append('file', this.selectedFile)
      axios.post('http://api.fengyutangfly.cn/edu/file/uploadImg', formData).then((response) => {
        console.log('图片已成功上传' + response.data.data)
        const url = response.data.data
        const _this = this
        // vuex存入图片路径
        _this.$store.commit('getUpLoadUrl', response.data.data)
        _this.$store.commit('getSrcList', new Array(this.$store.state.upLoadUrl))
        const img = new Image()
        img.src = url
        img.onload = function () {
          this.imgHeight = Math.ceil(img.height / (img.width / 300) + 100)
          // vuex存入图片高度
          _this.$store.commit('getImgHeight', this.imgHeight)
        }
      }).catch((error) => {
        console.error('文件上传失败', error)
      })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
