<!-- 公司 -->
<template>
    <div class="category">
     <Head></Head>
     <div>
      <el-container>
        <el-aside class="aside" width="200px">
          <div style="width: 200px;">
            <CompanyLeft></CompanyLeft>
          </div>
        </el-aside>
        <el-main  style="background: #F8F8FF;padding:7px;height: 100%;">
          <div class="main">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
     </div>
    </div>
   </template>

<script>
import Head from '../../components/Head'
import CompanyLeft from '../../components/left/CompanyLeft'
export default {
  name: '',
  components: {
    Head,
    CompanyLeft
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.aside{
    height: 500px;
    // border: 1px red solid;
    margin-top: 0px;
}
</style>
